import SelectedVideoItem from './SelectedVideoItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import styled from 'styled-components';
import RightArrowIcon from '../../assets/right-arrow.svg';
import LeftArrowIcon from '../../assets/left-arrow.svg';
import UserColors from '../../constants/colors';
import { useState } from 'react';

function SelectedVideo({ videos, RemoveVideo, onToggle, isStatic }) {
    // console.log(videos);
    const isPageLeft = () => {};
    const [page, setPage] = useState(1);
    const lastPage = Math.ceil(videos.length / 6);

    const leftArrowSvg = (
        <svg
            width="7"
            height="15"
            viewBox="0 0 7 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.83749 14.0013C5.68809 14.0018 5.54048 13.9688 5.4055 13.9048C5.27052 13.8407 5.15161 13.7473 5.05749 13.6313L0.227488 7.63125C0.0804062 7.45232 0 7.22788 0 6.99625C0 6.76463 0.0804062 6.54018 0.227488 6.36125L5.22749 0.361252C5.39723 0.157036 5.64114 0.0286112 5.90556 0.0042315C6.16999 -0.0201482 6.43327 0.0615137 6.63749 0.231252C6.8417 0.400991 6.97013 0.644902 6.99451 0.909329C7.01889 1.17375 6.93723 1.43704 6.76749 1.64125L2.29749 7.00125L6.61749 12.3613C6.73977 12.508 6.81745 12.6868 6.84133 12.8763C6.86521 13.0659 6.83429 13.2583 6.75223 13.4308C6.67018 13.6034 6.54042 13.7488 6.37831 13.8499C6.2162 13.9509 6.02852 14.0035 5.83749 14.0013Z"
                fill="#547AFF"
            />
        </svg>
    );

    const rightArrowSvg = (
        <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.66178 14.0005C1.81117 14.001 1.95878 13.9681 2.09376 13.904C2.22874 13.84 2.34766 13.7465 2.44178 13.6305L7.27178 7.63052C7.41886 7.45159 7.49927 7.22714 7.49927 6.99552C7.49927 6.76389 7.41886 6.53945 7.27178 6.36052L2.27178 0.36052C2.10204 0.156303 1.85813 0.0278788 1.5937 0.00349908C1.32928 -0.0208806 1.066 0.0607813 0.86178 0.23052C0.657563 0.400258 0.529139 0.64417 0.504759 0.908596C0.48038 1.17302 0.562041 1.4363 0.73178 1.64052L5.20178 7.00052L0.88178 12.3605C0.759497 12.5073 0.68182 12.686 0.65794 12.8756C0.634061 13.0651 0.664978 13.2576 0.747034 13.4301C0.82909 13.6026 0.958849 13.748 1.12096 13.8491C1.28307 13.9502 1.47075 14.0028 1.66178 14.0005Z"
                fill="#547AFF"
            />
        </svg>
    );

    const IncrePage = () => {
        if (page >= lastPage) return;
        setPage(page + 1);
    };
    const DecrePage = () => {
        if (page <= 1) return;
        setPage(page - 1);
    };
    console.log(videos);
    return (
        <SelectedVideoWrapper>
            {/* {page} */}
            <ArrowButton dir="left" onClick={DecrePage} first={page === 1}>
                {leftArrowSvg}
            </ArrowButton>
            {videos &&
                videos
                    .slice((page - 1) * 6, (page - 1) * 6 + 6)
                    .map((item, index) => (
                        <SelectedVideoItem
                            video={item}
                            key={index}
                            RemoveVideo={RemoveVideo}
                            onToggle={onToggle}
                            /* indexOf(item)으로 구분가능한 이유
                             * 객체라서 구분가능함
                             * 동일한 내용의 객체라 할지라도 주소로 따지기 때문에 다른것으로 본다 */
                            kval={videos.indexOf(item)}
                            isStatic={isStatic}
                        />
                    ))}
            <ArrowButton
                dir="right"
                onClick={IncrePage}
                last={lastPage === 0 || page >= lastPage}
            >
                {rightArrowSvg}
            </ArrowButton>
            {/* <Swiper
                // modules={[Navigation, Pagination, Scrollbar, A11y]}
                modules={[Pagination]}
                spaceBetween={20}
                slidesPerView={4}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
            >
                <SwiperSlide>
                    {videos &&
                        videos.map((item, index) => (
                            <SelectedVideoItem
                                video={item}
                                key={index}
                                RemoveVideo={RemoveVideo}
                                onToggle={onToggle}
                                kval={index}
                            />
                        ))}
                </SwiperSlide>
                <SwiperSlide>2다</SwiperSlide>
                {}
            </Swiper> */}
        </SelectedVideoWrapper>
    );
}

const SelectedVideoWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0.8rem;
    grid-row-gap: 0.6rem;
    position: relative;
    padding: 0 2.3rem;
    white-space: nowrap;
`;

const ArrowButton = styled.div`
    padding-right: ${(props) => (props.dir === 'left' ? '1rem' : '')};
    padding-left: ${(props) => (props.dir === 'right' ? '1rem' : '')};
    height: 7rem;
    background-color: ${UserColors.white01};
    position: absolute;
    left: ${(props) => (props.dir === 'left' ? 0 : '')};
    right: ${(props) => (props.dir === 'right' ? 0 : '')};
    top: -1rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    & {
        img {
            width: 0.8rem;
        }

        svg path {
            fill: ${(props) =>
                props.first || props.last ? `${UserColors.gray02}` : 0};
        }
    }
`;

export default SelectedVideo;
