import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import UserColors from '../../constants/colors';

function UserNoticeItem({ notice, isFixTop, isNotRead }) {
    const {
        content,
        createdAt,
        fixTop,
        id,
        isVisible,
        redirectUrl,
        reservedDatetime,
        title,
    } = notice;
    const [showDate, setShowDate] = useState('');

    const MegaphoneIcon = (
        <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 1.48926C13 1.09428 13.158 0.715483 13.4393 0.436193C13.7206 0.156903 14.1022 0 14.5 0C14.8978 0 15.2794 0.156903 15.5607 0.436193C15.842 0.715483 16 1.09428 16 1.48926V12.4105C16 12.8054 15.842 13.1842 15.5607 13.4635C15.2794 13.7428 14.8978 13.8997 14.5 13.8997C14.1022 13.8997 13.7206 13.7428 13.4393 13.4635C13.158 13.1842 13 12.8054 13 12.4105V1.48926ZM12 2.20807C9.933 3.15127 7.461 3.67846 5 3.85221V10.0445C5.36324 10.0649 5.72599 10.093 6.088 10.1289C8.141 10.3315 10.126 10.7921 12 11.6778V2.20807ZM4 9.99291V3.90582C3.32 3.93263 2.601 3.94851 1.992 3.95844C1.46234 3.96573 0.956756 4.17929 0.584163 4.55311C0.21157 4.92694 0.00178478 5.43111 0 5.95702L0 7.9427C0 9.04078 0.896 9.9244 1.994 9.93731C2.15934 9.93936 2.32468 9.94201 2.49 9.94525C2.9935 9.95524 3.49687 9.97112 4 9.99291ZM5.39 11.0662C5.675 11.087 5.959 11.1128 6.24 11.1436L6.493 12.8215C6.52053 12.9651 6.51579 13.1129 6.47911 13.2545C6.44243 13.3961 6.37473 13.5278 6.28083 13.6404C6.18692 13.753 6.06914 13.8436 5.9359 13.9058C5.80265 13.9679 5.65722 14.0001 5.51 14H4.962C4.76762 14 4.57746 13.9437 4.41478 13.8381C4.2521 13.7325 4.12395 13.5821 4.046 13.4053L2.732 10.9431C3.29625 10.957 3.8603 10.9782 4.424 11.0066C4.751 11.0235 5.074 11.0433 5.39 11.0662Z"
                fill="#FFBF36"
            />
        </svg>
    );

    const NotReadIcon = (
        <svg
            width="6"
            height="6"
            viewBox="0 0 6 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="6" height="6" rx="3" fill="#FFBF36" />
        </svg>
    );

    const navigate = useNavigate();

    const onClick = () => navigate(`/home/notice/view/${id}`);

    useEffect(() => {
        if (reservedDatetime) setShowDate(reservedDatetime.split(' ')[0]);
        else setShowDate(createdAt.split(' ')[0]);
    }, []);

    return (
        <NoticeItemContainer
            onClick={onClick}
            isFixTop={isFixTop}
            isNotRead={isNotRead}
        >
            <NoticeTitle isFixTop={isFixTop} isNotRead={isNotRead}>
                {isFixTop ? MegaphoneIcon : null}
                {!isFixTop && isNotRead ? NotReadIcon : null}
                {title}
            </NoticeTitle>
            <NoticeSummary>
                <NoticeBody isNotRead={isNotRead}>{notice.content}</NoticeBody>
                <NoticeDate>{showDate}</NoticeDate>
            </NoticeSummary>
        </NoticeItemContainer>
    );
}

const NoticeItemContainer = styled.div`
    margin-bottom: 0.8rem;
    padding: 0.8rem;
    border-bottom: 0.1rem solid;
    border-color: ${(props) =>
        props.isNotRead ? UserColors.blue02 : UserColors.gray01};
    cursor: pointer;
    /* 상단고정 공지사항 */
    ${(props) =>
        props.isFixTop
            ? `
        border:none;
        border-radius: 0.8rem;
    `
            : ''}
    background-color:${(props) =>
        props.isFixTop
            ? props.isNotRead
                ? UserColors.blue02
                : '#F3F3F3'
            : ''};
`;

const NoticeTitle = styled.div`
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${(props) => (props.isNotRead ? UserColors.blue01 : '')};

    & {
        svg {
            margin-right: 0.4rem;
            position: relative;
            top: ${(props) =>
                !props.isFixTop && props.isNotRead ? '-0.75rem' : '0.15rem'};
        }

        svg > path {
            fill: ${(props) => (!props.isNotRead ? UserColors.black02 : '')};
        }
    }
`;

const NoticeSummary = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 3rem;
`;

const NoticeBody = styled.div`
    font-size: 1.3rem;
    flex-grow: 1;
    color: ${(props) =>
        props.isNotRead ? UserColors.black01 : UserColors.black02};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.8rem;
`;

const NoticeDate = styled.div`
    font-size: 1.1rem;
    color: ${UserColors.gray03};
`;

export default UserNoticeItem;
