import React, { useState, useRef } from 'react';
import { addNotice } from '../../lib/notices';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/UI/Admin/Spinner';
import reservationIcon from '../../assets/reservation.svg';
import { Urls } from '../../constants/urls';

import styled from 'styled-components';

const AdminAddNotice = () => {
    const reservedDateRef = useRef();
    const reservedTimeRef = useRef();

    const [inputObj, setInputObj] = useState({
        title: null,
        content: null,
        redirectUrl: null,
        sendSMS: false,
        fixTop: false,
        isVisible: true,
        reservedDatetime: null,
    });
    const [isReservationNotice, setIsReservationNotice] = useState(false);
    const [loading, setLoading] = useState(false);

    const inputChangeHandler = (event) => {
        const { name, value } = event.target;

        if (name === 'fixTop' || name === 'isVisible' || name === 'sendSMS') {
            if (event.target.checked) {
                setInputObj({
                    ...inputObj,
                    [name]: true,
                });
            } else {
                setInputObj({
                    ...inputObj,
                    [name]: false,
                });
            }
        } else {
            setInputObj({
                ...inputObj,
                [name]: value,
            });
        }
    };

    const setRedirectUrlToKaKaoChannel = () => {
        setInputObj({
            ...inputObj,
            redirectUrl: Urls.kakaoTalkChannelUrl,
        });
    };

    const writeReservationNoticeHandler = () => {
        console.log('clicked');
        setIsReservationNotice(true);
    };

    const writeCommonNoticeHandler = () => {
        setIsReservationNotice(false);
    };

    const navigate = useNavigate();
    const submitFormHandler = async (event) => {
        event.preventDefault();
        if (isReservationNotice) {
            inputObj.reservedDatetime = `${reservedDateRef.current.value}T${reservedTimeRef.current.value}:00+09:00`;
            console.log(inputObj.reservedDatetime);
        }
        setLoading(true);
        const res = await addNotice(inputObj);
        if (res.code) {
            if (res.code === 442) {
                alert('입력값이 올바르지 않습니다.');
                setLoading(false);
                return;
            }
        }
        console.log(res);
        setLoading(false);
        alert('새로운 공지사항이 추가되었습니다.');
        navigate(-1);
    };

    return (
        <NoticeDetailContainer>
            <NoticeDetailWrapper>
                <form onSubmit={submitFormHandler}>
                    {loading && <Spinner />}
                    <NoticeStatusWrapper>
                        <div>
                            <input
                                name="common"
                                type="checkbox"
                                id="common"
                                checked={!isReservationNotice}
                                onChange={writeCommonNoticeHandler}
                            />
                            <label htmlFor="common">일반 공지사항</label>
                        </div>
                        <div>
                            <input
                                name="reservation"
                                type="checkbox"
                                id="reservation"
                                checked={isReservationNotice}
                                onChange={writeReservationNoticeHandler}
                            />
                            <label htmlFor="reservation">예약 공지사항</label>
                        </div>
                    </NoticeStatusWrapper>
                    <NoticeContentWrapper>
                        <div>
                            <label htmlFor="title">제목</label>
                            <input
                                name="title"
                                type="text"
                                id="title"
                                onChange={inputChangeHandler}
                                value={inputObj.title ? inputObj.title : ''}
                            />
                        </div>
                        <div>
                            <label htmlFor="content">내용</label>
                            <textarea
                                name="content"
                                id="content"
                                onChange={inputChangeHandler}
                                value={inputObj.content ? inputObj.content : ''}
                            />
                        </div>
                        <div>
                            <label htmlFor="redirectUrl">첨부할 링크</label>
                            <div>
                                <input
                                    name="redirectUrl"
                                    type="text"
                                    id="redirectUrl"
                                    onChange={inputChangeHandler}
                                    value={
                                        inputObj.redirectUrl
                                            ? inputObj.redirectUrl
                                            : ''
                                    }
                                />
                                <button
                                    type="button"
                                    onClick={setRedirectUrlToKaKaoChannel}
                                >
                                    카카오톡 채널 링크로 설정
                                </button>
                            </div>
                        </div>
                    </NoticeContentWrapper>
                    <NoticeStatusCheckbox>
                        <div>
                            <input
                                name="fixTop"
                                type="checkbox"
                                id="fixTop"
                                onClick={inputChangeHandler}
                                value={inputObj.fixTop}
                            />
                            <label htmlFor="fixTop">상단 고정</label>
                            {inputObj.fixTop && (
                                <Warning>
                                    다른 공지사항이 상단 고정이 되어 있는 경우,
                                    기존의 상단 고정이 해제되며 해당 공지사항이
                                    상단 고정됩니다.
                                </Warning>
                            )}
                        </div>
                        {!isReservationNotice && (
                            <div>
                                <input
                                    name="isVisible"
                                    type="checkbox"
                                    id="isVisible"
                                    onClick={inputChangeHandler}
                                    value={inputObj.isVisible}
                                    defaultChecked={true}
                                />
                                <label htmlFor="isVisible">게시 여부</label>
                                {inputObj.isVisible ? (
                                    <Warning>
                                        공지사항이 유저에게 게시됩니다.
                                    </Warning>
                                ) : (
                                    <Warning>
                                        공지사항이 유저에게 보이지 않게 합니다.
                                    </Warning>
                                )}
                            </div>
                        )}
                        <div>
                            <input
                                name="sendSMS"
                                type="checkbox"
                                id="sendSMS"
                                onClick={inputChangeHandler}
                                value={inputObj.sendSMS}
                            />
                            <label htmlFor="sendSMS">문자 전송</label>
                            <Warning>
                                체크 시, 새로운 공지사항이 게시되었다는 알림
                                문자도 함께 전송됩니다.
                            </Warning>
                        </div>
                    </NoticeStatusCheckbox>
                    {isReservationNotice && (
                        <NoticeReservationWrapper>
                            <ReservationTitle>
                                <img src={reservationIcon} alt="" />
                                예약 일정 등록
                            </ReservationTitle>
                            <ReservationContent>
                                <input
                                    name="reservedDate"
                                    type="date"
                                    id="reservedDate"
                                    ref={reservedDateRef}
                                />
                                <input
                                    name="reservedTime"
                                    type="time"
                                    id="reservedTime"
                                    ref={reservedTimeRef}
                                />
                            </ReservationContent>
                        </NoticeReservationWrapper>
                    )}
                    <NoticeButtonWrapper>
                        <button type="submit">등록하기</button>
                    </NoticeButtonWrapper>
                </form>
            </NoticeDetailWrapper>
        </NoticeDetailContainer>
    );
};

const NoticeDetailContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;

const NoticeReservationWrapper = styled.div`
    background-color: #f8f8f9;
    padding: 1rem 0.8rem;
    font-size: 0.9rem;
    color: #007aff;
    padding-bottom: 0;

    & {
        input {
            width: 8rem;
        }
    }
`;

const ReservationTitle = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 0.5rem;
    gap: 0.07rem;

    & {
        img {
            position: relative;
            top: -0.06rem;
        }
    }
`;

const ReservationContent = styled.div`
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1.05rem;
    color: #515151;
    gap: 1rem;

    & {
        b {
            color: rgb(12, 19, 28);
        }
    }
`;

const NoticeDetailWrapper = styled.div`
    height: fit-content;
    margin-bottom: 1rem;
    width: 80%;
    max-width: 50rem;
    border: 1px solid #babfc7;
`;

const NoticeStatusWrapper = styled.div`
    background: #ffffff;
    width: 100%;
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid #babfc7;
    padding-top: 1.15rem;
    gap: 0.4rem;

    & {
        div {
            display: flex;
            align-items: center;
        }

        label {
            font-size: 0.8rem;
            margin-left: 0.1rem;
        }
    }
`;

const NoticeStatusCheckbox = styled.div`
    gap: 0.4rem;
    padding: 1rem;
    background-color: #f8f8f9;
    padding-bottom: 0;

    & {
        div {
            display: flex;
            align-items: center;
        }

        label {
            font-size: 0.8rem;
            margin-left: 0.1rem;
        }
    }
`;

const NoticeContentWrapper = styled.div`
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #f8f8f9;

    & {
        > div {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            font-size: 0.8rem;

            & {
                label {
                    margin-left: 0.1rem;
                }

                input {
                    width: 50%;
                    padding: 0.3rem;
                }

                textarea {
                    height: 15rem;
                    padding: 0.5rem;
                    resize: none;
                }

                input,
                textarea {
                    border: 1px solid #babfc7;
                    background: rgba(118, 171, 255, 0.1);
                }

                input:focus,
                textarea:focus {
                    outline: none;
                }

                input:disabled,
                textarea:disabled {
                    background-color: #ffffff;
                }

                button {
                    width: 10rem;
                }
            }
        }

        > div > div {
            display: flex;

            input {
                margin-right: 0.5rem;
            }
        }
    }
`;

const NoticeButtonWrapper = styled.div`
    text-align: right;
    padding: 0.8rem;
    padding-top: 0;
    background-color: #f5f5f5;

    & {
        button {
            margin: 0 0.2rem;
        }
    }
`;

const Warning = styled.span`
    font-size: 0.8rem;
    color: red;
    margin-left: 0.5rem;
`;

export default AdminAddNotice;
