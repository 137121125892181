import { formatTimezoneWithTime } from './formatTimezone';
import axiosInstance from './axiosInstance';
import axios from 'axios';

const getMessages = async (userId) => {
    if (!userId) return;
    let failed;
    const res = await axiosInstance
        .get(`/messages/user/${userId}`)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const getMessagesByUser = async (userId) => {
    if (!userId) return;
    let failed;
    const res = await axios.get(`/messages/user/${userId}`).catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }
    return res.data;
};

const getMessagesByUserMod = async (userId) => {
    if (!userId) return false;
    let failed;
    const res = await axios.get(`/messages/user/${userId}`).catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }
    return res.data.map((r) => {
        return {
            id: r.id,
            content: r.content,
            sendSMS: r.sendSMS,
            createdAt: formatTimezoneWithTime(r.createdAt),
            updatedAt: formatTimezoneWithTime(r.updatedAt),
            redirectUrl: r.redirectUrl,
        };
    });
};

const createMessage = async (body) => {
    let failed;
    const res = await axiosInstance.post('/messages', body).catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }
    return res.data;
};

export { getMessages, getMessagesByUser, createMessage, getMessagesByUserMod };
