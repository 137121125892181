import React, { useState, useContext } from 'react';
import Modal from '../UI/Admin/Modal';
import camelCaseToRegularText from '../../lib/toRegularText';
import FormInputArea from '../UI/Admin/FormInputArea';
import FormInput from '../UI/Admin/FormInput';
import FormLabel from '../UI/Admin/FormLabel';
import AuthContext from '../../store/AuthContext';
import FormSelect from '../UI/Admin/FormSelect';
import { deleteAttribute, updateAttribute } from '../../lib/attributes';
import CloseIcon from '../../assets/close.svg';
import HelpIcon from '../../assets/help.svg';
import Spinner from '../UI/Admin/Spinner';

import styles from './UpdateColumns.module.css';

const UpdateColumns = (props) => {
    const authCtx = useContext(AuthContext);
    const [editingColumn, setEditingColumn] = useState('');
    const [loading, setLoading] = useState(false);

    const showInputs = (inputId) => {
        document.getElementById(`${inputId}_name`).style.display =
            'inline-block';
        document.getElementById(`${inputId}_type`).style.display =
            'inline-block';
    };

    const hideInputs = (inputId) => {
        document.getElementById(`${inputId}_name`).style.display = 'none';
        document.getElementById(`${inputId}_type`).style.display = 'none';
    };

    const enableInput = (event, id, name) => {
        setEditingColumn(name);
        showInputs(id);
    };

    const updateColumn = async (event, param) => {
        const newName = document.getElementById(`${param}_name`).value;
        const newType = document.getElementById(`${param}_type`).value;

        setLoading(true);
        const res = await updateAttribute(param, newName, newType);
        if (res.code) {
            if (res.code === 404) {
                alert(
                    '해당 열을 찾을 수 없습니다. 새로고침 후 재시도 해주세요.'
                );
                setLoading(false);
                return;
            } else if (res.code === 442) {
                alert('입력값이 올바르지 않습니다.');
                setLoading(false);
                return;
            } else if (res.code === 500) {
                alert('알 수 없는 오류가 발생했습니다. 나중에 재시도해주세요.');
                setLoading(false);
                props.onClose();
                return;
            }
        }
        console.log(res);
        setLoading(false);
        alert('수정되었습니다');
        props.onUpdate();
        setEditingColumn('');
    };

    const deleteColumn = async (event, param) => {
        const proceed = confirm(
            '열을 삭제하면 관련된 데이터도 모두 삭제됩니다. 삭제하시겠습니까?'
        );
        if (proceed === true) {
            setLoading(true);
            const res = await deleteAttribute(param);
            if (res.code) {
                if (res.code === 404) {
                    alert(
                        '해당 열이 존재하지 않습니다. 새로고침 후 재시도 해주세요.'
                    );
                    return;
                }
                return;
            }
            props.onUpdate();
            alert('열이 삭제되었습니다.');
            setLoading(false);
        }
    };

    const cancelUpdate = (event, param) => {
        hideInputs(param);
        setEditingColumn('');
    };

    return (
        <Modal onClose={props.onClose}>
            <div className="adminModalHeader">
                <div>열 수정 및 삭제</div>
                <div onClick={props.onClose} className="adminModalCloseBtn">
                    <img src={CloseIcon} alt="" />
                </div>
            </div>
            <div className="adminModalContent">
                {loading && <Spinner />}
                <div className="adminModalInfo">
                    <img src={HelpIcon} alt="" />
                    도움말
                </div>
                <ul>
                    <li>
                        <span className="emp2">수정 및 삭제 권한</span>이 있는
                        데이터만 나타납니다.
                    </li>
                </ul>
                <div className="adminModalFormWrapper">
                    <FormInputArea className={styles['input-area']}>
                        {props.attributes
                            .filter(
                                (column) =>
                                    column.creator.id === authCtx.user.id
                            )
                            .map((column) => {
                                return (
                                    <div
                                        key={column.id}
                                        className="adminInputRowWrapper"
                                    >
                                        <FormLabel
                                            for={column.name}
                                            label={camelCaseToRegularText(
                                                column.name
                                            )}
                                            className="adminInputLabel"
                                        >
                                            {camelCaseToRegularText(
                                                column.name
                                            )}
                                        </FormLabel>
                                        <FormInput
                                            type="text"
                                            placeholder="새로운 이름을 입력하세요"
                                            id={`${column.id}_name`}
                                            name={column.name}
                                            pattern="[a-zA-Z0-9]+"
                                            className={styles['new-name']}
                                        />
                                        <FormSelect
                                            name="isNumber"
                                            id={`${column.id}_type`}
                                            required={true}
                                            options={[
                                                { name: '숫자', value: true },
                                                { name: '문자', value: false },
                                            ]}
                                            className={styles['new-type']}
                                        />
                                        {editingColumn === column.name ? (
                                            <button
                                                type="button"
                                                onClick={(event) =>
                                                    updateColumn(
                                                        event,
                                                        column.id
                                                    )
                                                }
                                                className="adminMarginBtn"
                                            >
                                                저장
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                onClick={(event) =>
                                                    enableInput(
                                                        event,
                                                        column.id,
                                                        column.name
                                                    )
                                                }
                                                className="adminMarginBtn"
                                            >
                                                수정
                                            </button>
                                        )}
                                        {editingColumn === column.name ? (
                                            <button
                                                type="button"
                                                onClick={(event) =>
                                                    cancelUpdate(
                                                        event,
                                                        column.id
                                                    )
                                                }
                                                className="adminMarginBtn"
                                            >
                                                취소
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                onClick={(event) =>
                                                    deleteColumn(
                                                        event,
                                                        column.id
                                                    )
                                                }
                                                className="adminMarginBtn"
                                            >
                                                삭제
                                            </button>
                                        )}
                                    </div>
                                );
                            })}
                    </FormInputArea>
                </div>
            </div>
        </Modal>
    );
};

export default UpdateColumns;
