import React, { memo, useMemo, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { topBarGraphOptions, bottomBarGraphOptions } from '../../lib/chart-js';
import { viewCountBarChartData } from '../../lib/requestChartData';
import { cloneDeep } from 'lodash';

import styles from './VideoBarGraph.module.css';

const VideoBarGraph = (props) => {
    const topInitialData = useMemo(() => {
        return {
            labels: new Array(5).fill('정보없음'),
            datasets: [
                {
                    label: '누적 재생횟수',
                    data: new Array(5).fill(0),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        };
    }, []);

    const lowInitialData = cloneDeep(topInitialData);
    lowInitialData.datasets[0].backgroundColor = 'rgba(100,124,187,0.5)';
    const [chartData, setChartData] = useState(null);
    const [topData, setTopData] = useState(topInitialData);
    const [lowData, setLowData] = useState(lowInitialData);

    useEffect(() => {
        const getVideoBarGraphData = async () => {
            const res = await viewCountBarChartData();
            console.log(res);
            if (res.code) {
                console.log(res);
                return;
            }
            setChartData(res.data);
            setTopData((prev) => {
                prev.labels = res.high.map((value) => value[0]);
                prev.datasets[0].data = res.high.map((value) => value[1]);
                return prev;
            });
            setLowData((prev) => {
                prev.labels = res.low.map((value) => value[0]);
                prev.datasets[0].data = res.low.map((value) => value[1]);
                return prev;
            });
        };
        getVideoBarGraphData();
    }, []);

    return (
        <div className={styles['bar-chart-area']}>
            <div className={styles.left}>
                <Bar
                    options={topBarGraphOptions}
                    data={topData}
                    redraw={true}
                />
            </div>
            <div className={styles.right}>
                <Bar
                    options={bottomBarGraphOptions}
                    data={lowData}
                    redraw={true}
                />
            </div>
        </div>
    );
};

export default memo(VideoBarGraph);
