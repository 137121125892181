const calendarHeatmapInitialData = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    console.log(currentYear, currentMonth);

    const startDate = new Date(currentYear, currentMonth - 5, 1).toISOString();
    const endDate = new Date(currentYear, currentMonth + 1, 0).toISOString();
    console.log(startDate, endDate);

    const lastDays = [];
    for (let i = 5; i >= 0; i--) {
        lastDays.push(new Date(currentYear, currentMonth - i + 1, 0).getDate());
    }
    console.log('lastDays', lastDays);
    let totalDays = 0;
    lastDays.forEach((num) => (totalDays += num));
    console.log('totalDays', totalDays);

    const formattedData = [];
    lastDays.forEach((value, index) => {
        const monthlyEmptyData = Array.from({ length: value }, () => ({
            date: null,
            count: 0,
        }));
        for (let i = 0; i < value; i++) {
            const date = new Date(
                currentYear,
                currentMonth - (5 - index),
                i + 1
            );
            monthlyEmptyData[i].date = `${currentYear}-${
                (date.getMonth() + 1).toString().length === 1
                    ? '0' + (date.getMonth() + 1)
                    : date.getMonth() + 1
            }-${
                date.getDate().toString().length === 1
                    ? '0' + date.getDate()
                    : date.getDate()
            }`;
        }
        formattedData.push.apply(formattedData, monthlyEmptyData); // 6개월 동안의 모든 날짜와 count: 0 이 들어가 있는 array
    });

    console.log(formattedData);
    return formattedData;
};

export default calendarHeatmapInitialData;
