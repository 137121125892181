import { adminLogin, userLogin, onSilentRefresh } from './lib/auth';
import { getUsersData } from './lib/users';
import {
    addActivity,
    getAllActivities,
    getUserActivities,
} from './lib/activities';
import {
    addAttribute,
    getAttributes,
    getAttribute,
    updateAttribute,
    deleteAttribute,
} from './lib/attributes';
import {
    addResource,
    deleteResource,
    getAllResources,
    getResourceById,
    updateResource,
} from './lib/resources';

const AxiosTest = () => {
    /* ========== User & Auth ============ */
    const onAdminLogin = async () => {
        const res = await adminLogin('test', 'password');
        console.log(res);
    };

    const onAdminRefresh = async () => {
        onSilentRefresh();
    };

    const onUserLogin = async () => {
        const res = await userLogin('021', 'userSuriming');
        console.log(res);
    };

    const onGetAllUsers = async () => {
        const res = await getUsersData();
        console.log(res);
    };

    /* ========== Resources ============ */

    const onGetAllResources = async () => {
        const res = await getAllResources();
        console.log(res);
    };

    const onGetResource = async () => {
        const res = await getResourceById('62cfc8a1ff30f40cbb2b1a5c');
        console.log(res);
    };

    const onUpdateResource = async () => {
        const res = await updateResource(
            '62cfc8a1ff30f40cbb2b1a5c',
            'updated leg 7',
            'https://youtu.be/rUv8Y7nk9xy'
        );
        console.log(res);
    };

    const onAddResource = async () => {
        const res = await addResource('arm 1', 'https://youtu.be/rUv8Y7nk9zw');
        console.log(res);
    };

    const onDeleteResource = async () => {
        const res = await deleteResource('62c53ad373e0daf055913581');
        console.log(res);
    };

    /* ========== Attributes ============ */

    const onGetAttributes = async () => {
        const res = await getAttributes();
        console.log(res);
    };

    const onAddNewAttribute = async () => {
        const res = await addAttribute('add new attribute', false);
        console.log(res);
    };

    const onDeleteAttribute = async () => {
        const res = await deleteAttribute('62e0acac4bd80236bee93739');
        console.log(res);
    };

    const onUpdateAttribute = async () => {
        const res = await updateAttribute(
            '62e2233d6e42e14dea1a1918',
            'updating default'
        );
        console.log(res);
    };

    const onGetAttributeById = async () => {
        const res = await getAttribute('62e0ac8c4bd80236bee93735');
        console.log(res);
    };

    /* ========== Activities ============ */

    const onGetUserActivities = async () => {
        const res = await getUserActivities('62df6bb8a7f6ec61cb73e2d6');
        console.log(res);
    };

    const onAddActivity = async (
        videoResourceId,
        viewDatetime,
        viewDuration,
        status
    ) => {
        const res = await addActivity(
            videoResourceId,
            viewDatetime,
            viewDuration,
            status
        );
        console.log(res);
    };

    const GetAllActivities = async () => {
        const res = await getAllActivities(100, '633bd638e9acffd5716aec0f');
        console.log(res);
    };

    return (
        <div>
            <p>Test Axios Requests</p>
            <p>Check out your console!</p>
            <div>
                <p>Users & Auth</p>
                <button onClick={onAdminLogin}>Admin Login</button>
                <button onClick={onAdminRefresh}>Admin Refresh</button>
                <button onClick={onUserLogin}>User Login</button>
                <button onClick={onGetAllUsers}>Get all users</button>
                <button>Get user by username</button>
            </div>
            <div>
                <p>Attributes</p>
                <button onClick={onGetAttributes}>Get all attributes</button>
                <button onClick={onGetAttributeById}>
                    Get attribute by Id
                </button>
                <button onClick={onAddNewAttribute}>Add new attribute</button>
                <button onClick={onUpdateAttribute}>Update attribute</button>
                <button onClick={onDeleteAttribute}>Delete attribute</button>
            </div>
            <div>
                <p>Resources</p>
                <button onClick={onGetAllResources}>Get all resources</button>
                <button onClick={onGetResource}>Get resource by Id</button>
                <button onClick={onDeleteResource}>Delete resource</button>
                <button onClick={onUpdateResource}>Update Resource</button>
                <button onClick={onAddResource}>Add Resource</button>
            </div>
            <div>
                <p>Activities</p>
                <button onClick={onAddActivity}>Post activity</button>
                <button disabled onClick={onGetUserActivities}>
                    Get user's activities
                </button>
                <button onClick={GetAllActivities}>Get all activities</button>
            </div>
        </div>
    );
};

export default AxiosTest;
