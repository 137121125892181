import { useEffect, useState, useRef } from 'react';
import UserMessage from './UserMessage';
import styled from 'styled-components';
import UserColors from '../../constants/colors';
import LoadingDots from '../UI/User/LoadingDots';
import { NoResourceError } from '../../constants/styled';
import useMessages from '../../hooks/useMessages';

function UserMessages() {
    const container = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [noResourceError, setNoResourceError] = useState(false);
    const { messages } = useMessages();

    useEffect(() => {
        if (messages.length) {
            setIsLoading(false);
            setNoResourceError(false);
            container.current.scrollTop = container.current.scrollHeight;
        } else {
            setNoResourceError(true);
        }

        // 마지막 접속시간 기록
        localStorage.setItem('MessageLastChecked', Date.now());
    }, [messages]);

    return (
        <MessagesContainer ref={container} isLoading={isLoading}>
            <UserMessage />
            <LoadingArea isLoading={isLoading}>
                <LoadingDots
                    size={'1.5rem'}
                    color={UserColors.blue01}
                    gap={'0.8rem'}
                    transformHeight={'-50%'}
                    isLoading={isLoading}
                />
            </LoadingArea>
            {noResourceError && (
                <NoResourceError>메세지가 아직 없어요</NoResourceError>
            )}
        </MessagesContainer>
    );
}

const MessagesContainer = styled.div`
    background-color: ${UserColors.blue02};
    height: calc(100vh - (4.4rem) - (6.5rem));
    overflow-y: scroll;
    overflow: ${(props) => (props.isLoading ? 'hidden' : '')};
    margin-bottom: 6.5rem;
    padding: 0.5rem 0;
    padding-bottom: 1.2rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const LoadingArea = styled.div`
    opacity: ${(props) => (props.isLoading ? 1 : 0)};
    visibility: ${(props) => (props.isLoading ? 'visible' : 'hidden')};
    transition: 1s ease;
    background-color: ${UserColors.blue02};
    width: 100%;
    height: calc(100vh - (4.4rem) - (6.5rem));
    position: fixed;
    bottom: 6.5rem;
    z-index: 1000;
`;

export default UserMessages;
