import moment from 'moment';
import UserMessageItem from './UserMessageItem';
import styled from 'styled-components';
import UserColors from '../../constants/colors';
import useMessages from '../../hooks/useMessages';

function UserMessage() {
    const { messages } = useMessages();
    const days = ['일', '월', '화', '수', '목', '금', '토'];
    const sections = {};
    return (
        <>
            {!!messages.length &&
                messages.map((item, index) => {
                    const monthDate = moment(item.createdAt).format(
                        'YYYY년 MM월 DD일'
                    );
                    const day = moment(item.createdAt).day();
                    if (sections[monthDate])
                        return <UserMessageItem message={item} key={index} />;
                    else {
                        sections[monthDate] = 1;
                        return (
                            <>
                                <SectionLine
                                    monthDate={`${monthDate} ${days[day]}요일`}
                                >
                                    <hr />
                                </SectionLine>
                                <UserMessageItem message={item} key={index} />
                            </>
                        );
                    }
                })}
        </>
    );
}

const SectionLine = styled.div`
    padding: 2rem 0 0.35rem 0;
    position: relative;

    & {
        hr {
            border: none;
            border-top: 0.5px solid ${UserColors.gray03};
            overflow: visible;
            text-align: center;
            height: 1.7rem;
        }
        hr:before {
            content: '';
            background: ${UserColors.blue02};
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 14.5rem;
            height: 1.6rem;
        }
        hr:after {
            content: '${(props) => `${props.monthDate}`}';
            position: relative;
            top: -0.7rem;
            background: ${UserColors.blue03};
            padding: 0.2rem 0.6rem;
            padding-top: 0.3rem;
            color: #868e96;
            font-size: 1rem;
            border-radius: 8px;
            margin: 0 1rem;
            color: ${UserColors.blue01};
        }
    }
`;

export default UserMessage;
