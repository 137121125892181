import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import UserColors from '../../constants/colors';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/UI/User/LoadingSpinner';
import Placeholder from '../../components/UI/User/Placeholder';
import NoNotiIcon from '../../assets/no-noti.svg';
import useMessages from '../../hooks/useMessages';
import useNotices from '../../hooks/useNotices';

const SvgIcons = {
    메세지: (
        <svg
            width="15"
            height="11"
            viewBox="0 0 15 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.07143 1V9C1.07143 9.26522 1.18431 9.51957 1.38524 9.70711C1.58617 9.89464 1.8587 10 2.14286 10H12.8571C13.1413 10 13.4138 9.89464 13.6148 9.70711C13.8157 9.51957 13.9286 9.26522 13.9286 9V1H1.07143ZM1.07143 0H13.9286C14.2127 0 14.4853 0.105357 14.6862 0.292893C14.8871 0.48043 15 0.734784 15 1V9C15 9.53043 14.7742 10.0391 14.3724 10.4142C13.9705 10.7893 13.4255 11 12.8571 11H2.14286C1.57454 11 1.02949 10.7893 0.627628 10.4142C0.225765 10.0391 0 9.53043 0 9V1C0 0.734784 0.112882 0.48043 0.313814 0.292893C0.514746 0.105357 0.787268 0 1.07143 0Z"
                fill="#FFBF36"
            />
            <path
                d="M14.0625 1L9.91929 5.42C9.61759 5.74189 9.24565 5.99986 8.82839 6.17661C8.41114 6.35336 7.95822 6.44479 7.5 6.44479C7.04178 6.44479 6.58886 6.35336 6.17161 6.17661C5.75435 5.99986 5.38241 5.74189 5.08071 5.42L0.9375 1H14.0625ZM2.36143 1L5.88643 4.761C6.08756 4.97565 6.33554 5.14768 6.61374 5.26554C6.89195 5.38341 7.19394 5.44439 7.49946 5.44439C7.80499 5.44439 8.10698 5.38341 8.38518 5.26554C8.66339 5.14768 8.91137 4.97565 9.1125 4.761L12.6386 1H2.36143Z"
                fill="#FFBF36"
            />
        </svg>
    ),
    공지사항: (
        <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.375 1.38288C11.375 1.01612 11.5133 0.664377 11.7594 0.405036C12.0056 0.145696 12.3394 0 12.6875 0C13.0356 0 13.3694 0.145696 13.6156 0.405036C13.8617 0.664377 14 1.01612 14 1.38288V11.524C14 11.8908 13.8617 12.2425 13.6156 12.5019C13.3694 12.7612 13.0356 12.9069 12.6875 12.9069C12.3394 12.9069 12.0056 12.7612 11.7594 12.5019C11.5133 12.2425 11.375 11.8908 11.375 11.524V1.38288ZM10.5 2.05035C8.69137 2.92618 6.52838 3.41572 4.375 3.57705V9.32707C4.69283 9.34595 5.01024 9.37208 5.327 9.40543C7.12337 9.5935 8.86025 10.0213 10.5 10.8436V2.05035ZM3.5 9.27913V3.62683C2.905 3.65173 2.27588 3.66648 1.743 3.6757C1.27955 3.68246 0.837162 3.88077 0.511143 4.22789C0.185124 4.57501 0.00156169 5.04317 0 5.53152L0 7.37536C0 8.39501 0.784 9.21552 1.74475 9.2275C1.88942 9.2294 2.03409 9.23186 2.17875 9.23488C2.61932 9.24415 3.05976 9.2589 3.5 9.27913ZM4.71625 10.2757C4.96562 10.2951 5.21412 10.3191 5.46 10.3476L5.68138 11.9057C5.70546 12.039 5.70131 12.1763 5.66922 12.3078C5.63713 12.4392 5.57789 12.5616 5.49572 12.6661C5.41356 12.7706 5.3105 12.8548 5.19391 12.9125C5.07732 12.9702 4.95007 13.0001 4.82125 13H4.34175C4.17167 13 4.00528 12.9477 3.86293 12.8497C3.72059 12.7516 3.60845 12.6119 3.54025 12.4478L2.3905 10.1614C2.88422 10.1744 3.37776 10.1941 3.871 10.2204C4.15712 10.2361 4.43975 10.2545 4.71625 10.2757Z"
                fill="#FFBF36"
            />
        </svg>
    ),
};

const UserNotifications = () => {
    const { messages } = useMessages();
    const { notices } = useNotices();
    const [customMessages, setCustomMessages] = useState([]);
    const [customNotices, setCustomNotices] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [noticeRead, setNoticeRead] = useState([]);
    const [messageRead, setMessageRead] = useState(moment('2000-01-01'));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const localStorage = window.localStorage;
        const NoticeRead = JSON.parse(localStorage.getItem('NoticeRead')) || [];
        setNoticeRead(NoticeRead);
        const MessageLastChecked =
            JSON.parse(localStorage.getItem('MessageLastChecked')) ||
            '2000-01-01';
        setMessageRead(moment(MessageLastChecked));
    }, []);

    useEffect(() => {
        setCustomMessages(
            messages.map((v) => {
                v.sentAt = v.createdAt;
                v.category = '메세지';
                return v;
            })
        );

        setCustomNotices(
            notices
                .filter((v) => v.isVisible)
                .map((v) => {
                    if (v.reservedDatetime) v.sentAt = v.reservedDatetime;
                    else v.sentAt = v.createdAt;
                    v.category = '공지사항';
                    return v;
                })
        );
    }, [messages, notices]);

    useEffect(() => {
        setNotifications([...customMessages, ...customNotices]);
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, [customMessages, customNotices]);

    return (
        <NotificationContainer>
            <LoadingSpinner isLoading={isLoading} isNotice={true} />
            {/* 안읽은 알림 */}
            {!!notifications.length &&
                notifications
                    .filter((v) => {
                        if (v.category === '메세지')
                            return messageRead.isBefore(v.sentAt);
                        else return !noticeRead.includes(v.id);
                    })
                    .sort((a, b) => moment(b.sentAt).diff(a.sentAt))
                    .map((item, idx) => {
                        return (
                            <Notification
                                item={item}
                                idx={idx}
                                isRead={false}
                                key={`notRead : ${item.id}`}
                            />
                        );
                    })}
            {/* 읽은 알림 */}
            {!!notifications.length &&
                notifications
                    .filter((v) => {
                        if (v.category === '메세지')
                            return !messageRead.isBefore(v.sentAt);
                        else return noticeRead.includes(v.id);
                    })
                    .sort((a, b) => moment(b.sentAt).diff(a.sentAt))
                    .map((item, idx) => {
                        return (
                            <Notification
                                item={item}
                                idx={idx}
                                isRead={true}
                                key={item.id}
                            />
                        );
                    })}
            {!notifications.length && !isLoading ? (
                <PlaceholderContainer>
                    <Placeholder
                        icon={NoNotiIcon}
                        title={'새로운 알림이 없어요'}
                    />
                </PlaceholderContainer>
            ) : null}
        </NotificationContainer>
    );
};

const Notification = ({ item, idx, isRead }) => {
    const navigate = useNavigate();

    const toMessages = () => navigate('/home/message');

    const toNotice = (v) => navigate(`/home/notice/view/${v.id}`);

    const navigateTo = (v) => {
        if (v.category === '메세지') toMessages();
        else toNotice(v);
    };

    return (
        <NotificationWrapper key={idx} onClick={() => navigateTo(item)}>
            <NotificationCategory isRead={isRead}>
                {SvgIcons[item.category]}
                {item.category}
            </NotificationCategory>
            <NotificationSummary>
                <NotificationBody isRead={isRead}>
                    {item.title || item.content}
                </NotificationBody>
                <NotificationDate>{item.sentAt.split(' ')[0]}</NotificationDate>
            </NotificationSummary>
        </NotificationWrapper>
    );
};

const NotificationContainer = styled.div`
    margin: 0.4rem 1.5rem;
`;

const NotificationWrapper = styled.div`
    margin-bottom: 0.8rem;
    padding: 0.8rem;
    cursor: pointer;
    animation: slideDown 0.8s ease-out;

    @keyframes slideDown {
        from {
            transform: translateY(-40%);
            opacity: 0;
        }
        to {
            transform: translateY(0%);
            opacity: 1;
        }
    }
`;

const NotificationCategory = styled.div`
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: ${(props) => (!props.isRead ? UserColors.blue01 : '')};

    & {
        svg {
            margin-right: 0.8rem;
            position: relative;
            top: 0.05rem;
        }

        svg path {
            fill: ${(props) => (props.isRead ? UserColors.black02 : '')};
        }
    }
`;

const NotificationSummary = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 3rem;
`;

const NotificationBody = styled.div`
    font-size: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${(props) => (props.isRead ? UserColors.black02 : '')};
`;

const NotificationDate = styled.div`
    font-size: 1.1rem;
    color: ${UserColors.gray03};
`;

const PlaceholderContainer = styled.div`
    margin-top: 10.9vh;
`;

export default UserNotifications;
