import React, { useRef, useState } from 'react';
import CloseIcon from '../../assets/close.svg';
import HelpIcon from '../../assets/help.svg';

import Modal from '../UI/Admin/Modal';
import FormInputArea from '../UI/Admin/FormInputArea';
import FormLabel from '../UI/Admin/FormLabel';
import FormInput from '../UI/Admin/FormInput';
import getFileExtension from '../../lib/getFileExtension';

import { getPreSignedUrlForSampleFile, uploadUserData } from '../../lib/users';
import Spinner from '../UI/Admin/Spinner';

const uploadUserFile = (props) => {
    const fileInputRef = useRef();
    const fileLabel = useRef();
    const [loading, setLoading] = useState(false);

    const submitFormHandler = async (event) => {
        event.preventDefault();

        // 파일 존재 여부 확인
        if (fileInputRef.current.files.length === 0) {
            alert('선택한 파일이 없습니다.');
            return false;
        }

        const fileExtension = getFileExtension(fileInputRef.current.value);
        console.log(fileExtension);
        if (fileExtension !== '.csv') {
            alert('파일의 확장자는 쉼표로 구분된 csv 이어야 합니다.');
            return;
        }
        const file = fileInputRef.current.files[0];
        console.log('파일 존재 체크');
        console.log(file);

        const formData = new FormData();
        formData.append('csvFile', file);

        setLoading(true);
        const res = await uploadUserData(formData);
        if (res.code) {
            console.log(res);
        }
        console.log(res);

        let addedColumnNames = '';
        if (res.newAttributes.length !== 0) {
            res.newAttributes.forEach((name) => {
                if (addedColumnNames === '') {
                    addedColumnNames = addedColumnNames + name;
                } else {
                    addedColumnNames = addedColumnNames + ', ' + name;
                }
            });
        }

        const alertMsg = `피험자 데이터가 등록되었습니다.\n\n추가된 피험자: ${
            res.addCount
        } 명\n수정된 피험자: ${res.updateCount} 명\n추가된 열: ${
            addedColumnNames === '' ? '없음' : addedColumnNames
        }`;
        setLoading(false);
        alert(alertMsg);
        props.onAdd();
        props.onClose();
    };

    const UpdateFileLabel = () => {
        if (fileInputRef.current.files.length === 0) {
            fileLabel.current.innerText = '파일을 선택해주세요';
            fileLabel.current.classList.remove('yesFile');
            fileLabel.current.classList.add('noFile');
        } else {
            fileLabel.current.innerText = fileInputRef.current.files[0].name;
            fileLabel.current.classList.remove('noFile');
            fileLabel.current.classList.add('yesFile');
        }
    };

    const sampleFileDownloadHandler = async () => {
        const res = await getPreSignedUrlForSampleFile();
        location.href = res;
    };

    return (
        <Modal onClose={props.onClose}>
            <div className="adminModalHeader">
                <div>피험자 데이터 등록</div>
                <div onClick={props.onClose} className="adminModalCloseBtn">
                    <img src={CloseIcon} alt="" />
                </div>
            </div>
            <div className="adminModalContent">
                {loading && <Spinner />}
                <div className="adminModalInfo">
                    <img src={HelpIcon} alt="" />
                    도움말
                </div>
                <ul>
                    <li>
                        쉼표로 구분된 <span className="emp1">csv</span> 형태의
                        파일이어야 합니다.
                    </li>
                    <li>
                        파일의 <span className="emp1">첫 행은 열 이름</span>이
                        포함되어야 합니다.
                        <br />열 이름은 현재까지 추가되어 있는{' '}
                        <span className="emp2">
                            기존의 열 이름과 동일해야
                        </span>{' '}
                        합니다.
                    </li>
                    <li>
                        새로 추가하려는 행의 이름을{' '}
                        <span className="emp2">영어</span>로 기입해주세요.
                    </li>
                    <li>
                        해당 액셀 파일을 다운받아 편집 후{' '}
                        <span className="emp2">csv 로 변환</span>한 뒤, 업로드
                        해주세요.
                        <br />
                        <span className="emp2">5번째 행</span>부터 편집하여야
                        하며, <span className="emp2">User Id</span> 와{' '}
                        <span className="emp2">Name</span> 행은 필수로
                        채워주세요.
                    </li>
                </ul>
                <div className="adminModalFormWrapper">
                    <form onSubmit={submitFormHandler}>
                        <button
                            style={{ marginRight: '2rem' }}
                            type="button"
                            onClick={sampleFileDownloadHandler}
                        >
                            샘플 파일 다운로드
                        </button>
                        <FormInputArea>
                            <div>
                                <FormLabel
                                    for="csvFile"
                                    label="파일 선택"
                                    className="adminInputFileLabel"
                                    ref={fileLabel}
                                >
                                    파일을 선택해주세요
                                </FormLabel>
                                <FormInput
                                    type="file"
                                    name="csvFile"
                                    placeholder="csv 파일 업로드"
                                    ref={fileInputRef}
                                    id="csvFile"
                                    className="adminInputFile"
                                    onChange={UpdateFileLabel}
                                />
                            </div>
                        </FormInputArea>
                        <button
                            type="submit"
                            className="adminUploadUserFileBtn"
                        >
                            등록
                        </button>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default uploadUserFile;
