import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://api.icaan.net/',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
});

axiosInstance.interceptors.request.use(
    async (config) => {
        console.log('axios interceptors');
        const response = await axios.post('auth/refresh').catch((err) => {
            console.log(err);
            if (err.response.status === 401 || err.response.status === 400) {
                alert(
                    '사용자 인증 정보가 유효하지 않습니다. 다시 로그인해주세요.'
                );
                localStorage.removeItem('currentUser');
                window.location.href = '/admin/login';
            }
        });
        console.log(response.data.message);
        const { accessToken } = response.data;
        config.headers = {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        config.withCredentials = true;
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        return config;
    },
    async (error) => {
        console.log(error);
        return Promise.reject(error);
    }
);

export default axiosInstance;
