import React, {
    useEffect,
    useState,
    useRef,
    useMemo,
    useContext,
    useCallback,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import AddResource from './AddResource';
import Help from '../UI/Admin/Help';
import {
    getAllResources,
    deleteResource,
    reorderResource,
} from '../../lib/resources';
import AuthContext from '../../store/AuthContext';
import UpdateResource from './UpdateResource';
import { dateFilterParams } from '../../lib/agGrid';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './ag-grid.css';

import styles from './ResourcesTable.module.css';

const linkCellRenderer = (props) => (
    <a href={props.data.videoUrl} target="_blank" rel="noreferrer">
        {props.data.videoUrl}
    </a>
);

const ResourcesTable = (props) => {
    const [rowData, setRowData] = useState([]);
    const [isAddResource, setIsAddResource] = useState(false);
    const [isUpdateResource, setIsUpdateResource] = useState(false);
    const [doubleClickedResource, setDoubleClickedResource] = useState({});
    const [resourceDataChanged, setResourceDataChanged] = useState(true);
    const [gridApi, setGridApi] = useState(null);

    const resizeColumnsToFit = () => {
        gridRef.current.api.sizeColumnsToFit();
    };

    useEffect(() => {
        window.addEventListener('resize', resizeColumnsToFit);
        return () => {
            window.removeEventListener('resize', resizeColumnsToFit);
        };
    }, []);

    useEffect(() => {
        if (gridApi) {
            gridRef.current.api.showLoadingOverlay();
        }
        if (resourceDataChanged) {
            const getResources = async () => {
                const data = await getAllResources();
                console.log('data', data);
                if (data.code) {
                    console.log(data);
                    return;
                }
                setRowData(data);
            };
            getResources();
            setResourceDataChanged(false);
        }
    }, [resourceDataChanged, gridApi]);

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '95%', height: '92%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const defaultColumnDefs = {
        resizable: true,
        floatingFilter: true,
    };

    const columnDefs = useMemo(
        () => [
            {
                field: 'name',
                headerName: '영상 이름',
                sortable: true,
                filter: 'agTextColumnFilter',
                suppressSizeToFit: true,
                pinned: 'left',
                width: 200,
                rowDrag: true,
                resizable: true,
            },
            {
                field: 'videoUrl',
                headerName: '영상 주소',
                filter: 'agTextColumnFilter',
                editable: true,
                cellRenderer: linkCellRenderer,
            },
            {
                field: 'creator',
                headerName: '만든이',
                sortable: true,
                filter: 'agTextColumnFilter',
                suppressSizeToFit: true,
                width: 130,
            },
            {
                field: 'createdAt',
                headerName: '생성 날짜',
                sortable: true,
                filter: 'agDateColumnFilter',
                filterParams: dateFilterParams,
                width: 160,
                suppressSizeToFit: true,
            },
            {
                field: 'updatedAt',
                headerName: '최근 수정 날짜',
                sortable: true,
                filter: 'agDateColumnFilter',
                filterParams: dateFilterParams,
                width: 160,
                suppressSizeToFit: true,
            },
        ],
        []
    );

    const resourceDataChangedHandler = () => {
        setResourceDataChanged(true);
    };

    const onGridReady = (params) => {
        console.log('grid ready');
        setGridApi(params.api);
        resizeColumnsToFit();
    };

    const deleteRowHandler = async () => {
        const selectedResource = gridRef.current.api.getSelectedRows()[0];
        if (!selectedResource) {
            alert('삭제할 행을 선택해주세요.');
        } else {
            console.log(selectedResource);
            const proceed = confirm('선택한 영상을 삭제하시겠습니까?');
            if (proceed) {
                const res = await deleteResource(selectedResource.id);
                if (res.code === 404) {
                    alert(
                        '해당 영상 자료를 찾을 수 없습니다. 새로고침 후 다시 시도해보세요.'
                    );
                    return;
                } else if (res.code === 403) {
                    alert('삭제할 권한이 없습니다.');
                    return;
                }
                console.log(res);
                resourceDataChangedHandler(true);
                props.alert('삭제되었습니다.');
            }
        }
    };

    const authCtx = useContext(AuthContext);

    const rowDoubleClickHandler = (event) => {
        console.log('double clicked');
        console.log(event.data);
        console.log(authCtx.user);
        if (event.data.creator !== authCtx.user.affiliation) {
            alert('수정 권한이 없습니다.');
            return;
        }
        setDoubleClickedResource(event.data);
        setIsUpdateResource(true);
    };

    const getRowId = useMemo(() => {
        return (params) => params.data.id;
    }, []);

    const updateResourceModalHandler = () => {
        setIsUpdateResource((prevState) => {
            return !prevState;
        });
    };

    const addResourceModalHandler = () => {
        setIsAddResource((prevState) => {
            return !prevState;
        });
    };

    const onRowDragEnd = useCallback((e) => {
        console.log('onRowDragEnd', e);
        const draggedItem = e.node.data.id;
        const behind = e.overNode.data.id;

        reorderResource(draggedItem, behind);
    }, []);

    return (
        <div style={containerStyle}>
            {/* ------Modals------ */}
            {isAddResource && (
                <AddResource
                    onClose={addResourceModalHandler}
                    onAdd={resourceDataChangedHandler}
                />
            )}
            {isUpdateResource && (
                <UpdateResource
                    resource={doubleClickedResource}
                    onClose={updateResourceModalHandler}
                    onUpdate={resourceDataChangedHandler}
                />
            )}
            {/* ------Modals End Here------ */}
            <div className={styles.bar}>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Help
                        header={'도움말'}
                        size={'80%'}
                        width={'20rem'}
                        position={'right bottom'}
                        isRelative={true}
                        top={'0.1rem'}
                        right={'0rem'}
                        display={'inline-block'}
                        style={{ verticalAlign: 'middle' }}
                    >
                        <ul>
                            <li>
                                ctrl 키를 누르면 선택한 열을 취소하려면 취소할
                                수 있습니다. (Mac 의 경우 cmd 키)
                            </li>
                            <li>
                                더블 클릭하여 자료 정보를 수정할 수 있습니다.
                            </li>
                        </ul>
                    </Help>
                    <div>
                        <p style={{ marginLeft: '0.2rem' }}>
                            Rows: {rowData.length}
                        </p>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button onClick={addResourceModalHandler}>
                        영상 자료 추가
                    </button>
                    <button onClick={deleteRowHandler}>영상 삭제</button>
                </div>
            </div>
            <div className="ag-theme-alpine" style={gridStyle}>
                <AgGridReact
                    ref={gridRef}
                    // domLayout={'autoHeight'}
                    animateRows={true}
                    rowData={rowData}
                    defaultColDef={defaultColumnDefs}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    localeText={{ noRowsToShow: '조회 결과가 없습니다.' }}
                    overlayLoadingTemplate={
                        '<span class="ag-overlay-loading-center">데이터 가져오는 중...</span>'
                    }
                    onRowDoubleClicked={rowDoubleClickHandler}
                    getRowId={getRowId}
                    rowSelection={'single'}
                    rowMultiSelectWithClick={true}
                    rowDragManaged={true}
                    suppressMoveWhenRowDragging={true}
                    onRowDragEnd={onRowDragEnd}
                ></AgGridReact>
            </div>
        </div>
    );
};

export default ResourcesTable;
