import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import React, { memo, useEffect, useMemo, useState } from 'react';
import styles from './CalendarChart.module.css';
import './calendar-heatmap.css';
import ReactTooltip from 'react-tooltip';
import ChartTitle from './ChartTitle';
import { calendarHeatmapData } from '../../lib/requestChartData';
import calendarHeatmapInitialData from '../../lib/calendarHeatmapInitialData';

const CalendarChart = (props) => {
    const initialData = useMemo(() => {
        return calendarHeatmapInitialData();
    }, []);
    console.log(initialData);
    const [chartData, setChartData] = useState(initialData);

    useEffect(() => {
        const getCalendarHeatmapData = async () => {
            const res = await calendarHeatmapData();
            if (res.code) {
                console.log(res);
            }
            setChartData(res);
        };
        getCalendarHeatmapData();
    }, []);

    return (
        <div style={{ width: '100%', height: '100%', maxHeight: '100%' }}>
            <ChartTitle title="전체출석률" />
            <div className={styles['chart-area']}>
                <div className={styles.calendar}>
                    <CalendarHeatmap
                        style={{ height: '100%', width: '100%' }}
                        startDate={
                            new Date(
                                props.currentYear,
                                props.currentMonth - 6,
                                0
                            )
                        }
                        endDate={
                            new Date(props.currentYear, props.currentMonth, 0)
                        }
                        horizontal={true}
                        showWeekdayLabels={true}
                        showMonthLabels={true}
                        weekdayLabels={[
                            'SUN',
                            'MON',
                            'TUE',
                            'WED',
                            'THU',
                            'FRI',
                            'SAT',
                        ]}
                        tooltipDataAttrs={(value) => {
                            return {
                                'data-tip': `${value.date} 출석인원: ${value.count}`,
                            };
                        }}
                        values={chartData}
                        classForValue={(value) => {
                            if (!value || value.count === 0) {
                                return 'color-empty';
                            }
                            if (value.count > 0 && value.count <= 10) {
                                return 'color-scale-1';
                            }
                            if (value.count > 10 && value.count <= 20) {
                                return 'color-scale-2';
                            }
                            if (value.count > 20 && value.count <= 30) {
                                return 'color-scale-3';
                            }
                            return 'color-scale-4';
                        }}
                    />
                    <ReactTooltip />
                </div>
            </div>
        </div>
    );
};

export default memo(CalendarChart);
