import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';
import axios from 'axios';
import './index.css';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer, { rootSaga } from './modules';
import { Provider } from 'react-redux';

/* Redux */
const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();

const enhancer =
    process.env.NODE_ENV === 'production'
        ? composeWithDevTools(applyMiddleware(sagaMiddleware))
        : composeWithDevTools(applyMiddleware(logger, sagaMiddleware));

export const store = createStore(rootReducer, enhancer);
sagaMiddleware.run(rootSaga);

/* Axios */
axios.defaults.baseURL = 'https://api.icaan.net/';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
);
