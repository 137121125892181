import React, { useEffect, useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';

import camelCaseToRegularText from '../../lib/toRegularText';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './ag-grid.css';
import { dateFilterParams } from '../../lib/agGrid';

const UsersTable = (props) => {
    const baseColumns = [
        {
            field: 'username',
            headerName: 'UserId',
            sortable: true,
            pinned: 'left',
            checkboxSelection: true,
            headerCheckboxSelection: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            filter: 'agTextColumnFilter',
            pinned: 'left',
            suppressSizeToFit: true,
            width: 150,
        },
        {
            field: 'dateOfBirth',
            headerName: 'Date of Birth',
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
        },
        { field: 'phoneNumber', headerName: 'Phone Number' },
        {
            field: 'createdAt',
            headerName: 'Added At',
            sortable: true,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
        },
        {
            field: 'updatedAt',
            headerName: 'Last Update',
            sortable: true,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
        },
    ];

    // prettier-ignore
    const containerStyle = useMemo(() => ({ width: '95%', height: '60%', marginBottom:'1rem' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [dynamicColumns, setDynamicColumns] = useState(baseColumns);

    useEffect(() => {
        let customColumnDefs;
        let columnDefs;
        if (props.columns) {
            customColumnDefs = props.columns.map((c) => {
                const columnDef = {
                    field: c.name,
                    headerName: camelCaseToRegularText(c.name),
                };
                if (c.isNumber === true) {
                    columnDef.sortable = true;
                    columnDef.filter = 'agNumberColumnFilter';
                } else {
                    columnDef.filter = 'agTextColumnFilter';
                }
                return columnDef;
            });

            columnDefs = baseColumns
                .slice(0, 4)
                .concat(customColumnDefs)
                .concat(baseColumns.slice(4, 6));
        } else {
            columnDefs = baseColumns;
        }

        setDynamicColumns(columnDefs);
    }, [props.columns]);

    const defaultColumnDefs = {
        resizable: true,
    };

    const rowClickHandler = (event) => {
        props.onSelectUser(event.data);
    };

    const rowDoubleClickHandler = (event) => {
        props.onDoubleClickUser(event.data);
    };

    const onGridReady = (params) => {
        console.log('grid ready');
        props.onGridReady(params.api);
    };

    const getRowId = useMemo(() => {
        return (params) => params.data.id;
    }, []);

    return (
        <div style={containerStyle}>
            <div className="ag-theme-alpine" style={gridStyle}>
                <AgGridReact
                    ref={props.gridRef}
                    animateRows={true}
                    rowData={props.rowData}
                    defaultColDef={defaultColumnDefs}
                    onGridReady={onGridReady}
                    columnDefs={dynamicColumns}
                    rowSelection={'multiple'}
                    suppressRowClickSelection={true}
                    localeText={{ noRowsToShow: '조회 결과가 없습니다.' }}
                    overlayLoadingTemplate={
                        '<span class="ag-overlay-loading-center">데이터 가져오는 중...</span>'
                    }
                    onRowClicked={rowClickHandler}
                    onRowDoubleClicked={rowDoubleClickHandler}
                    getRowId={getRowId}
                    suppressColumnVirtualisation={true}
                ></AgGridReact>
            </div>
        </div>
    );
};

export default UsersTable;
