import React, { useRef, useState } from 'react';
import Modal from '../UI/Admin/Modal';
import styles from './UpdateColumns.module.css';
import FormInputArea from '../UI/Admin/FormInputArea';
import FormLabel from '../UI/Admin/FormLabel';
import FormInput from '../UI/Admin/FormInput';
import { addResource } from '../../lib/resources';
import CloseIcon from '../../assets/close.svg';
import HelpIcon from '../../assets/help.svg';
import Spinner from '../UI/Admin/Spinner';

const AddResource = (props) => {
    const nameInputRef = useRef();
    const urlInputRef = useRef();
    const [loading, setLoading] = useState(false);

    const submitFormHandler = async (event) => {
        event.preventDefault();

        console.log(urlInputRef.current.value.indexOf('?'));

        if (
            !(
                urlInputRef.current.value.includes('youtu.be') &&
                urlInputRef.current.value.split('/').length === 4
            )
        ) {
            alert(
                "유튜브 영상 하단의 '공유하기' 버튼을 통해 복사한 url 을 사용해주세요."
            );
            urlInputRef.current.value = '';
            return;
        }

        setLoading(true);
        const res = await addResource(
            nameInputRef.current.value,
            urlInputRef.current.value
        );
        if (res.code === 409) {
            alert(
                '동일한 url 의 영상 자료가 이미 존재합니다. 기존의 영상 자료를 수정하거나 삭제하세요.'
            );
            setLoading(false);
            return;
        } else if (res.code === 500) {
            alert('알 수 없는 오류가 발생했습니다. 나중에 재시도해주세요.');
            setLoading(false);
            props.onClose();
            return;
        }
        props.onAdd();
        setLoading(false);
        alert('새로운 영상 자료가 추가되었습니다.');
        nameInputRef.current.value = '';
        urlInputRef.current.value = '';
    };

    return (
        <Modal onClose={props.onClose}>
            <div className="adminModalHeader">
                <div>영상 자료 추가</div>
                <div onClick={props.onClose} className="adminModalCloseBtn">
                    <img src={CloseIcon} alt="" />
                </div>
            </div>
            <div className="adminModalContent">
                {loading && <Spinner />}
                <div className="adminModalInfo">
                    <img src={HelpIcon} alt="" />
                    도움말
                </div>
                <ul>
                    <li>
                        <span className="emp1">새로운 영상 자료</span>를{' '}
                        <span className="emp1">추가</span>합니다.
                    </li>
                </ul>
                <div className="adminModalFormWrapper">
                    <form onSubmit={submitFormHandler}>
                        <FormInputArea className={styles['input-area']}>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="name"
                                    label="영상 이름"
                                    className="adminInputLabel"
                                >
                                    영상 이름
                                </FormLabel>
                                <FormInput
                                    type="text"
                                    name="name"
                                    ref={nameInputRef}
                                    required={true}
                                    className="adminInputText"
                                />
                            </div>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="videoUrl"
                                    label="영상 주소"
                                    className="adminInputLabel"
                                >
                                    영상 주소
                                </FormLabel>
                                <FormInput
                                    type="text"
                                    name="videoUrl"
                                    ref={urlInputRef}
                                    required={true}
                                    className="adminInputText"
                                />
                            </div>
                        </FormInputArea>
                        <button type="submit" className="adminBottomBtn">
                            추가
                        </button>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default AddResource;
