import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteNotice, updateNotice } from '../../lib/notices';
import UpdateNoticeReservation from '../../components/Admin/UpdateNoticeReservation';
import AuthContext from '../../store/AuthContext';
import { formatTimezoneWithTime } from '../../lib/formatTimezone';
import Spinner from '../../components/UI/Admin/Spinner';
import reservationIcon from '../../assets/reservation.svg';

import styled from 'styled-components';

const AdminNoticeDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [initialStatus, setInitialStatus] = useState({
        fixTop: location.state.fixTop,
        isVisible: location.state.isVisible,
    });
    const [notice, setNotice] = useState(location.state);
    const [inputObj, setInputObj] = useState({
        title: location.state.title,
        content: location.state.content,
        redirectUrl: location.state.redirectUrl,
        fixTop: location.state.fixTop,
        isVisible: location.state.isVisible,
    });
    const [isUpdateReservation, setIsUpdateReservation] = useState(false);
    const [isUpdateNotice, setIsUpdateNotice] = useState(false);
    const initData = useRef(notice);
    const authCtx = useContext(AuthContext);
    const [isAuthor, setIsAuthor] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log(authCtx.user.affiliation);
        if (notice !== null) {
            setIsAuthor(notice.author === authCtx.user.affiliation);
        }
    }, [notice]);

    const inputChangeHandler = (event, param) => {
        let { value, name } = event.target;

        if (name === 'fixTop' || name === 'isVisible') {
            value = document.getElementById(name).checked;
        }

        if (name === 'fixTop' && document.getElementById(name).checked) {
            if (
                !confirm(
                    '다른 공지사항이 이미 상단고정 되어있는 경우, 기존의 상단고정이 해제되며 이 공지사항이 상단고정됩니다. 진행하시겠습니까?'
                )
            )
                return;
        }

        setInputObj({
            ...inputObj,
            [name]: value,
        });
    };

    const saveFormHandler = async (event) => {
        event.preventDefault();
        console.log(inputObj);
        setLoading(true);
        const res = await updateNotice(notice.id, inputObj);
        if (res.code) {
            console.log(res);
            if (res.code === 404) {
                alert(
                    '해당 공지사항을 찾을 수 없습니다. 새로고침 후 재시도 해주세요.'
                );
                setLoading(false);
                return;
            }
        }
        res.author = res.author.affiliation;
        setNotice(res);
        initData.current = {
            title: res.title,
            content: res.content,
            redirectUrl: res.redirectUrl,
            fixTop: res.fixTop,
            isVisible: res.isVisible,
        };
        setLoading(false);
        alert('수정되었습니다.');
        setIsUpdateNotice(false);
        setInitialStatus({
            fixTop: inputObj.fixTop,
            isVisible: inputObj.isVisible,
        });
    };

    const deleteNoticeHandler = async () => {
        const proceed = confirm(
            '공지사항을 완전히 삭제하시겠습니까? (단순히 유저에게 보이지 않게 하고 싶은 경우, 공지사항의 게시 여부의 체크박스를 해제해주세요.)'
        );
        if (proceed) {
            const res = await deleteNotice(notice.id);
            if (res.code) {
                if (res.code === 404) {
                    alert(
                        '해당 공지사항을 찾을 수 없습니다. 새로고침 후 재시도 해주세요.'
                    );
                } else if (res.code === 403) {
                    alert('삭제 권한이 없습니다.');
                }
            }
            alert('삭제되었습니다.');
            navigate(-1);
        }
    };

    const updateReservationHandler = () => {
        setIsUpdateReservation((prevState) => {
            return !prevState;
        });
    };

    const updateNoticeHandler = () => {
        console.log('수정');
        if (!isAuthor) {
            alert('수정 권한이 없습니다.');
            return;
        }
        setIsUpdateNotice((prevState) => {
            return !prevState;
        });
    };

    const cancelUpdateHandler = () => {
        console.log('cancel');
        setIsUpdateNotice(false);
        setInputObj(initData);
    };

    return (
        <NoticeDetailContainer>
            {loading && <Spinner />}
            {notice.reservedDatetime !== null &&
                new Date(notice.reservedDatetime) > Date.now() && (
                    <NoticeReservationWrapper>
                        {/* ****************** 에약 공지사항인 경우 ****************** */}
                        {isUpdateReservation && (
                            <UpdateNoticeReservation
                                notice={notice}
                                onClose={updateReservationHandler}
                                onSave={setNotice}
                            />
                        )}
                        {new Date(notice.reservedDatetime) > Date.now() && (
                            <ReservationInfo>
                                <ReservationTitle>
                                    <img src={reservationIcon} alt="" />
                                    예약된 공지사항
                                </ReservationTitle>
                                <ReservationContent>
                                    <div>
                                        <b>
                                            {formatTimezoneWithTime(
                                                notice.reservedDatetime
                                            )}
                                        </b>{' '}
                                        에 공지사항이 게시될 예정입니다.
                                        <br />
                                        문자 전송이{' '}
                                        <b>
                                            {notice.sendSMS
                                                ? '활성화'
                                                : '비활성화'}
                                        </b>{' '}
                                        되어있습니다.
                                    </div>
                                    <div>
                                        {isAuthor && (
                                            <button
                                                type="button"
                                                onClick={
                                                    updateReservationHandler
                                                }
                                            >
                                                예약 수정
                                            </button>
                                        )}
                                    </div>
                                </ReservationContent>
                            </ReservationInfo>
                        )}
                    </NoticeReservationWrapper>
                )}
            {/* *************************************************** */}
            <NoticeDetailWrapper>
                <form id="notice-form" onSubmit={saveFormHandler}>
                    <NoticeStatusWrapper>
                        <NoticeStatusCurrent>
                            {initialStatus.fixTop ? (
                                <>
                                    <Emp>상단고정</Emp> 및{' '}
                                </>
                            ) : null}
                            {initialStatus.isVisible ? (
                                <>
                                    <Emp>게시중</Emp>인
                                </>
                            ) : (
                                <>
                                    <EmpNot>비게시</EmpNot>중인
                                </>
                            )}{' '}
                            공지사항입니다
                        </NoticeStatusCurrent>
                        <NoticeStatusCheckbox>
                            <div>
                                <input
                                    name="fixTop"
                                    type="checkbox"
                                    id="fixTop"
                                    onChange={inputChangeHandler}
                                    checked={inputObj.fixTop}
                                    value={inputObj.fixTop}
                                    disabled={!isUpdateNotice}
                                />
                                <label htmlFor="fixTop">상단 고정</label>
                            </div>
                            <div>
                                <input
                                    name="isVisible"
                                    type="checkbox"
                                    id="isVisible"
                                    onChange={inputChangeHandler}
                                    value={inputObj.isVisible}
                                    checked={inputObj.isVisible}
                                    disabled={!isUpdateNotice}
                                />
                                <label htmlFor="isVisible">게시 여부</label>
                            </div>
                        </NoticeStatusCheckbox>
                    </NoticeStatusWrapper>
                    <NoticeContentWrapper>
                        <div>
                            <label>제목</label>
                            <input
                                name="title"
                                type="text"
                                id="title"
                                onChange={inputChangeHandler}
                                value={inputObj.title}
                                disabled={!isUpdateNotice}
                            />
                        </div>
                        <div>
                            <label>내용</label>
                            <textarea
                                name="content"
                                id="content"
                                onChange={inputChangeHandler}
                                value={inputObj.content}
                                disabled={!isUpdateNotice}
                            />
                        </div>
                        <div>
                            <label>첨부할 링크</label>
                            <input
                                name="redirectUrl"
                                type="text"
                                id="redirectUrl"
                                onChange={inputChangeHandler}
                                value={
                                    inputObj.redirectUrl
                                        ? inputObj.redirectUrl
                                        : ''
                                }
                                disabled={!isUpdateNotice}
                            />
                        </div>
                    </NoticeContentWrapper>
                    <NoticeButtonWrapper>
                        {!isUpdateNotice && isAuthor && (
                            <div>
                                <button
                                    type="button"
                                    onClick={updateNoticeHandler}
                                >
                                    수정
                                </button>
                                <button
                                    type="button"
                                    onClick={deleteNoticeHandler}
                                >
                                    삭제
                                </button>
                            </div>
                        )}
                        {isUpdateNotice && isAuthor && (
                            <div>
                                <button type="submit">저장</button>
                                <button
                                    onClick={cancelUpdateHandler}
                                    type="button"
                                >
                                    취소
                                </button>
                            </div>
                        )}
                    </NoticeButtonWrapper>
                </form>
            </NoticeDetailWrapper>
        </NoticeDetailContainer>
    );
};

const NoticeDetailContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;

const NoticeReservationWrapper = styled.div`
    width: 80%;
    max-width: 50rem;
    background-color: #f8f8f9;
    border: 1.5px solid rgba(118, 171, 255);
    padding: 0.6rem 0.8rem;
    font-size: 0.9rem;
    color: #007aff;
`;

const ReservationInfo = styled.div``;

const ReservationTitle = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 0.1rem;
    gap: 0.07rem;

    & {
        img {
            position: relative;
            top: -0.06rem;
        }
    }
`;

const ReservationContent = styled.div`
    padding: 0 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 0.8rem;
    line-height: 1.05rem;
    color: #515151;

    & {
        b {
            color: rgb(12, 19, 28);
        }
    }
`;

const NoticeDetailWrapper = styled.div`
    height: fit-content;
    margin-bottom: 1rem;
    width: 80%;
    max-width: 50rem;
    border: 1px solid #babfc7;
`;

const NoticeStatusWrapper = styled.div`
    background: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #babfc7;
    padding-top: 1.15rem;
`;

const NoticeStatusCurrent = styled.div``;

const NoticeStatusCheckbox = styled.div`
    display: flex;
    gap: 0.4rem;

    & {
        div {
            display: flex;
            align-items: center;
        }

        label {
            font-size: 0.8rem;
            margin-left: 0.1rem;
        }
    }
`;

const NoticeContentWrapper = styled.div`
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #f8f8f9;

    & {
        div {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            font-size: 0.8rem;

            & {
                label {
                    margin-left: 0.1rem;
                }

                input {
                    width: 50%;
                    padding: 0.3rem;
                }

                textarea {
                    height: 20rem;
                    padding: 0.5rem;
                    resize: none;
                }

                input,
                textarea {
                    border: 1px solid #babfc7;
                    background: rgba(118, 171, 255, 0.1);
                }

                input:focus,
                textarea:focus {
                    outline: none;
                }

                input:disabled,
                textarea:disabled {
                    background-color: #ffffff;
                }
            }
        }
    }
`;

const NoticeButtonWrapper = styled.div`
    text-align: right;
    padding: 0.8rem;
    padding-top: 0;
    background-color: #f8f8f9;

    & {
        button {
            margin: 0 0.2rem;
        }
    }
`;

const Emp = styled.span`
    color: #007aff;
    font-weight: 500;
`;

const EmpNot = styled.span`
    color: rgb(220, 0, 0);
    font-weight: 500;
`;

export default AdminNoticeDetail;
