import BeatLoader from 'react-spinners/BeatLoader';
import styles from './Spinner.module.css';

const Spinner = () => {
    return (
        <div className={styles['spinner-backdrop']}>
            <BeatLoader />
        </div>
    );
};

export default Spinner;
