import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { UserShadows } from '../../../constants/styles';
import UserColors from '../../../constants/colors';
// import HomeIcon from '../../../assets/tab-home.svg';
// import MessageIcon from '../../../assets/tab-message.svg';
// import NoticeIcon from '../../../assets/tab-notice.svg';

const TabBar = ({ path }) => {
    const menuItems = [
        {
            name: '운동하기',
            path: '/home',
        },
        {
            name: '메시지',
            path: '/home/message',
        },
        {
            name: '공지사항',
            path: '/home/notice',
        },
    ];
    const svgIcons = {
        HomeIcon: (
            <svg
                width="20"
                height="25"
                viewBox="0 0 20 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13.0769 4.68768C14.3514 4.68768 15.3846 3.63833 15.3846 2.34384C15.3846 1.04936 14.3514 0 13.0769 0C11.8024 0 10.7692 1.04936 10.7692 2.34384C10.7692 3.63833 11.8024 4.68768 13.0769 4.68768ZM5.46587 15.5021L4.75433 17.1877H1.53846C0.688942 17.1877 0 17.8874 0 18.7502C0 19.6131 0.688942 20.3128 1.53846 20.3128H5.26202C6.1875 20.3128 7.02067 19.7542 7.38269 18.8923L7.80529 17.8903L7.29231 17.5827C6.45962 17.0832 5.84712 16.3439 5.46587 15.5021ZM18.4615 10.9374H16.3447L15.0918 8.33724C14.4909 7.08963 13.3875 6.17749 12.1216 5.84984L8.70433 4.81757C7.34375 4.48553 5.92692 4.79071 4.81779 5.65452L2.91058 7.13944C2.23606 7.66436 2.10817 8.64487 2.62596 9.32996C3.14375 10.015 4.10865 10.1435 4.78269 9.61903L6.69087 8.13411C7.05962 7.8465 7.52933 7.74347 7.90577 7.83429L8.6125 8.04768L6.81154 12.3149C6.20481 13.7544 6.74856 15.4405 8.07596 16.2365L12.1615 18.6863L10.8409 22.9701C10.587 23.7934 11.0385 24.6699 11.849 24.9277C12.0024 24.9766 12.1572 25 12.3096 25C12.9639 25 13.5707 24.5718 13.7769 23.9038L15.2981 18.969C15.5822 17.9548 15.1591 16.8654 14.2577 16.3131L11.3135 14.5484L12.8188 10.726L13.7933 12.749C14.1779 13.5469 14.9913 14.0621 15.8659 14.0621H18.4615C19.3111 14.0621 20 13.3623 20 12.4995C20 11.6367 19.3111 10.9374 18.4615 10.9374Z"
                    fill="#B6B6B6"
                />
            </svg>
        ),
        MessageIcon: (
            <svg
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2 1.45455V13.0909C2 13.4767 2.15804 13.8466 2.43934 14.1194C2.72064 14.3922 3.10217 14.5455 3.5 14.5455H18.5C18.8978 14.5455 19.2794 14.3922 19.5607 14.1194C19.842 13.8466 20 13.4767 20 13.0909V1.45455H2ZM2 0H20C20.3978 0 20.7794 0.153246 21.0607 0.426026C21.342 0.698807 21.5 1.06878 21.5 1.45455V13.0909C21.5 13.8624 21.1839 14.6024 20.6213 15.1479C20.0587 15.6935 19.2956 16 18.5 16H3.5C2.70435 16 1.94129 15.6935 1.37868 15.1479C0.816071 14.6024 0.5 13.8624 0.5 13.0909V1.45455C0.5 1.06878 0.658035 0.698807 0.93934 0.426026C1.22064 0.153246 1.60218 0 2 0Z"
                    fill="#B6B6B6"
                />
                <path
                    d="M20.1875 1.45455L14.387 7.88364C13.9646 8.35184 13.4439 8.72707 12.8598 8.98416C12.2756 9.24125 11.6415 9.37425 11 9.37425C10.3585 9.37425 9.7244 9.24125 9.14025 8.98416C8.55609 8.72707 8.03537 8.35184 7.613 7.88364L1.8125 1.45455H20.1875ZM3.806 1.45455L8.741 6.92509C9.02258 7.23731 9.36976 7.48753 9.75924 7.65897C10.1487 7.83041 10.5715 7.91911 10.9992 7.91911C11.427 7.91911 11.8498 7.83041 12.2393 7.65897C12.6287 7.48753 12.9759 7.23731 13.2575 6.92509L18.194 1.45455H3.806Z"
                    fill="#B6B6B6"
                />
            </svg>
        ),
        NoticeIcon: (
            <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.25 1.91476C16.25 1.40693 16.4475 0.919906 16.7992 0.56082C17.1508 0.201733 17.6277 0 18.125 0C18.6223 0 19.0992 0.201733 19.4508 0.56082C19.8025 0.919906 20 1.40693 20 1.91476V15.9563C20 16.4641 19.8025 16.9512 19.4508 17.3103C19.0992 17.6693 18.6223 17.8711 18.125 17.8711C17.6277 17.8711 17.1508 17.6693 16.7992 17.3103C16.4475 16.9512 16.25 16.4641 16.25 15.9563V1.91476ZM15 2.83895C12.4163 4.05163 9.32625 4.72945 6.25 4.95284V12.9144C6.70405 12.9405 7.15749 12.9767 7.61 13.0229C10.1762 13.2833 12.6575 13.8756 15 15.0143V2.83895ZM5 12.848V5.02177C4.15 5.05624 3.25125 5.07666 2.49 5.08943C1.82792 5.0988 1.19595 5.37337 0.730204 5.854C0.264462 6.33463 0.00223098 6.98286 0 7.65903L0 10.212C0 11.6239 1.12 12.7599 2.4925 12.7765C2.69918 12.7792 2.90585 12.7826 3.1125 12.7868C3.74188 12.7996 4.37109 12.82 5 12.848ZM6.7375 14.2279C7.09375 14.2547 7.44875 14.2879 7.8 14.3275L8.11625 16.4848C8.15066 16.6694 8.14474 16.8595 8.09889 17.0415C8.05304 17.2235 7.96841 17.3929 7.85103 17.5377C7.73365 17.6824 7.58643 17.7989 7.41987 17.8788C7.25331 17.9587 7.07153 18.0001 6.8875 18H6.2025C5.95953 18 5.72183 17.9276 5.51847 17.7919C5.31512 17.6561 5.15493 17.4627 5.0575 17.2354L3.415 14.0696C4.12031 14.0876 4.82537 14.1148 5.53 14.1513C5.93875 14.173 6.3425 14.1986 6.7375 14.2279Z"
                    fill="#B6B6B6"
                />
            </svg>
        ),
    };

    return (
        <TabBarWrapper>
            {menuItems.map((item, i) => (
                <TabBarMenu key={item.name} path={path}>
                    {/* prettier-ignore */}
                    <NavLink to={item.path} key={item.name}>
                        {i === 0 ? (svgIcons.HomeIcon) : null}
                        {i === 1 ? (svgIcons.MessageIcon) : null}
                        {i === 2 ? (svgIcons.NoticeIcon) : null}
                        <br />
                        {item.name}
                    </NavLink>
                </TabBarMenu>
            ))}
        </TabBarWrapper>
    );
};

const TabBarWrapper = styled.div`
    height: 6.5rem;
    display: flex;
    ${UserShadows.dropShadow07}
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: ${UserColors.white01};
    z-index: 999;

    @media screen and (min-width: 600px) {
        width: 35.2rem;
    }
`;

const TabBarMenu = styled.div`
    flex-grow: 1;
    text-align: center;
    color: ${UserColors.black02};
    display: flex;
    flex-direction: column;
    padding-bottom: 1.1rem;

    & {
        a {
            font-size: 1.1rem;
        }
    }

    &:first-child {
        svg {
            margin-bottom: 0.1rem;
        }
        svg > path {
            fill: ${(props) =>
                props.path === '/home' ? `${UserColors.blue01}` : ''};
        }
        color: ${(props) =>
            props.path === '/home' ? `${UserColors.blue01}` : ''};
    }

    &:nth-child(2) {
        svg {
            margin-bottom: 0.5rem;
        }
        svg > path {
            fill: ${(props) =>
                props.path === '/home/message' ? `${UserColors.blue01}` : ''};
        }
        color: ${(props) =>
            props.path === '/home/message' ? `${UserColors.blue01}` : ''};
    }

    &:last-child {
        svg {
            margin-bottom: 0.6rem;
        }
        svg > path {
            fill: ${(props) =>
                props.path === '/home/notice' ? `${UserColors.blue01}` : ''};
        }
        color: ${(props) =>
            props.path === '/home/notice' ? `${UserColors.blue01}` : ''};
    }
`;

export default TabBar;
