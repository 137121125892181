import { useDispatch, useSelector } from 'react-redux';
import { setNotices } from '../modules/notices';

export default function useNotices() {
    const notices = useSelector(({ notices }) => notices);
    const dispatch = useDispatch();

    const onSetNotices = (notices) => dispatch(setNotices(notices));

    return { notices, onSetNotices };
}
