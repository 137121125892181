import { useEffect, useState, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import millisecondToMinute from '../../lib/millisecondToMinute';

import { getUserActivities } from '../../lib/activities';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './ag-grid.css';
import styles from './UserActivitiesTable.module.css';
import { dateFilterParams } from '../../lib/agGrid';

const UserActivitiesTable = (props) => {
    const defaultColumnDefs = {
        resizable: true,
    };
    const columnDefs = useMemo(
        () => [
            {
                field: 'viewDatetime',
                headerName: '시청 일시',
                sortable: true,
                filter: 'agDateColumnFilter',
                width: 250,
                suppressSizeToFit: true,
                filterParams: dateFilterParams,
            },
            {
                field: 'videoResource',
                headerName: '시청한 영상',
                sortable: false,
                filter: 'agTextColumnFilter',
            },
            {
                field: 'totalDuration',
                headerName: '시청 시간 (분)',
                sortable: true,
                filter: 'agNumberColumnFilter',
                width: 150,
                suppressSizeToFit: true,
                valueFormatter: (params) => {
                    return millisecondToMinute(params.value);
                },
            },
            {
                field: 'status',
                headerName: '시청 상태',
                sortable: true,
                filter: 'agTextColumnFilter',
                suppressSizeToFit: true,
                width: 150,
            },
        ],
        []
    );

    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);
    const containerStyle = useMemo(
        () => ({ width: '100%', height: '30%' }),
        []
    );
    const gridStyle = useMemo(
        () => ({ height: '95%', width: '95%', margin: 'auto' }),
        []
    );
    const [rowData, setRowData] = useState([]);

    const resizeColumnsToFit = () => {
        gridRef.current.api.sizeColumnsToFit();
    };

    useEffect(() => {
        window.addEventListener('resize', resizeColumnsToFit);
        return () => {
            window.removeEventListener('resize', resizeColumnsToFit);
        };
    }, []);

    useEffect(() => {
        console.log(props.user);
        const getActivities = async () => {
            const data = await getUserActivities(props.user.id);
            if (data.code) {
                console.log(data);
                return;
            }
            setRowData(data);
            if (data.length === 0 || !data) {
                gridRef.current.api.hideOverlay();
            }
        };

        if (props.user) {
            if (gridApi) {
                gridRef.current.api.showLoadingOverlay();
            }
            getActivities();
        }
    }, [props.user]);

    const onGridReady = (params) => {
        console.log('grid ready');
        setGridApi(params.api);
        resizeColumnsToFit();
    };

    const onRowClicked = (event) => {
        console.log(event.data);
    };

    return (
        <div style={containerStyle} className={styles['activities-table']}>
            <div className="ag-theme-alpine" style={gridStyle}>
                <AgGridReact
                    ref={gridRef}
                    animateRows={true}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColumnDefs}
                    onRowClicked={onRowClicked}
                    onGridReady={onGridReady}
                    overlayLoadingTemplate={
                        '<span class="ag-overlay-loading-center">데이터 가져오는 중...</span>'
                    }
                    localeText={{ noRowsToShow: '조회 결과가 없습니다.' }}
                ></AgGridReact>
            </div>
        </div>
    );
};

export default UserActivitiesTable;
