import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import AdminMain from './AdminMain';
import AdminUsers from './AdminUsers';
import AdminResources from './AdminResources';
import AdminActivities from './AdminActivities';
import AdminMessages from './AdminMessages';
import AdminNotices from './AdminNotices';
import AdminAddNotice from './AdminAddNotice';
import AdminLogin from './AdminLogin';
import AuthProvider from '../../store/AuthProvider';
import Sidebar from '../../components/Admin/Sidebar';
import Header from '../../components/Admin/Header';
import AdminNoticeDetail from './AdminNoticeDetail';

import styles from './AdminApp.module.css';

function AdminApp() {
    const titles = {
        '/admin/dashboard': '피험자 통계',
        '/admin/users': '피험자 관리',
        '/admin/resources': '영상 관리',
        '/admin/activities': '영상 시청 기록',
        '/admin/notices': '공지사항 관리',
        '/admin/messages': '메시지 관리',
    };
    const location = useLocation();
    const title = titles[location.pathname.split('/').slice(0, 3).join('/')];

    const checkIsLoggedIn = () => !!localStorage.getItem('currentUser');

    return (
        <div className={styles.container}>
            <AuthProvider>
                <Sidebar />
                <div
                    className={`${styles.wrapper} ${
                        location.pathname === '/admin/login' ? styles.login : ''
                    }`}
                >
                    {location.pathname !== '/admin/login' ? (
                        <Header>{title}</Header>
                    ) : null}
                    <div className={styles.contents}>
                        <Routes>
                            {checkIsLoggedIn() ? (
                                <>
                                    <Route
                                        path="/dashboard"
                                        element={<AdminMain />}
                                    />
                                    <Route
                                        path="/users"
                                        element={<AdminUsers />}
                                    />
                                    <Route
                                        path="/resources"
                                        element={<AdminResources />}
                                    />
                                    <Route
                                        path="/activities"
                                        element={<AdminActivities />}
                                    />
                                    <Route
                                        path="/notices"
                                        element={<AdminNotices />}
                                    />
                                    <Route
                                        path="/notices/new"
                                        element={<AdminAddNotice />}
                                    />
                                    <Route
                                        path="/notices/:noticeId"
                                        element={<AdminNoticeDetail />}
                                    />
                                    <Route
                                        path="/messages"
                                        element={<AdminMessages />}
                                    />
                                    <Route
                                        path="*"
                                        element={<Navigate to="dashboard" />}
                                    />
                                </>
                            ) : (
                                <Route path="/*" element={<AdminLogin />} />
                            )}
                        </Routes>
                    </div>
                </div>
            </AuthProvider>
        </div>
    );
}

export default AdminApp;
