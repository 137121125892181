import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import UserColors from '../../../constants/colors';

const LoadingSpinner = ({ isLoading, isNotice }) => {
    const [stopAnim, setStopAnim] = useState(false);

    useEffect(() => {
        if (!isLoading)
            setTimeout(() => {
                setStopAnim(true);
            }, 1000);
    }, [isLoading]);

    return (
        <LoadingWrapper
            isLoading={isLoading}
            stopAnim={stopAnim}
            isNotice={isNotice}
        >
            <LoadingRow>
                <Loading />
            </LoadingRow>
            <LoadingRow>
                <Loading />
                <Loading />
            </LoadingRow>
            <LoadingRow>
                <Loading />
                <Loading />
            </LoadingRow>
            <LoadingRow>
                <Loading />
                <Loading />
            </LoadingRow>
            <LoadingRow>
                <Loading />
            </LoadingRow>
        </LoadingWrapper>
    );
};

const LoadingWrapper = styled.div`
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: rotate 2s infinite;
    width: 40rem;
    position: absolute;
    top: ${(props) => (props.isNotice ? '49%' : '50%')};
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.8s ease;
    opacity: ${(props) => (props.isLoading ? 1 : 0)};
    display: ${(props) => (props.stopAnim ? 'none' : '')};
    z-index: 5;

    & {
        div > div {
            transition: 0.8s ease;
            background-color: ${(props) =>
                props.isLoading ? '' : `${UserColors.blue01}`};
        }
    }

    @keyframes rotate {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
`;

const LoadingRow = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    flex-grow: 0;

    &:first-child {
        top: 3px;
    }

    &:nth-child(2) {
        width: 13%;
        margin: 0 auto;
        top: -3px;
    }
    &:nth-child(3) {
        width: 19%;
        margin: 0 auto;
        top: -1px;
    }
    &:nth-child(4) {
        width: 13%;
        margin: 0 auto;
        top: 0px;
    }
    &:last-child {
        top: -6px;
    }
`;

const Loading = styled.div`
    width: 1.1rem;
    height: 1.1rem;
    background-color: ${UserColors.yellow01};
    border-radius: 50%;
    margin: 0 auto;
    animation: loading 1.5s infinite;

    &:first-child {
        animation-delay: 0.1s;
    }
    &:nth-child(2) {
        animation-delay: 0.3s;
    }
    &:nth-child(3) {
        animation-delay: 0.5s;
    }
    &:nth-child(4) {
        animation-delay: 0.7s;
    }
    &:nth-child(5) {
        animation-delay: 0.9s;
    }
    &:nth-child(6) {
        animation-delay: 1.1s;
    }
    &:nth-child(7) {
        animation-delay: 1.3s;
    }
    &:nth-child(8) {
        animation-delay: 1.5s;
        align-self: flex-end;
    }

    @keyframes loading {
        0% {
            opacity: 1;
            transform: scale(100%, 100%);
        }
        50% {
            opacity: 0.5;
            transform: scale(80%, 80%);
        }
        100% {
            opacity: 1;
            transform: scale(100%, 100%);
        }
    }
`;

export default LoadingSpinner;
