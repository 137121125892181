import { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import UserMain from './UserMain';
import UserNotices from './UserNotices';
import UserMessages from '../../components/User/UserMessages';
import UserLogin from './UserLogin';
import UserWatchPage from './UserWatchPage';
import SelectVideo from '../../components/User/SelectVideo';
import UserCompletePage from './UserCompletePage';
import UserLanding from './UserLanding';
import styled from 'styled-components';
import { UserShadows, UserGlobalStyle } from '../../constants/styles';
import Navbar from '../../components/UI/User/Navbar';
import TabBar from '../../components/UI/User/TabBar';
import UserNoticeView from '../../components/User/UserNoticeView';
import UserNotifications from './UserNotifications';
import useMessages from '../../hooks/useMessages';
import useNotices from '../../hooks/useNotices';
import { getMessagesByUserMod } from '../../lib/messages';
import axios from 'axios';
import { getAllNoticesByUser } from '../../lib/notices';

function UserApp() {
    const { onSetNotices } = useNotices();
    const { onSetMessages } = useMessages();
    const location = useLocation();
    const [navbar, setNavbar] = useState(true);
    const [tabBar, setTabBar] = useState(false);
    const [locationScope, setLocationScope] = useState('');
    const navbarOff = ['/login', '/complete'];
    // prettier-ignore
    const tabBarOff = ['/', '/login', '/video', '/complete', '/home/notice/view', '/notification'];
    const fullScreenOn = ['/home/message', '/home/notice', '/home/notice/view'];
    const [fullScreen, setFullScreen] = useState(false);

    const setGlobalMessages = async () => {
        const userId = localStorage.getItem('userId');
        const messages = await getMessagesByUserMod(userId);
        onSetMessages(messages);
    };

    const setGlobalNotices = async () => {
        const userId = localStorage.getItem('userId');
        const notices = await getAllNoticesByUser(userId);
        onSetNotices(notices);
    };

    const setAuth = () => {
        const accessToken = localStorage.getItem('accessToken');
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    };

    const checkIsLoggedIn = () => {
        return !!localStorage.getItem('accessToken');
    };

    useEffect(() => {
        /* If no accessToken, redirect to root */
        const accessToken = localStorage.getItem('accessToken');
        const userName = localStorage.getItem('userName');
        const userId = localStorage.getItem('userId');
        // prettier-ignore
        if (!(accessToken && userName && userId) && !(location.pathname === '/' || location.pathname === '/login')) {
            localStorage.clear();
            window.location.replace('/');
        }
        /* Different ui depends on pathname */
        const locationScope = location.pathname
            .split('/')
            .slice(0, 4)
            .join('/');
        if (navbarOff.includes(locationScope)) setNavbar(false);
        else setNavbar(true);
        if (tabBarOff.includes(locationScope)) setTabBar(false);
        else setTabBar(true);
        if (fullScreenOn.includes(locationScope)) setFullScreen(true);
        else setFullScreen(false);
        /* 라우팅 시 항상 최상단으로 스크롤 */
        window.scrollTo(0, 0);
        setLocationScope(locationScope);

        /* Set global messages & notices */
        if (checkIsLoggedIn()) {
            setAuth();
            setGlobalMessages();
            setGlobalNotices();
        }
    }, [location]);

    return (
        <>
            <UserGlobalStyle />
            <UserContainer>
                {navbar ? <Navbar path={locationScope} /> : null}
                <UserMainWrapper navbar={navbar}>
                    <Routes>
                        <Route path="/" element={<UserLanding />} />
                        <Route path="login" element={<UserLogin />} />
                        <Route
                            path="home"
                            element={<UserMain fullScreen={fullScreen} />}
                        >
                            <Route index element={<SelectVideo />} />
                            <Route path="message" element={<UserMessages />} />
                            <Route path="notice" element={<UserNotices />} />
                            <Route
                                path="notice/view/:id"
                                element={<UserNoticeView />}
                            />
                        </Route>
                        <Route
                            path="notification"
                            element={<UserNotifications />}
                        />
                        <Route path="video" element={<UserWatchPage />} />
                        <Route path="complete" element={<UserCompletePage />} />
                    </Routes>
                </UserMainWrapper>
                {tabBar ? <TabBar path={locationScope} /> : null}
            </UserContainer>
        </>
    );
}

const UserContainer = styled.div`
    min-height: 100vh;
    width: 100vw;
    min-width: 35rem;
    margin: 0 auto;
    overflow: hidden;
    // 스크롤바 없앰
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    @media screen and (min-width: 600px) {
        width: 35.2rem;
        ${UserShadows.dropShadow01};
    }
`;

const UserMainWrapper = styled.div`
    // Navbar 높이 확보
    padding-top: ${(props) => (props.navbar ? '4.4rem' : '')};
`;

export default UserApp;
