import UserNoticeItem from './UserNoticeItem';
import styled from 'styled-components';

function UserNotice({ notices, noticeRead }) {
    return (
        <>
            {/* 상단고정 공지사항 */}
            <FixTopContainer>
                {!!notices.length &&
                    notices
                        .filter((v) => v.fixTop)
                        .map((item, index) => {
                            return (
                                <UserNoticeItem
                                    notice={item}
                                    key={index}
                                    isFixTop={'true'}
                                    isNotRead={!noticeRead.includes(item.id)}
                                />
                            );
                        })}
            </FixTopContainer>
            {/* 안읽은 공지사항 */}
            {!!notices.length &&
                notices
                    .filter((v) => !v.fixTop && !noticeRead.includes(v.id))
                    .reverse()
                    .map((item, index) => {
                        return (
                            <UserNoticeItem
                                notice={item}
                                key={index}
                                isNotRead={true}
                            />
                        );
                    })}
            {/* 읽은 공지사항 */}
            {!!notices.length &&
                notices
                    .filter((v) => !v.fixTop && noticeRead.includes(v.id))
                    .reverse()
                    .map((item, index) => {
                        return (
                            <UserNoticeItem
                                notice={item}
                                key={index}
                                isNotRead={false}
                            />
                        );
                    })}
        </>
    );
}

const FixTopContainer = styled.div`
    margin-bottom: 1.6rem;
`;

export default UserNotice;
