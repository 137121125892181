const autoSizeAll = (gridRef) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getId());
    });
    console.log(allColumnIds);
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
};

const sizeToFit = (gridRef) => {
    gridRef.current.api.sizeColumnsToFit();
};

const dateFilterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        const dateParts = dateAsString.split(' ');
        const year = Number(dateParts[0].substring(0, 4));
        const month =
            Number(dateParts[1].substring(0, dateParts[1].length - 1)) - 1;
        const day = Number(dateParts[2].substring(0, dateParts[2].length - 1));
        const cellDate = new Date(year, month, day);
        console.log(cellDate);

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
    },
    browserDatePicker: true,
};

export { autoSizeAll, sizeToFit, dateFilterParams };
