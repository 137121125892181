import UserColors from './colors';
import { createGlobalStyle } from 'styled-components';

export class UserShadows {
    static dropShadow01 =
        'box-shadow: 0px 1px 10px -1px rgba(62, 67, 84, 0.4);';

    static dropShadow07 =
        'box-shadow: 0px 2.31176px 6.35735px -1.73382px rgba(24, 39, 75, 0.12), 0px 4.04559px 18.4941px -1.15588px rgba(24, 39, 75, 0.12);';
}

export class UserFonts {
    static Navbar = `font-size: 1.1rem; color: ${UserColors.blue01}`;
    static Tag = `font-size: 1rem; line-height: 1.3rem; color: ${UserColors.blue01}`;
    static TabBarDefault = `font-size: 1.1rem; line-height: 1.7rem; color: ${UserColors.black02}`;
    static TabBarSelected = `font-size: 1.2rem; line-height: 1.432rem; color: ${UserColors.blue01}`;
    static AddCard = 'font-size: 1.3rem; line-height: 1.8rem; font-weight: 700';
    static Playlist = `font-size: 1.3rem; line-height: 1.8rem; font-weight: 700; color: ${UserColors.blue01}`;
    static LoginInput = 'font-size: 1.1rem; line-height: 1.3rem';
    static MessageCard = 'font-size: 1rem; line-height: 1.3rem';
    static Button = `font-size: 1.3rem; line-height: 1.8rem; font-weight: 700; color: ${UserColors.white01}`;
    static LandingButton = `font-size: 2rem; line-height: 2.4rem; font-weight: 700; color: ${UserColors.white01}`;
}

export const UserGlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    color:${UserColors.black01};
    /* 1rem = 10px 보정 */
    font-size: 62.5%;
    /* 모바일 하이라이트 제거 */
    -webkit-tap-highlight-color: transparent;
    /* 모바일 스크롤 자연스럽게 */
    -webkit-overflow-scrolling: touch;
  }

  body {
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
  }
`;
