import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import UserColors from '../../constants/colors';
import MegaphoneIcon from '../../assets/megaphone.svg';
import LoadingSpinner from '../UI/User/LoadingSpinner';
import { RedirectUrl } from '../../constants/styled';
import useNotices from '../../hooks/useNotices';

function UserNoticeView() {
    const { id } = useParams();
    const { notices } = useNotices();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (id && notices) {
            const notice = notices.find((i) => i.id === id);
            setData(notice);
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }

        // 공지사항 읽음처리
        const localStorage = window.localStorage;
        const prevRead = JSON.parse(localStorage.getItem('NoticeRead')) || [];
        const updateRead = JSON.stringify(
            Array.from(new Set([...prevRead, id]))
        );
        localStorage.setItem('NoticeRead', updateRead);
    }, [id, notices]);

    const svgLink = (
        <svg
            width="11"
            height="11"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22 12V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H12C12.2652 2 12.5196 2.10536 12.7071 2.29289C12.8946 2.48043 13 2.73478 13 3C13 3.26522 12.8946 3.51957 12.7071 3.70711C12.5196 3.89464 12.2652 4 12 4H4V20H20V12C20 11.7348 20.1054 11.4804 20.2929 11.2929C20.4804 11.1054 20.7348 11 21 11C21.2652 11 21.5196 11.1054 21.7071 11.2929C21.8946 11.4804 22 11.7348 22 12ZM21.382 2.077C21.261 2.0263 21.1312 2.00013 21 2H16C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3C15 3.26522 15.1054 3.51957 15.2929 3.70711C15.4804 3.89464 15.7348 4 16 4H18.586L11.293 11.293C11.1975 11.3852 11.1213 11.4956 11.0689 11.6176C11.0165 11.7396 10.9889 11.8708 10.9877 12.0036C10.9866 12.1364 11.0119 12.2681 11.0622 12.391C11.1125 12.5139 11.1867 12.6255 11.2806 12.7194C11.3745 12.8133 11.4861 12.8875 11.609 12.9378C11.7319 12.9881 11.8636 13.0134 11.9964 13.0123C12.1292 13.0111 12.2604 12.9835 12.3824 12.9311C12.5044 12.8787 12.6148 12.8025 12.707 12.707L20 5.414V8C20 8.26522 20.1054 8.51957 20.2929 8.70711C20.4804 8.89464 20.7348 9 21 9C21.2652 9 21.5196 8.89464 21.7071 8.70711C21.8946 8.51957 22 8.26522 22 8V3C21.9994 2.86888 21.9732 2.73913 21.923 2.618C21.8215 2.37308 21.6269 2.17849 21.382 2.077Z"
                fill="#547AFF"
            />
        </svg>
    );

    return (
        <NoticeViewContainer>
            <LoadingSpinner isLoading={isLoading} isNotice={true} />
            <NoticeTitleWrapper>
                <NoticeTitle>
                    <img src={MegaphoneIcon} alt="MegaphoneIcon" />
                    {data && data.title}
                </NoticeTitle>
                <NoticeDate>{data && data.createdAt}</NoticeDate>
            </NoticeTitleWrapper>
            <ContentWrapper>{data && data.content}</ContentWrapper>
            {data && data.redirectUrl && (
                <NoticeRedirectUrl
                    href={data && data.redirectUrl}
                    target="_blank"
                >
                    {svgLink} 카카오톡 채널 바로가기
                </NoticeRedirectUrl>
            )}
        </NoticeViewContainer>
    );
}

const NoticeViewContainer = styled.div`
    margin: 1.2rem 1.5rem;
`;

const NoticeTitleWrapper = styled.div`
    margin-bottom: 2.4rem;
`;

const NoticeTitle = styled.div`
    font-size: 1.7rem;
    font-weight: 700;
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    word-break: break-all;

    & {
        img {
            margin-bottom: 0.15rem;
        }
    }
`;

const NoticeDate = styled.div`
    font-size: 1.1rem;
    color: ${UserColors.black02};
`;

const ContentWrapper = styled.pre`
    font-size: 1.5rem;
    white-space: pre-wrap;
`;

const NoticeRedirectUrl = styled(RedirectUrl)`
    font-size: calc(1.5rem * 0.9);
`;

export default UserNoticeView;
