import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    addActivityByUser,
    addViewedVideoByUser,
    updateActivityStatusByUser,
} from '../../lib/activities';
import moment from 'moment';
import VideoDisplayPlaylist from './VideoDisplayPlaylist';
import styled from 'styled-components';
import Button from '../UI/User/Button';
import UserColors from '../../constants/colors';
import { useNavigate } from 'react-router-dom';

class VideoDisplay extends React.PureComponent {
    timer;

    static propTypes = {
        playlist: PropTypes.array.isRequired,
        today: PropTypes.instanceOf(Date),
        test: PropTypes.instanceOf(Object),
        received: PropTypes.array.isRequired,
        navigate: PropTypes.any,
    };

    state = {
        curDT: moment().format('YYYY-MM-DD HH:mm:ss'),
        newArr: [],
        pk: [],
        activityId: '',
        curNum: 0,
        names: [],
        curName: '',
        isDisable: true,
        durLi: [],
    };

    // navigate = useNavigate();

    getTime = () => {
        // addActivity(this.state.newArr, this.state.curDT);
        addActivityByUser(this.state.newArr);
    };

    componentDidMount = () => {
        // On mount, check to see if the API script is already loaded
        window.addEventListener('popstate', this.handleBackButton);
        if (!window.YT) {
            // If not, load the script asynchronously
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';

            // onYouTubeIframeAPIReady will load the video after the script is loaded
            window.onYouTubeIframeAPIReady = this.loadVideo;

            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        } else {
            // If script is already there, load the video directly
            this.loadVideo();
        }
    };

    handleBackButton = (event) => {
        this.onAbort();
    };

    loadVideo = () => {
        const { youtubeId } = this.props;
        console.log(this.props.received.join(','));
        this.player = new window.YT.Player('youtube-player-80GkyUDDp4c', {
            width: '320',
            // height: '180px',
            // videoId: this.props.received[0],
            playerVars: {
                listType: 'playlist',
                playlist: this.props.received.join(','),
                // controls: 0,
            },
            events: {
                onReady: this.onPlayerReady,
                onStateChange: this.onVideoViewed,
                onError: this.onPlayerError,
            },
        });
    };

    onPlayerError = (event) => {
        console.log(event);
        console.log('에러떳다에러에러에러에렁러어렝러ㅔㅇㄹ');
    };

    onPlayerReady = (event) => {
        const newArr = this.props.playlist.map((item) => item.youtubeId);
        // this.player.cuePlaylist({
        //     playlist: newArr.join(','),
        //     playsinline: 1,
        //     // listType: 'playlist',
        // });
        this.player.loadPlaylist({
            playlist: newArr,
            listType: 'playlist',
        });

        const pk = this.props.playlist.map((item) => item.id);
        const names = this.props.playlist.map((item) => item.name);
        // this.props.activityId = pk;
        this.setState(
            {
                newArr,
                pk,
                names,
            },
            () => {
                console.log(this.state.newArr);
                this.setState({
                    curName: this.state.names[this.state.curNum],
                });
            }
        );

        const sleep = (milliseconds) => {
            return new Promise((resolve) => setTimeout(resolve, milliseconds));
        };
        // event.target.playVideo();
        const embedCode = event.target.getVideoEmbedCode();
        // const newArrStr = this.state.newArr.toString();
        // addActivity(this.state.newArr.toString(), this.state.curDT);
        // console.log(this.state.newArr);
        this.getActID(pk);
    };

    async getActID(pk) {
        const activityId = await addActivityByUser(pk);
        // console.log('activityID!!!');
        // console.log(activityId);
        // const data = await updateActivityStatusByUser(activityId.id, 'ABORTED');
        // console.log('Data!!!!');
        // console.log(data);
        // console.log(activityId);
        // console.log('여기다!!!');
        const { id } = activityId;
        this.setState(
            {
                activityId: `${id}`,
            },
            () => {
                console.log(this.state.activityId);
                // console.log('여기다2!!');
            }
        );
        localStorage.setItem('activityId', id);
    }

    onVideoViewed = (event) => {
        if (event.data === 0) {
            console.log('비디오 전부 끝!');
            const tnow = moment();
            const dur = moment
                .duration(tnow.diff(this.state.curDT))
                .asMilliseconds();
            if (this.player.playerInfo.currentTime > 20) {
                console.log('add 시도!');
                this.addOneViewedVideo(dur);
            }
            this.updateStatus();
            this.onComplete();
            // Timer(this.state.activityId);
        }
        if (event.data === -1) {
            console.log('비디오 한개 끝!');
            console.log(this.state.durLi);
            console.log(this.player.playerInfo.duration);
            const tnow = moment();
            const dur = moment
                .duration(tnow.diff(this.state.curDT))
                .asMilliseconds();
            console.log(this.player.playerInfo);
            console.log(this.player.playerInfo.playlistIndex);
            console.log(this.player.playerInfo.playlist.length);
            if (this.player.playerInfo.playlistIndex) {
                console.log('add 시도!');
                this.addOneViewedVideo(dur);
            }
        }
        if (event.data === 1) {
            this.setState(
                {
                    durLi: [
                        ...this.state.durLi,
                        this.player.playerInfo.duration,
                    ],
                },
                () => {
                    console.log(this.state.durLi);
                    // console.log('여기다4!!');
                    if (
                        this.player.playerInfo.playlistIndex ===
                        this.player.playerInfo.playlist.length - 1
                    ) {
                        this.onStartLastVideo(
                            this.state.durLi[this.state.durLi.length - 1]
                        );
                    }
                }
            );
        }
    };

    onClose = () => {
        this.onAbort();
        this.props.navigate('/home');
    };

    async onAbort() {
        console.log(this.state.activityId);
        const data = await updateActivityStatusByUser(
            this.state.activityId,
            'ABORTED'
        );
        console.log(data);
    }

    onStartLastVideo(dur) {
        console.log('onstartlastvideo');
        console.log(dur);
        const vidDur = dur;
        this.timer = setTimeout(() => {
            // alert('영상 종료 10초 전이므로 활성화지금돼야함');
            this.setState({ isDisable: false });
        }, vidDur * 1000 - 10000);
        return false;
    }

    // async callbackHell() {
    //     const tmp = await this.player.playerInfo.duration;
    //     return tmp;
    // }

    async addOneViewedVideo(dur) {
        // console.log(dur);
        // console.log(this.state.activityId);
        // console.log(this.state.curNum);
        // console.log(this.state.newArr[this.state.curNum]);
        const data = await addViewedVideoByUser(
            this.state.activityId,
            this.state.pk[this.state.curNum],
            dur
        );
        this.setState(
            {
                curNum: this.state.curNum + 1,
                curDT: moment().format('YYYY-MM-DD HH:mm:ss'),
            },
            () => {
                console.log(this.state.activityId);
                this.setState({
                    curName: this.state.names[this.state.curNum],
                });
            }
        );
    }

    async updateStatus() {
        console.log(this.state.activityId);
        const data = await updateActivityStatusByUser(
            this.state.activityId,
            'COMPLETE'
        );
        console.log(data);
    }

    onClick = (player) => {
        // console.log(this.props.playlist[0]);
        const tnow = moment();
        const dur = moment
            .duration(tnow.diff(this.state.curDT))
            .asMilliseconds();
        console.log(dur);
        console.log(this.player.playerInfo.currentTime);
        // const newArr = this.props.playlist.map((item) => item.youtubeId);
        // console.log(newArr);
        // console.log(this.props.today);
        // console.)log(this.player);
        // console.log(this.player.playerInfo);
        // console.log(this.player.playerInfo.currentTime);
    };

    onComplete = () => {
        this.timer = setTimeout(() => {
            alert('종료 후 10분 경과로 동영상 시청이 미완료 처리됩니다');
            this.updateStatusIncomplete();
        }, 600000);
        return false;
    };

    onClickComplete = () => {
        this.props.navigate('/complete', {
            state: {
                curNum: this.player.playerInfo.playlistIndex,
                playlist: this.state.names,
            },
        });
    };

    async updateStatusIncomplete() {
        const data = await updateActivityStatusByUser(
            this.state.activityId,
            'INCOMPLETE'
        );
        console.log(data);
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.handleBackButton);
        clearTimeout(this.timer);
    }

    isComplete = () => {};

    render = () => {
        const { id } = this.props;
        return (
            <>
                <DisplayWrapper>
                    <YoutubePlayer id={'youtube-player-80GkyUDDp4c'} />
                    <StatusWrapper>
                        <TitleWrapper>
                            <Title>
                                {this.state.curName
                                    ? this.state.curName
                                    : this.state.curNum === 0
                                    ? 'Now Loading'
                                    : 'Completed'}
                            </Title>
                            <Current>
                                {this.state.curName ? (
                                    <>
                                        <Num>
                                            {Math.min(
                                                this.state.curNum + 1,
                                                this.state.names.length
                                            )}
                                        </Num>
                                        번째 운동 시청 중
                                    </>
                                ) : this.state.curNum === 0 ? (
                                    '로딩 중입니다'
                                ) : (
                                    '운동 시청 완료'
                                )}
                            </Current>
                        </TitleWrapper>
                        <VideoDisplayPlaylist
                            videos={this.state.names}
                            isComplete={this.isComplete}
                            curNum={this.state.curNum}
                        />
                        <Button
                            action={this.onClickComplete}
                            color={'blue'}
                            isDisable={this.state.isDisable}
                        >
                            운동 완료
                        </Button>
                    </StatusWrapper>
                </DisplayWrapper>
                {/* <button onClick={this.onComplete}>테스트용</button>
                    <button onClick={this.getTime}>api 전송 테스트</button> */}
            </>
        );
    };
}

const DisplayWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - (4.4rem));
`;

const YoutubePlayer = styled.div`
    background-color: black;
    padding: 0;
    /* 16:10 비율 */
    width: 100vw;
    height: 56.3vw;
    min-height: 19.8rem;
    min-width: 35.2rem;
    flex-shrink: 0;

    @media screen and (min-width: 600px) {
        width: 100%;
        height: auto;
    }
`;

const StatusWrapper = styled.div`
    padding: 0 1.6rem;
    margin: 1.5rem 0;
    height: 100%;
    /* 히든줘야 밖으로 나가는 애들이 플렉스 컨테이너 안에 담김 */
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-shrink: 0;
`;

const Title = styled.div`
    font-size: 1.7rem;
    font-weight: 700;
    color: ${UserColors.blue01};
`;

const Current = styled.div`
    font-size: 1rem;
    color: ${UserColors.black02};
`;

const Num = styled.span`
    color: ${UserColors.blue01};
    font-size: 1rem;
`;

export default VideoDisplay;
