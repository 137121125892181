import { useNavigate } from 'react-router-dom';
import IcaanLogo from '../../assets/icaan-logo.svg';
import IconC from '../../assets/icon-c.svg';
import IconA from '../../assets/icon-a.svg';
import IconN from '../../assets/icon-n.svg';
import Underline from '../../assets/underline.svg';
import GrayBubble from '../../assets/gray-bubble.svg';
import BlueBubble from '../../assets/blue-bubble.svg';
import IphoneSample from '../../assets/iphone-sample.svg';
import ScrollToTopIcon from '../../assets/scroll-to-top.svg';
import styled from 'styled-components';
import Button from '../../components/UI/User/Button';
import UserColors from '../../constants/colors';
import { UserShadows } from '../../constants/styles';

function UserLanding() {
    const navigate = useNavigate();
    const onStart = () => {
        navigate('/login');
    };
    return (
        <>
            <LandingWrapper>
                <TitleWrapper>
                    <SubTitle>
                        내 곁의
                        <br />
                        건강한 운동 짝꿍
                    </SubTitle>
                    <img src={IcaanLogo} alt="IcaanLogo"></img>
                </TitleWrapper>
                <BigCircle />
                <SmallCircle />
                <Background />
                <DialogueWrapper>
                    <Dialogue>
                        <BubbleWrapper color="gray">
                            <img src={GrayBubble} alt="GrayBubble" />
                            <BubbleTitleWrapper color="gray">
                                <BubbleTitle>청소년 운동 부족,</BubbleTitle>
                                <BubbleSubTitle>
                                    뾰족한 해결법이 없을까요?
                                </BubbleSubTitle>
                            </BubbleTitleWrapper>
                        </BubbleWrapper>
                        <img src={IconC} alt="IconC" />
                    </Dialogue>
                    <Dialogue>
                        <img src={IconA} alt="IconA" />
                        <BubbleWrapper color="blue">
                            <img src={BlueBubble} alt="BlueBubble" />
                            <BubbleTitleWrapper color="blue">
                                <BubbleTitle color={UserColors.blue01}>
                                    Activity !
                                </BubbleTitle>
                                <BubbleSubTitle color={UserColors.blue01}>
                                    재밌고 규칙적인 운동을 습관화하고,
                                </BubbleSubTitle>
                            </BubbleTitleWrapper>
                        </BubbleWrapper>
                    </Dialogue>
                    <Dialogue>
                        <img src={IconN} alt="IconN" />
                        <BubbleWrapper color="blue" last={true}>
                            <img src={BlueBubble} alt="BlueBubble" />
                            <BubbleTitleWrapper color="blue">
                                <BubbleTitle color={UserColors.blue01}>
                                    Nutrition !
                                </BubbleTitle>
                                <BubbleSubTitle color={UserColors.blue01}>
                                    올바른 식습관과 식단을 배워보아요!
                                </BubbleSubTitle>
                            </BubbleTitleWrapper>
                        </BubbleWrapper>
                    </Dialogue>
                    <CatchphraseWrapper>
                        <Catchphrase>“ 할 수 있다! ”</Catchphrase>
                        <img src={Underline} alt="Underline" />
                    </CatchphraseWrapper>
                </DialogueWrapper>
                <SlideWrapper>
                    <img src={IphoneSample} alt="IphoneSample" />
                    <img src={IphoneSample} alt="IphoneSample" />
                    <img src={IphoneSample} alt="IphoneSample" />
                </SlideWrapper>
                <Button action={onStart} isLanding={true}>
                    바로 시작하기
                </Button>
                <Footer>
                    <PlayIdea>ⓒ2022 PLAYIDEA</PlayIdea>
                    <License>오픈소스라이센스 | 개인정보처리방침</License>
                </Footer>
                <ScrollToTop
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        });
                    }}
                >
                    <img src={ScrollToTopIcon} alt="ScrollToTopIcon" />
                </ScrollToTop>
            </LandingWrapper>
        </>
    );
}

const LandingWrapper = styled.div`
    padding-top: 0.3rem;
    position: relative;
`;

const TitleWrapper = styled.div`
    margin: 0 2.3rem;

    & {
        img {
            position: relative;
            top: -2rem;
        }
    }
`;

const SubTitle = styled.div`
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    color: ${UserColors.blue01};
`;

const BigCircle = styled.div`
    width: 8.008rem;
    height: 8.008rem;
    background: linear-gradient(
        172.65deg,
        rgba(255, 249, 227, 0) -4.78%,
        rgba(255, 221, 113, 0.8) 76.23%
    );
    border-radius: 100px;
    transform: rotate(-109.73deg);
    position: absolute;
    top: 7.8rem;
    right: 1.7rem;
`;

const SmallCircle = styled.div`
    width: 3.389rem;
    height: 3.389rem;
    background: linear-gradient(
        172.65deg,
        rgba(255, 249, 227, 0.16) -4.78%,
        rgba(255, 221, 113, 0.56) 67.46%
    );
    opacity: 0.8;
    border-radius: 100px;
    transform: rotate(56.3deg);
    position: absolute;
    top: 2.3rem;
    right: 3rem;
`;

const Background = styled.div`
    width: 100%;
    height: 88%;
    background: linear-gradient(180deg, #ffedb4 0%, #ffc100 100%);
    border-radius: 40px 40px 0px 0px;
    position: absolute;
    top: 18.2rem;
    z-index: -10;
`;

const DialogueWrapper = styled.div`
    background: #ffffff;
    border-radius: 20px;
    ${UserShadows.dropShadow01}
    padding:2.9rem 2rem;
    margin: 0 1.7rem;
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.7rem;

    & {
        div:nth-child(2) {
            margin-bottom: 0.3rem;
        }
    }
`;

const Dialogue = styled.div`
    display: flex;
    justify-content: space-between;
    & {
        img {
            display: block;
            position: relative;
            top: -1.4rem;
        }
    }
`;

const BubbleWrapper = styled.div`
    position: relative;
    margin-left: ${(props) => (props.color === 'blue' ? '1.2rem' : 0)};
    top: ${(props) => (props.color === 'blue' ? '-0.3rem' : 0)};
    top: ${(props) => (props.last ? '-0.8rem' : '')};
    & {
        img {
            all: unset;
        }
    }
`;

const BubbleTitleWrapper = styled.div`
    position: absolute;
    top: ${(props) => (props.color === 'gray' ? '0.9rem' : '1.05rem')};
    left: ${(props) => (props.color === 'gray' ? '1.3rem' : '2.1rem')};
`;

const BubbleTitle = styled.div`
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: ${(props) => props.color};
`;

const BubbleSubTitle = styled.div`
    font-size: 1.1rem;
    color: ${(props) => props.color};
`;

const CatchphraseWrapper = styled.div`
    margin: 0 auto;
    margin-top: 1.3rem;
    margin-bottom: 3.3rem;
    text-align: center;

    & {
        img {
            position: relative;
            top: -1.1rem;
            z-index: 1;
        }
    }
`;

const Catchphrase = styled.div`
    color: ${UserColors.blue01};
    font-weight: 700;
    font-size: 3.4rem;
    position: relative;
    z-index: 2;
`;

const SlideWrapper = styled.div`
    width: 35.2rem;
    margin: 0 auto;
    position: relative;
    top: -4.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    & {
        img {
            display: block;
            position: relative;
        }
        img:first-child,
        img:last-child {
            width: 55%;
            margin-bottom: 3rem;
            opacity: 0.5;
        }

        img:first-child {
            animation: first 8s infinite;
        }

        img:nth-child(2) {
            animation: second 8s infinite;
        }

        img:last-child {
            animation: third 8s infinite;
        }

        @keyframes first {
            0% {
                left: 0;
                top: 0;
                transform: scale(100%);
                opacity: 0.5;
            }
            20% {
                left: 21.6rem;
                top: -1.55rem;
                transform: scale(123%);
                opacity: 1;
            }
            30% {
                left: 21.6rem;
                top: -1.55rem;
                transform: scale(123%);
                opacity: 1;
            }
            50% {
                left: 43.2rem;
                top: 0em;
                transform: scale(100%);
                opacity: 0.5;
            }
            60% {
                left: 43.2rem;
                top: 0em;
                transform: scale(100%);
                opacity: 0.5;
            }
            80% {
                left: 0;
                top: 0;
                transform: scale(100%);
                opacity: 0.5;
            }
            100% {
                left: 0;
                top: 0;
                transform: scale(100%);
                opacity: 0.5;
            }
        }

        @keyframes second {
            0% {
                left: 0;
                top: 0;
                transform: scale(100%);
                opacity: 1;
            }
            20% {
                left: 21.6rem;
                top: 1.5rem;
                transform: scale(81.5%);
                opacity: 0.5;
            }
            30% {
                left: 21.6rem;
                top: 1.5rem;
                transform: scale(81.5%);
                opacity: 0.5;
            }
            50% {
                left: -21.6rem;
                top: 1.5rem;
                transform: scale(81.5%);
                opacity: 0.5;
            }
            60% {
                left: -21.6rem;
                top: 1.5rem;
                transform: scale(81.5%);
                opacity: 0.5;
            }
            80% {
                left: 0;
                top: 0;
                transform: scale(100%);
                opacity: 1;
            }
            100% {
                left: 0;
                top: 0;
                transform: scale(100%);
                opacity: 1;
            }
        }

        @keyframes third {
            0% {
                left: 0;
                top: 0;
                transform: scale(100%);
                opacity: 0.5;
            }
            20% {
                left: -43.2rem;
                top: 0em;
                transform: scale(100%);
                opacity: 0.5;
            }
            30% {
                left: -43.2rem;
                top: 0em;
                transform: scale(100%);
                opacity: 0.5;
            }
            50% {
                left: -21.6rem;
                top: -1.55rem;
                transform: scale(123%);
                opacity: 1;
            }
            60% {
                left: -21.6rem;
                top: -1.55rem;
                transform: scale(123%);
                opacity: 1;
            }
            80% {
                left: 0;
                top: 0;
                transform: scale(100%);
                opacity: 0.5;
            }
            100% {
                left: 0;
                top: 0;
                transform: scale(100%);
                opacity: 0.5;
            }
        }
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
    align-items: center;
    margin-top: 2.75rem;
    margin-bottom: 2.5rem;
`;

const PlayIdea = styled.div`
    font-weight: 700;
    font-size: 1.3rem;
    color: #ffffff;
`;

const License = styled.div`
    font-size: 1rem;
    color: #ffffff;
`;

const ScrollToTop = styled.div`
    position: absolute;
    bottom: -2.4rem;
    right: 1.5rem;
    cursor: pointer;
    animation: motion 0.6s ease-out infinite alternate;

    @keyframes motion {
        0% {
            bottom: -2.4rem;
        }
        100% {
            bottom: -2rem;
        }
    }
`;

export default UserLanding;
