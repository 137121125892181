import styles from './FormInputArea.module.css';

const FormInputArea = (props) => {
    return (
        <div className={`${styles['input-area']} ${props.className}`}>
            {props.children}
        </div>
    );
};

export default FormInputArea;
