import React, { useState, useEffect, useRef } from 'react';
import NoticesTable from '../../components/Admin/NoticesTable';
import { getAllNotices } from '../../lib/notices';

import styles from './AdminNotices.module.css';

const AdminNotices = () => {
    const [noticesData, setNoticesData] = useState([]);
    const [isScheduled, setIsScheduled] = useState(false);

    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);

    useEffect(() => {
        const getNotices = async () => {
            const res = await getAllNotices();
            if (res.code) {
                console.log(res);
                return;
            }
            if (res.length !== 0) {
                setNoticesData(res);
            } else {
                gridRef.current.api.showNoRowsOverlay();
            }
        };
        if (gridApi) {
            gridRef.current.api.showLoadingOverlay();
            getNotices();
        }
    }, [gridApi]);

    const commonNoticeHandler = () => {
        setIsScheduled(false);
    };

    const scheduledNoticeHandler = () => {
        setIsScheduled(true);
    };

    return (
        <div style={{ height: '100%' }}>
            <div className={styles.switch}>
                <div
                    className={!isScheduled ? styles.active : undefined}
                    onClick={commonNoticeHandler}
                >
                    <p>일반 공지사항</p>
                </div>
                <div
                    className={isScheduled ? styles.active : undefined}
                    onClick={scheduledNoticeHandler}
                >
                    <p>예약 공지사항</p>
                </div>
            </div>
            <NoticesTable
                isScheduled={isScheduled}
                noticesData={noticesData}
                gridRef={gridRef}
                onGridReady={setGridApi}
            />
        </div>
    );
};

export default AdminNotices;
