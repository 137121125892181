import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/UI/User/Button';
import styled from 'styled-components';
import UserColors from '../../constants/colors';
import ExerciseBasket from '../../components/UI/User/ExerciseBasket';
import Lottie from 'react-lottie-player';
import LottieJson from '../../assets/lottie.json';
import pickRandomInt from '../../lib/pickRandomInt';
import { CompleteMessages } from '../../constants/completeMessages';
import { useState, useEffect } from 'react';

function UserCommpletePage() {
    const navigate = useNavigate();
    const location = useLocation();
    const curNum = location.state.curNum;
    const videos = location.state.playlist;
    const [completeMessage, setCompleteMessage] = useState('');
    const onClick = () => {
        navigate('/home');
    };

    useEffect(() => {
        const messagesCnt = Object.keys(CompleteMessages).length;
        const randomNumber = pickRandomInt(1, messagesCnt);
        const message = CompleteMessages[randomNumber];
        setCompleteMessage(message);
    }, []);

    return (
        <>
            <CompleteContainer>
                <Header>운동 완료!</Header>
                <CompleteWrapper>
                    <StatusWrapper>
                        <LottieWrapper>
                            <Lottie
                                loop
                                animationData={LottieJson}
                                play
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </LottieWrapper>
                        <pre>{completeMessage}</pre>
                        {/* <p>
                            <Num>{curNum + 1}</Num>개의 운동을 수행하셨어요!
                        </p> */}
                    </StatusWrapper>
                    {/* <ExerciseBasket
                        videos={videos.slice(0, curNum + 1)}
                        isStatic={true}
                    /> */}
                </CompleteWrapper>
                <Button action={onClick} color="yellow" margin="auto 0 5vh 0">
                    나가기
                </Button>
            </CompleteContainer>
        </>
    );
}

const CompleteContainer = styled.div`
    padding: 3.2rem 1.6rem;
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

const CompleteWrapper = styled.div`
    text-align: center;
`;

const Header = styled.div`
    font-weight: 700;
    font-size: 2.4rem;
    color: ${UserColors.yellow01};
`;

const StatusWrapper = styled.div`
    margin-bottom: 7vh;
    font-size: 1.5rem;
    color: ${UserColors.blue01};

    > pre {
        color: inherit;
        font-size: inherit;
        line-height: 150%;
    }

    > p {
        margin-top: 0.7rem;
        font-size: 1.3rem;
    }
`;

const LottieWrapper = styled.div`
    margin-top: 6vh;
    margin-bottom: 6vh;
`;

const Num = styled.span`
    font-size: 1.3rem;
    color: ${UserColors.blue01};
`;

export default UserCommpletePage;
