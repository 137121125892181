import React, { memo, useMemo, useEffect, useState } from 'react';
import { lineChartOptions } from '../../lib/chart-js';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { statusLineChartData } from '../../lib/requestChartData';

const StatusLineChart = (props) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const getLineChartData = async () => {
            const res = await statusLineChartData();
            if (res.code) {
                console.log(res);
                return;
            }
            setChartData(res.data);
        };
        getLineChartData();
    }, []);

    const labels = useMemo(() => props.labels, []);

    const data = {
        labels,
        datasets: [
            {
                label: 'COMPLETE',
                data: chartData ? chartData.complete : new Array(6).fill(0),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'INCOMPLETE',
                data: chartData ? chartData.incomplete : new Array(6).fill(0),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'ABORTED',
                data: chartData ? chartData.aborted : new Array(6).fill(0),
                borderColor: 'rgb(232, 144, 28)',
                backgroundColor: 'rgba(232, 144, 28, 0.5)',
            },
            {
                label: 'ETC',
                data: chartData ? chartData.etc : new Array(6).fill(0),
                borderColor: 'rgb(150, 144, 144)',
                backgroundColor: 'rgba(150, 144, 144, 0.5)',
            },
        ],
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Line options={lineChartOptions} data={data} redraw={true} />
        </div>
    );
};

export default memo(StatusLineChart);
