import { createAction, handleActions } from 'redux-actions';

/**
 * Action
 */
const SET_MESSAGES = 'messages/SET_MESSAGES';

export const setMessages = createAction(SET_MESSAGES, (messages) => ({
    messages,
}));

/**
 * Reducer
 */

const initialState = [];

const messages = handleActions(
    {
        [SET_MESSAGES]: (state, { payload }) => payload.messages,
    },
    initialState
);

export default messages;
