import styles from './AdminMain.module.css';
import CalendarChart from '../../components/Admin/CalendarChart';
import TodaysStats from '../../components/Admin/TodaysStats';
import StatusLineChart from '../../components/Admin/StatusLineChart';
import VideoBarGraph from '../../components/Admin/VideoBarGraph';
import { useMemo, useContext } from 'react';
import BoxPlot from '../../components/Admin/BoxPlot';
import Help from '../../components/UI/Admin/Help';
import AuthContext from '../../store/AuthContext';

const AdminMain = () => {
    const currentYear = useMemo(() => new Date().getFullYear(), []);
    const currentMonth = useMemo(() => new Date().getMonth() + 1, []);
    const labelArr = new Array(6).fill(0);
    const sixMonthsLabel = labelArr.map(
        (value, index) => currentMonth - (5 - index)
    );
    const authContext = useContext(AuthContext);
    console.log(sixMonthsLabel);
    console.log(authContext.user);
    return (
        <div className={styles.main}>
            <div className={styles['grid-item']}>
                <TodaysStats />
                {/* 사용 방법 */}
                {/* 도움말 아이콘 크기 : size (단위 %) = default는 100% */}
                {/* 말풍선 크기 : width (단위 rem) = default는 15rem */}
                {/* 말풍선 위치 : 4종류 = 필수 지정 */}
                {/* 1. left top */}
                {/* 2. left bottom */}
                {/* 3. right top */}
                {/* 4. right bottom */}
                {/* fade 효과를 위해 off시 말풍선은 opacity 0입니다 */}
                {/* 즉, off시에도 display block으로, 자리를 차지하기 때문에 */}
                {/* 위치 조정을 알맞게 해주셔야 화면 밖으로 빠져나가지 않습니다 */}
                {/* 적용하고자 하는 div에 position:relative를 해줘야 */}
                {/* 오른쪽 상단에 자동 배치됩니다 */}
                {/* grid-item은 제가 해뒀습니다! */}
                {/* 색/배치/세부기능 직접 수정하셔도 됩니다! (UI/Admin/Help.js) */}
                {/* ************************************************** */}
                {/* 추가 기능 */}
                {/* top : top (단위 rem) = default는 -0.2rem */}
                {/* right : right (단위 rem) = default는 -0.2rem */}
                {/* display : block, none 등 문자열로 전달 = default는 inline-block */}
                {/* isRelative : true 전달 시 relative = false 및 default는 absolute */}
                {/* alignRelativeBlockRight : true 전달 시 relative인 block을 우측 정렬 */}
                {/* alignRelativeBlockRight 사용 시 display=block && isRelative=true를 필수로 넘겨주세요 */}
                <Help
                    header={'오늘의 통계'}
                    size={'80%'}
                    width={'13rem'}
                    position={'right top'}
                >
                    오늘의 동영상 시청 현황입니다.
                </Help>
            </div>
            <div className={styles['grid-item']}>
                <CalendarChart
                    currentMonth={currentMonth}
                    currentYear={currentYear}
                />
                <Help
                    header={'전체 출석률'}
                    size={'80%'}
                    width={'17rem'}
                    position={'left top'}
                >
                    일별로 동영상을 시청한 피험자들의 수입니다. 시청 완료
                    여부와는 무관합니다.
                </Help>
            </div>

            <div className={styles['grid-item']}>
                <BoxPlot labels={sixMonthsLabel} />
                <Help
                    header={'월별 평균 운동 시간 분포'}
                    size={'80%'}
                    width={'15rem'}
                    position={'left top'}
                >
                    각 피험자들의 월별 평균 운동 시간을 수합하여 요약한 다
                    통계입니다.
                </Help>
            </div>

            <div className={styles['grid-item']} id="bar">
                <VideoBarGraph />
                <Help
                    header={'누적 재생횟수'}
                    size={'80%'}
                    width={'17rem'}
                    position={'right top'}
                >
                    현시점까지 누적 재생횟수가 많은 상위 5개와 하위 5개의 운동
                    동영상입니다.
                </Help>
            </div>
            <div className={styles['grid-item']}>
                <StatusLineChart
                    labels={sixMonthsLabel.map((value) => value + '월')}
                    currentMonth={currentMonth}
                />
                <Help
                    header={'상태별 시청 현황'}
                    size={'80%'}
                    width={'20rem'}
                    position={'left top'}
                >
                    월별로 기록된 피험자들의 동영상 시청 상태입니다.
                    <ul>
                        <li>
                            <strong>COMPLETE</strong>: 선택한 영상을 모두
                            시청하고 '완료하기' 버튼까지 누른 상태
                        </li>
                        <li>
                            <strong>INCOMPLETE</strong>: 선택한 영상을 모두
                            시청했다고 기록되었으나, 유저가 '완료하기' 버튼을
                            일정 시간 동안 누르지 않은 상태
                        </li>
                        <li>
                            <strong>ABORTED</strong>: 영상을 시청하다가 뒤로가기
                            버튼을 눌러 영상을 끝까지 시청하지 않고 취소한 상태
                        </li>
                        <li>
                            <strong>빈 값</strong>: 유저가 영상 시청 도중 앱을
                            종료하는 등의 문제로 인해 상태가 기록되지 못한 상태
                        </li>
                    </ul>
                </Help>
            </div>
        </div>
    );
};

export default AdminMain;
