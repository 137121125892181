import { useEffect, useState } from 'react';
import { userLogin } from '../../lib/auth';
import styles from './UserLogin.module.css';
import { useNavigate } from 'react-router-dom';
import isLogin from '../../components/User/IsUserLogin';
import { Urls } from '../../constants/urls';

function UserLogin() {
    const navigate = useNavigate();
    useEffect(() => {
        console.log(isLogin);
        if (isLogin) {
            return navigate('/home');
        }
    }, [isLogin]);
    const [form, setForm] = useState({
        username: '',
        name: '',
    });
    const { username, id } = form;
    // const createChangeTextHandler = (key) => (value) => {
    //     setForm({ ...form, [key]: value });
    // };
    const createChangeTextHandler = (e) => {
        const { value, name } = e.target;
        setForm({ ...form, [name]: value });
    };
    const onSubmit = async (event) => {
        event.preventDefault();
        const { username, name } = form;
        const info = { username, name };
        // console.log(form);
        console.log(info);
        // try {
        //     const { user } = await userLogin(info);
        //     console.log(user);
        //     navigate('/main');
        // } catch (e) {
        //     alert('로그인 실패');
        //     console.log(e);
        // }
        const user = await userLogin(info);
        if (user.code !== undefined) {
            console.log(user);
        } else {
            localStorage.setItem('userId', user.id);
            localStorage.setItem('userName', user.name);
            localStorage.setItem('accessToken', user.accessToken);
            navigate('/home');
            console.log(user);
        }
    };

    const [focus, setFocus] = useState({
        name: false,
        username: false,
    });

    const focusInput = (e) => {
        setFocus({
            ...focus,
            [e.target.name]: true,
        });
    };

    const blurInput = (e) => {
        setFocus({
            ...focus,
            [e.target.name]: false,
        });
    };

    const {
        container,
        mainWrapper,
        title,
        nameInput,
        usernameInput,
        svgIcon,
        forgotId,
        findId,
        footer,
        playIdea,
        license,
    } = styles;

    return (
        <div className={mainWrapper}>
            <div className={title}>로그인</div>
            {/* ************** 이름 Input Box ************** */}
            {/* prettier-ignore */}
            <div className={`${nameInput} ${focus.name ? 'focusInputBox' : ''} ${focus.name && form.name !== '' ? 'filledInputBox' : ''}`}>
                    {/* prettier-ignore */}
                    <div className={`${svgIcon} ${form.name !== '' ? 'filledInputIcon' : ''}`}>
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13C1 13 0 13 0 11.9167C0 10.8333 1 7.58333 6 7.58333C11 7.58333 12 10.8333 12 11.9167C12 13 11 13 11 13H1ZM6 6.5C6.79565 6.5 7.55871 6.15759 8.12132 5.5481C8.68393 4.9386 9 4.11195 9 3.25C9 2.38805 8.68393 1.5614 8.12132 0.951903C7.55871 0.34241 6.79565 0 6 0C5.20435 0 4.44129 0.34241 3.87868 0.951903C3.31607 1.5614 3 2.38805 3 3.25C3 4.11195 3.31607 4.9386 3.87868 5.5481C4.44129 6.15759 5.20435 6.5 6 6.5Z" fill="#DBDBDB" />
                        </svg>
                    </div>
                    <input
                        name="name"
                        placeholder="이름을 입력해주세요"
                        onChange={createChangeTextHandler}
                        autoComplete="off"
                        onFocus={focusInput}
                        onBlur={blurInput}
                    />
                </div>
            {/* ************** 아이디 Input Box ************** */}
            {/* prettier-ignore */}
            <div className={`${usernameInput} ${focus.username ? 'focusInputBox' : ''} ${focus.username && form.username !== '' ? 'filledInputBox' : ''}`}>
                    {/* prettier-ignore */}
                    <div className={`${svgIcon} ${form.username !== '' ? 'filledInputIcon' : ''}`}>
                        {/* prettier-ignore */}
                        <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.64167 5.78798C9.45369 5.61301 9.19131 5.50906 8.85256 5.47095V3.76279C8.85256 2.89486 8.66458 2.18284 8.28862 1.62673C7.91267 1.07063 7.42902 0.66178 6.83963 0.396722C6.25025 0.131663 5.63736 0 4.99902 0C4.36068 0 3.7478 0.131663 3.15841 0.396722C2.56902 0.66178 2.08537 1.07236 1.70942 1.62673C1.33346 2.1811 1.14549 2.89486 1.14549 3.76279V5.51426C0.873311 5.5645 0.640298 5.65978 0.448404 5.80184C0.148815 6.02185 0 6.35967 0 6.81703V11.6383C0 12.1061 0.119444 12.4508 0.358332 12.6708C0.59722 12.8909 0.961426 13 1.45291 13H8.55688C9.04053 13 9.40278 12.8909 9.64167 12.6708C9.88056 12.4508 10 12.1061 10 11.6383V6.81703C10 6.35448 9.88056 6.01146 9.64167 5.78971V5.78798ZM2.88232 2.23134C3.11533 1.871 3.41884 1.60075 3.79479 1.42404C4.17075 1.2456 4.57216 1.15725 4.99902 1.15725C5.42589 1.15725 5.8273 1.2456 6.20325 1.42404C6.57921 1.60248 6.88467 1.871 7.1216 2.23134C7.35657 2.59168 7.47601 3.04384 7.47601 3.58435V5.45709L2.53378 5.46922V3.58435C2.53378 3.04384 2.6493 2.59168 2.88232 2.23134Z" fill="#DBDBDB" />
                        </svg>
                    </div>
                    <input
                        name="username"
                        placeholder="ID를 입력해주세요"
                        onChange={createChangeTextHandler}
                        autoComplete="off"
                        onFocus={focusInput}
                        onBlur={blurInput}
                    />
                </div>
            {/* ************** 로그인 버튼 ************** */}
            {/* prettier-ignore */}
            <div className={`${form.name !== '' && form.username !== '' ? 'readyBtn' : ''}`}>
                    <button onClick={onSubmit}>로그인</button>
                </div>
            <div className={forgotId}>
                ID를 잊어버리셨나요?
                <span className={findId}>
                    <a href={Urls.kakaoTalkChannelUrl}>문의하기 &rang;</a>
                </span>
            </div>
            {/* ************** 푸터 ************** */}
            <div className={footer}>
                <div className={playIdea}>ⓒ2022 PLAYIDEA</div>
                <div className={license}>
                    오픈소스라이센스 및 개인정보처리방침은
                    <br />
                    <span
                        style={{
                            textDecoration: 'underline',
                            textUnderlinePosition: 'under',
                        }}
                    >
                        <a href="#">이 곳</a>
                    </span>
                    에서 확인하실 수 있습니다.
                </div>
            </div>
        </div>
    );
}

export default UserLogin;
