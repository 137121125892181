import React from 'react';
import styles from './Header.module.css';

const Header = (props) => {
    const { container, title } = styles;
    return (
        <div className={container}>
            <div className={title}>{props.children}</div>
        </div>
    );
};

export default Header;
