import React, { useState } from 'react';
import Modal from '../UI/Admin/Modal';
import FormInputArea from '../UI/Admin/FormInputArea';
import FormLabel from '../UI/Admin/FormLabel';
import FormInput from '../UI/Admin/FormInput';
import { updateResource } from '../../lib/resources';
import CloseIcon from '../../assets/close.svg';
import HelpIcon from '../../assets/help.svg';
import Spinner from '../UI/Admin/Spinner';

import styles from './UpdateColumns.module.css';

const UpdateResource = (props) => {
    const [nameInput, setNameInput] = useState(props.resource.name);
    const [urlInput, setUrlInput] = useState(props.resource.videoUrl);
    const [loading, setLoading] = useState(false);

    const nameInputChangeHandler = (event) => {
        setNameInput(event.target.value);
    };

    const urlInputChangeHandler = (event) => {
        setUrlInput(event.target.value);
    };

    const submitFormHandler = async (event) => {
        event.preventDefault();
        setLoading(true);
        const res = await updateResource(
            props.resource.id,
            nameInput,
            urlInput
        );
        if (res.code === 404) {
            alert(
                '해당 영상 자료를 찾을 수 없습니다. 새로고침 후 다시 시도해보세요.'
            );
            setLoading(false);
            return;
        } else if (res.code === 442) {
            alert('유효한 값을 입력해주세요.');
            setLoading(false);
            return;
        } else if (res.code === 500) {
            alert('알 수 없는 오류가 발생했습니다. 나중에 재시도해주세요.');
            setLoading(false);
            props.onClose();
            return;
        }

        props.onUpdate();
        alert('영상 자료를 수정하였습니다.');
        setLoading(false);
        props.onClose();
    };

    return (
        <Modal onClose={props.onClose}>
            <div className="adminModalHeader">
                <div>영상 자료 수정</div>
                <div onClick={props.onClose} className="adminModalCloseBtn">
                    <img src={CloseIcon} alt="" />
                </div>
            </div>
            <div className="adminModalContent">
                {loading && <Spinner />}
                <div className="adminModalInfo">
                    <img src={HelpIcon} alt="" />
                    도움말
                </div>
                <ul>
                    <li>
                        영상 자료의 <span className="emp1">이름 및 주소</span>를{' '}
                        <span className="emp1">수정</span>합니다.
                    </li>
                </ul>
                <div className="adminModalFormWrapper">
                    <form onSubmit={submitFormHandler}>
                        <FormInputArea className={styles['input-area']}>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="name"
                                    label="영상 이름"
                                    className="adminInputLabel"
                                >
                                    영상 이름
                                </FormLabel>
                                <FormInput
                                    type="text"
                                    name="name"
                                    onChange={nameInputChangeHandler}
                                    value={nameInput}
                                    required={true}
                                    className="adminInputText"
                                />
                            </div>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="videoUrl"
                                    label="영상 주소"
                                    className="adminInputLabel"
                                >
                                    영상 주소
                                </FormLabel>
                                <FormInput
                                    type="text"
                                    name="videoUrl"
                                    onChange={urlInputChangeHandler}
                                    value={urlInput}
                                    required={true}
                                    className="adminInputText"
                                />
                            </div>
                        </FormInputArea>
                        <button type="submit" className="adminBottomBtn">
                            수정
                        </button>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default UpdateResource;
