import styles from './ChartTitle.module.css';

const ChartTitle = (props) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <h1
                className={`${styles['chart-title']} ${
                    props.className && props.className
                }`}
            >
                {props.title}
            </h1>
        </div>
    );
};

export default ChartTitle;
