import React from 'react';

const FormSelect =
    // eslint-disable-next-line react/display-name
    React.forwardRef((props, ref) => {
        return (
            <select
                name={props.name}
                ref={ref}
                required={props.required}
                className={props.className}
                id={props.id}
                onChange={props.onChange}
            >
                {props.options.map((option, index) => {
                    return (
                        <option key={index} value={option.value}>
                            {option.name}
                        </option>
                    );
                })}
            </select>
        );
    });

export default FormSelect;
