import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllResourcesByUser } from '../../lib/resources';
import Button from '../UI/User/Button';
import GivenVideo from './GivenVideo';
import styled from 'styled-components';
import UserColors from '../../constants/colors';
import LoadingSpinner from '../UI/User/LoadingSpinner';
import ExerciseBasket from '../UI/User/ExerciseBasket';
import useNoResourceError from '../../hooks/useNoResourceError';
import { NoResourceError } from '../../constants/styled';

function SelectVideo() {
    const { noResourceError, onNoResourceError } = useNoResourceError();

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        setUserName(localStorage.getItem('userName'));
        const accessToken = localStorage.getItem('accessToken');
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        console.log('set authorization header');
        (async () => {
            try {
                console.log(userName);
                const varr = await getAllResourcesByUser();
                setTestVideos(varr);
                setVideoNum(varr.length);
            } catch (e) {
                console.log(e);
                onNoResourceError();
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        })();
    }, []);
    const [testVideos, setTestVideos] = useState([]);
    const [videos, setVideos] = useState([]);
    const [userName, setUserName] = useState('');
    const [videoNum, setVideoNum] = useState('');
    const [lastSlidePage, setLastSlidePage] = useState();
    const [noVideoError, setNoVideoError] = useState(false);

    const [indicator, setIndicator] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setLastSlidePage(Math.ceil(videoNum / 8));
    }, [videoNum]);

    const onToggle = (idx) => {
        console.log('toggles');
        console.log(idx);
        setVideos(
            videos.map((video, index) =>
                index === idx ? { ...video, active: !video.active } : video
            )
        );
    };

    // const RemoveVideo = (id) => {
    //     setVideos(videos.filter((video) => video.id !== id));
    // };
    const RemoveVideo = (idx) => {
        console.log(`삭제 : ${idx}`);
        setVideos(videos.filter((video, index) => index !== idx));
    };

    const AddVideo = (id) => {
        const test = testVideos.filter((video) => video.id === id)[0];
        setVideos(
            videos.concat({
                id: test.id,
                youtubeId: test.youtubeId,
                name: test.name,
            })
        );
        console.log(videos);
    };

    const navigate = useNavigate();
    function tmp() {
        navigate('/main/video');
    }

    const tmp2 = async () => {
        let failed;
        // ㄴconsole.log(axios.defaults.headers.Authorization);
        const res = await axios.get('/resources').catch((error) => {
            console.log(error);
            failed = error.response.data;
        });

        if (failed) {
            console.log('failed');
            return failed;
        }
        console.log(res.data);
    };

    const complete = (videos) => {
        navigate('/video', { state: { playlist: videos } });
    };

    const onListRefresh = () => {
        setVideos([]);
    };

    const syncIndicator = (slidePage) => {
        setIndicator(slidePage);
    };

    return (
        <>
            <Greet>
                {userName}님,
                <p>오늘은 어떤 운동을 해볼까요?</p>
            </Greet>
            <ResetButton onClick={onListRefresh}>선택 초기화</ResetButton>
            <ExerciseList>
                <LoadingSpinner isLoading={isLoading} />
                <GivenVideo
                    videos={testVideos}
                    selectedVideos={videos}
                    AddVideo={AddVideo}
                    onToggle={onToggle}
                    syncIndicator={syncIndicator}
                />
                <IndicatorWrapper>
                    {Array(lastSlidePage)
                        .fill(0)
                        .map((v, i) => (
                            <Indicator
                                key={i}
                                indicator={indicator}
                                index={i}
                            />
                        ))}
                </IndicatorWrapper>
                {noResourceError && (
                    <NoResourceError>영상 자료가 아직 없어요</NoResourceError>
                )}
            </ExerciseList>
            <TodayExerciseWrapper>
                <TodayExercise>오늘의 운동</TodayExercise>
                <HowManyExercise>
                    <ExerciseCount>{videos.length}</ExerciseCount>개의 운동
                    선택됨
                </HowManyExercise>
            </TodayExerciseWrapper>
            <ExerciseBasket
                videos={videos}
                RemoveVideo={RemoveVideo}
                onToggle={onToggle}
                isStatic={false}
            />
            <Button
                action={() => {
                    if (videos.length) complete(videos);
                }}
                color={!videos.length ? 'gray' : 'blue'}
                isActive={videos.length > 0}
                margin={'0 0 3.6rem 0'}
            >
                새로운 운동 시작하기
            </Button>
        </>
    );
}

const Greet = styled.div`
    color: ${UserColors.blue01};
    font-weight: 700;
    font-size: 2.4rem;
    margin-bottom: 1.4rem;

    & {
        p {
            font-size: 1.2rem;
            font-weight: 400;
            margin-top: 0.5rem;
        }
    }
`;

const ResetButton = styled.button`
    height: 2.3rem;
    border-radius: 30px;
    border: none;
    display: block;
    margin-left: auto;
    background-color: ${UserColors.gray01};
    margin-bottom: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    white-space: nowrap;
    padding: 0 0.8rem;
`;

const ExerciseList = styled.div`
    height: 26.5rem;
    border: 0.5px solid ${UserColors.gray02};
    border-radius: 20px;
    margin-bottom: 4.1rem;
    position: relative;
`;

const IndicatorWrapper = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 0.8rem;
    padding-bottom: 1rem;
`;

const Indicator = styled.div`
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: ${(props) =>
        props.indicator === props.index
            ? `${UserColors.blue01}`
            : `${UserColors.gray02}`};
`;

const TodayExerciseWrapper = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

const TodayExercise = styled.div`
    font-weight: 700;
    font-size: 2rem;
    color: ${UserColors.yellow01};
`;

const HowManyExercise = styled.div`
    font-size: 1rem;
    color: ${UserColors.black02};
`;

const ExerciseCount = styled.span`
    color: ${UserColors.blue01};
    font-size: 1rem;
`;

export default SelectVideo;
