import React, { useEffect, useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Link, useNavigate } from 'react-router-dom';
import { formatTimezoneWithTime } from '../../lib/formatTimezone';
import { BsFillPinAngleFill } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import { sendSMSNotice } from '../../lib/notices';
import Spinner from '../UI/Admin/Spinner';
import { dateFilterParams } from '../../lib/agGrid';
import styles from '../../pages/Admin/AdminNotices.module.css';

const NoticesTable = (props) => {
    const fixTopRenderer = (props) => (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <p>{props.data.title}</p>
            {props.data.fixTop && (
                <div style={{ height: '100%' }}>
                    <IconContext.Provider value={{ size: 15 }}>
                        <>
                            <BsFillPinAngleFill />
                        </>
                    </IconContext.Provider>
                </div>
            )}
        </div>
    );

    const resendMessage = async (noticeId) => {
        setLoading(true);
        const res = await sendSMSNotice(noticeId);
        if (res.code) {
            if (res.code === 404) {
                alert(
                    '해당 공지사항을 찾을 수 없습니다. 새로고침 후 재시도 해주세요.'
                );
                return;
            }
        }
        console.log(res);
        setLoading(false);
        alert('문자가 전송되었습니다.');
    };

    const sendSMSRenderer = (props) => (
        <button type="button" onClick={() => resendMessage(props.data.id)}>
            재전송
        </button>
    );

    const defaultColumnDefs = {
        resizable: true,
    };

    const initColumnDefs = useMemo(
        () => [
            {
                headerName: '#',
                suppressSizeToFit: true,
                width: 60,
                valueGetter: (params) => {
                    return params.node ? params.node.rowIndex + 1 : null;
                },
            },
            {
                field: 'title',
                headerName: '제목',
                sortable: true,
                filter: 'agTextColumnFilter',
                suppressSizeToFit: false,
                cellRenderer: !props.isScheduled && fixTopRenderer,
            },
            {
                field: 'isVisible',
                headerName: '게시 여부',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: [
                        'equals',
                        'contains',
                        'startsWith',
                        'endsWith',
                    ],
                    defaultOption: 'equals',
                },
                suppressSizeToFit: true,
                width: 120,
                valueFormatter: (params) => {
                    if (params.value) {
                        return '게시됨';
                    } else {
                        return '숨김';
                    }
                },
            },
            {
                field: 'createdAt',
                headerName: '작성일',
                sortable: true,
                filter: 'agDateColumnFilter',
                filterParams: dateFilterParams,
                suppressSizeToFit: true,
                width: 250,
            },
            {
                field: 'author',
                headerName: '작성자',
                sortable: true,
                filter: 'agTextColumnFilter',
                suppressSizeToFit: true,
                width: 100,
            },
            {
                headerName: '문자 재전송',
                width: 100,
                suppressSizeToFit: true,
                cellRenderer: sendSMSRenderer,
            },
        ],
        []
    );

    const additionalColumnDefs = [
        {
            field: 'reservedDatetime',
            headerName: '예약 일자',
            sortable: true,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressSizeToFit: true,
            width: 220,
            valueFormatter: (params) => {
                return formatTimezoneWithTime(params.value);
            },
        },
    ];

    const [loading, setLoading] = useState(false);
    const [columnDefs, setColumnDefs] = useState(initColumnDefs);
    const [rowData, setRowData] = useState([]);

    const resizeColumnsToFit = () => {
        props.gridRef.current.api.sizeColumnsToFit();
    };

    useEffect(() => {
        window.addEventListener('resize', resizeColumnsToFit);
        return () => {
            window.removeEventListener('resize', resizeColumnsToFit);
        };
    }, []);

    useEffect(() => {
        if (props.isScheduled) {
            console.log('예약 공지');
            const newColumnDefs = initColumnDefs
                .slice(0, 2)
                .concat(additionalColumnDefs)
                .concat(initColumnDefs.slice(3, 5));
            setColumnDefs(newColumnDefs);

            const data = props.noticesData.filter(
                (notice) =>
                    notice.reservedDatetime !== null &&
                    new Date(notice.reservedDatetime) > Date.now()
            );
            setRowData(data);
        } else {
            setColumnDefs(initColumnDefs);

            console.log('일반 공지');
            const data = props.noticesData.filter(
                (notice) =>
                    notice.reservedDatetime === null ||
                    new Date(notice.reservedDatetime) < Date.now()
            );
            setRowData(data);
        }
    }, [props.isScheduled, props.noticesData]);

    const containerStyle = useMemo(
        () => ({ width: '100%', height: '85%' }),
        []
    );
    const gridStyle = useMemo(
        () => ({ width: '95%', margin: 'auto', height: '100%' }),
        []
    );

    const onGridReady = (params) => {
        props.onGridReady(params.api);
        resizeColumnsToFit();
    };

    const navigate = useNavigate();
    const onRowDoubleClicked = (event) => {
        navigate(`./${event.data.id}`, { state: { ...event.data } });
    };

    return (
        <div style={containerStyle}>
            <div className="ag-theme-alpine" style={gridStyle}>
                {loading && <Spinner />}
                <AgGridReact
                    ref={props.gridRef}
                    // domLayout={'autoHeight'}
                    pagination={true}
                    paginationAutoPageSize={true}
                    // paginationPageSize={15}
                    pivotPanelShow={'always'}
                    animateRows={true}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColumnDefs}
                    onGridReady={onGridReady}
                    overlayLoadingTemplate={
                        '<span class="ag-overlay-loading-center">데이터 가져오는 중...</span>'
                    }
                    onRowDoubleClicked={onRowDoubleClicked}
                    // onRowClicked={onRowClicked}
                    localeText={{ noRowsToShow: '조회 결과가 없습니다.' }}
                ></AgGridReact>
            </div>
            <div className={styles.write}>
                <button>
                    <Link to="/admin/notices/new">글쓰기</Link>
                </button>
            </div>
        </div>
    );
};

export default NoticesTable;
