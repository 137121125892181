import Modal from '../UI/Admin/Modal';
import CloseIcon from '../../assets/close.svg';
import HelpIcon from '../../assets/help.svg';
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NoticeReservationForm from './NoticeReservationForm';
import FormInputArea from '../UI/Admin/FormInputArea';
import FormInput from '../UI/Admin/FormInput';
import FormLabel from '../UI/Admin/FormLabel';
import { updateNoticeSchedule } from '../../lib/notices';
import Spinner from '../UI/Admin/Spinner';
import 'moment/locale/ko';

const UpdateNoticeReservation = (props) => {
    const sendSMSRef = useRef();
    const reservedDateRef = useRef();
    const reservedTimeRef = useRef();
    const [loading, setLoading] = useState(false);

    const { noticeId } = useParams();

    const submitFormHandler = async (event) => {
        event.preventDefault();
        const inputObj = { sendSMS: sendSMSRef.current.checked };

        inputObj.reservedDatetime = `${reservedDateRef.current.value} ${reservedTimeRef.current.value}`;

        setLoading(true);
        const res = await updateNoticeSchedule(
            props.notice.id || noticeId,
            inputObj
        );
        if (res.code) {
            if (res.code === 404) {
                alert(
                    '해당 공지사항을 찾을 수 없습니다. 새로고침 후 재시도 해주세요.'
                );
                setLoading(false);
                return;
            } else if (res.code === 442) {
                alert('입력값이 올바르지 않습니다.');
                setLoading(false);
                return;
            } else if (res.code === 500) {
                alert('알 수 없는 오류가 발생했습니다. 나중에 재시도해주세요.');
                setLoading(false);
                props.onClose();
                return;
            }
        }

        res.notice.author = res.notice.author.affiliation;
        setLoading(false);
        alert('수정되었습니다');
        props.onSave(res.notice);
        props.onClose();
    };

    return (
        <Modal onClose={props.onClose}>
            <div className="adminModalHeader">
                <div>예약 일정 변경</div>
                <div onClick={props.onClose} className="adminModalCloseBtn">
                    <img src={CloseIcon} alt="" />
                </div>
            </div>
            <div className="adminModalContent">
                {loading && <Spinner />}
                <div className="adminModalInfo">
                    <img src={HelpIcon} alt="" />
                    도움말
                </div>
                <ul>
                    <li>
                        <span className="emp1">예약 일정</span>을{' '}
                        <span className="emp1">변경</span>합니다.
                    </li>
                    <li>
                        하단의 <span className="emp2">문자 전송 체크 박스</span>
                        에 체크하시면,
                        <br />
                        <span className="emp1">새로운 공지사항</span>이
                        게시되었다는{' '}
                        <span className="emp2">알림 문자도 함께 전송</span>
                        됩니다.
                    </li>
                </ul>
                <div className="adminModalFormWrapper">
                    <form onSubmit={submitFormHandler}>
                        <FormInputArea>
                            <NoticeReservationForm
                                reservedDateRef={reservedDateRef}
                                reservedTimeRef={reservedTimeRef}
                            />
                            <div className="adminCheckRowWrapper">
                                <FormLabel
                                    for="sendSMS"
                                    className="adminInputLabel adminCheckLabel"
                                >
                                    문자 전송
                                </FormLabel>
                                <FormInput
                                    name="sendSMS"
                                    type="checkbox"
                                    id="sendSMS"
                                    ref={sendSMSRef}
                                    className="adminInputCheck"
                                />
                            </div>
                        </FormInputArea>
                        <button type="submit" className="adminBottomBtn">
                            수정
                        </button>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default UpdateNoticeReservation;
