import React from 'react';
import ResourcesTable from '../../components/Admin/ResourcesTable';
import styles from './AdminResources.module.css';

const AdminResources = () => {
    return (
        <div className={styles.resources}>
            <ResourcesTable />
        </div>
    );
};

export default AdminResources;
