import React, { useMemo, useRef, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import millisecondToMinute from '../../lib/millisecondToMinute';
import Help from '../../components/UI/Admin/Help';
import { downloadActivitiesData, getAllActivities } from '../../lib/activities';
import { dateFilterParams } from '../../lib/agGrid';

import styles from './AdminActivities.module.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../components/Admin/ag-grid.css';

const AdminActivities = () => {
    const [gridApi, setGridApi] = useState(null);

    const resizeColumnsToFit = () => {
        gridRef.current.api.sizeColumnsToFit();
    };

    useEffect(() => {
        console.log('use');
        localStorage.removeItem('latest');
        window.addEventListener('resize', resizeColumnsToFit);
        return () => {
            window.removeEventListener('resize', resizeColumnsToFit);
        };
    }, []);

    const defaultColumnDefs = {
        resizable: true,
        floatingFilter: true,
    };

    const columnDefs = useMemo(
        () => [
            {
                field: 'username',
                headerName: 'UserId',
                sortable: true,
                pinned: 'left',
                suppressSizeToFit: true,
                width: 80,
            },
            {
                field: 'name',
                headerName: 'Name',
                filter: 'agTextColumnFilter',
                pinned: 'left',
                suppressSizeToFit: true,
                width: 150,
            },
            {
                field: 'viewDatetime',
                headerName: '시청 일시',
                sortable: true,
                filter: 'agDateColumnFilter',
                width: 200,
                suppressSizeToFit: true,
                filterParams: dateFilterParams,
            },
            {
                field: 'videoResource',
                headerName: '시청한 영상',
                sortable: false,
                filter: 'agTextColumnFilter',
            },
            {
                field: 'totalDuration',
                headerName: '시청 시간 (분)',
                sortable: true,
                filter: 'agNumberColumnFilter',
                width: 150,
                suppressSizeToFit: true,
                valueFormatter: (params) => {
                    return millisecondToMinute(params.value);
                },
            },
            {
                field: 'status',
                headerName: '시청 상태',
                sortable: true,
                filter: 'agTextColumnFilter',
                suppressSizeToFit: true,
                width: 150,
            },
        ],
        []
    );

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '95%', height: '92%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const datasource = {
        async getRows(params) {
            console.log(JSON.stringify(params, null, 1));
            const latestId = localStorage.getItem('latest');
            gridRef.current.api.showLoadingOverlay();
            const res = await getAllActivities(100, latestId || null);
            console.log(res);
            if (res.code) {
                if (res.code === 500) {
                    alert(
                        '알 수 없는 오류가 발생했습니다. 나중에 재시도해주세요.'
                    );
                }
            }
            gridRef.current.api.hideOverlay();
            if (res.length === 0) {
                gridRef.current.api.showNoRowsOverlay();
                // gridRef.current.api.setRowCount(0, 0);
                // params.failCallback();
                params.successCallback(0, 0);
                // return null;
            } else if (res.length < 100) {
                params.successCallback(res, res.length);
            } else {
                params.successCallback(res);
                localStorage.setItem('latest', res.at(-1).id);
            }
        },
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        resizeColumnsToFit();
        params.api.setDatasource(datasource);
    };

    const downloadActivitiesHandler = async () => {
        const res = await downloadActivitiesData();
        if (res.code) {
            console.log(res);
        }
    };

    const onRowClicked = (params) => {
        console.log(params);
    };

    return (
        <div className={styles.activities}>
            <div className={styles['download-button']}>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Help
                        top={'0.2rem'}
                        right={'0rem'}
                        isRelative={true}
                        header={'시청 상태'}
                        size={'80%'}
                        width={'20rem'}
                        position={'right bottom'}
                        display={'inline-block'}
                        style={{ verticalAlign: 'middle' }}
                    >
                        <ul>
                            <li>
                                <strong>COMPLETE</strong>: 선택한 영상을 모두
                                시청하고 '완료하기' 버튼까지 누른 상태
                            </li>
                            <li>
                                <strong>INCOMPLETE</strong>: 선택한 영상을 모두
                                시청했다고 기록되었으나, 유저가 '완료하기'
                                버튼을 일정 시간 동안 누르지 않은 상태
                            </li>
                            <li>
                                <strong>ABORTED</strong>: 영상을 시청하다가
                                뒤로가기 버튼을 눌러 영상을 끝까지 시청하지 않고
                                취소한 상태
                            </li>
                            <li>
                                <strong>빈 값</strong>: 유저가 영상 시청 도중
                                앱을 종료하는 등의 문제로 인해 상태가 기록되지
                                못한 상태
                            </li>
                        </ul>
                    </Help>
                    <div>
                        <p style={{ marginLeft: '0.2rem' }}>
                            시청 상태별 도움말
                        </p>
                    </div>
                </div>
                <button type="button" onClick={downloadActivitiesHandler}>
                    시청 내역 다운로드
                </button>
            </div>
            <div style={containerStyle}>
                <div className="ag-theme-alpine" style={gridStyle}>
                    <AgGridReact
                        ref={gridRef}
                        animateRows={true}
                        rowModelType="infinite"
                        defaultColDef={defaultColumnDefs}
                        onGridReady={onGridReady}
                        columnDefs={columnDefs}
                        localeText={{ noRowsToShow: '조회 결과가 없습니다.' }}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">데이터 가져오는 중...</span>'
                        }
                        // overlayNoRowsTemplate={
                        //     '<span style="height: 73%; width: 100%; background-color: white">This is a custom \'no rows\' overlay</span>'
                        // }
                        onRowClicked={onRowClicked}
                        infiniteInitialRowCount={0}
                    ></AgGridReact>
                </div>
            </div>
        </div>
    );
};

export default AdminActivities;
