import axiosInstance from './axiosInstance';

const getAttributes = async () => {
    let failed;
    const res = await axiosInstance.get('/attributes').catch((error) => {
        console.log(error.response.data);
        failed = error.response.data;
    });

    if (failed) {
        return failed;
    }

    return res.data;
};

const addAttribute = async (name, isNumber) => {
    let failed;
    const res = await axiosInstance
        .post('/attributes', { name, isNumber })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const deleteAttribute = async (attributeId) => {
    let failed;
    const res = await axiosInstance
        .delete(`/attributes/${attributeId}`)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const updateAttribute = async (attributeId, name, isNumber) => {
    let failed;
    const res = await axiosInstance
        .patch(`/attributes/${attributeId}`, { name, isNumber })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const getAttribute = async (attributeId) => {
    let failed;
    const res = await axiosInstance
        .get(`/attributes/${attributeId}`)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

export {
    getAttribute,
    addAttribute,
    deleteAttribute,
    updateAttribute,
    getAttributes,
};
