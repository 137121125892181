import styled from 'styled-components';
import UserColors from '../../constants/colors';
import { memo, useState, useEffect } from 'react';

// eslint-disable-next-line react/display-name
const GivenVideoItem = memo(function ({
    video,
    AddVideo,
    onToggle,
    selectedVideos,
}) {
    // console.log(video);
    const { name, id } = video;
    const addButtonSvg = (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                stroke="#D2D2D2"
            />
            <path
                d="M11 7V11M11 11V15M11 11H15M11 11H7"
                stroke="#D2D2D2"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );

    const [selected, setSelected] = useState(false);

    useEffect(() => {
        let find = 0;
        for (const v of selectedVideos) {
            if (v.id === video.id) {
                setSelected(true);
                ++find;
                break;
            }
        }
        if (find === 0) setSelected(false);
    }, [selectedVideos]);

    // console.log(name);
    return (
        <ExerciseItem selected={selected}>
            <ExerciseTitle onClick={() => onToggle(id)}>{name}</ExerciseTitle>
            <AddButton onClick={() => AddVideo(id)}>{addButtonSvg}</AddButton>
        </ExerciseItem>
    );
});

const ExerciseItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 4.5rem;
    border-radius: 10px;
    background-color: ${UserColors.gray01};
    padding-left: 1.6rem;
    padding-right: 1rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    background-color: ${(props) =>
        props.selected ? `${UserColors.blue02}` : ''};
    border: ${(props) =>
        props.selected ? `1px solid ${UserColors.blue01}` : ''};
    padding-left: ${(props) => (props.selected ? '1.5rem' : '')};

    & {
        svg path:first-child {
            stroke: ${(props) =>
                props.selected ? `${UserColors.white01}` : ''};
            fill: ${(props) => (props.selected ? `${UserColors.blue01}` : '')};
        }
        svg path:last-child {
            stroke: ${(props) =>
                props.selected ? `${UserColors.white01}` : ''};
        }
        div {
            color: ${(props) => (props.selected ? `${UserColors.blue01}` : '')};
        }
    }
`;

const ExerciseTitle = styled.div`
    font-weight: 700;
    font-size: 1.3rem;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const AddButton = styled.button`
    all: unset;
    position: relative;
    top: 2px;
    cursor: pointer;
`;

export default GivenVideoItem;
