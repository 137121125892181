import { useState, useEffect } from 'react';
import styled from 'styled-components';
import UserColors from '../../constants/colors';
import LoadingDots from '../UI/User/LoadingDots';

function VideoDIsplayPlaylistItem({
    video,
    // isComplete,
    kval,
    curNum,
}) {
    console.log(video);
    const { content, id, createdAt } = video;
    const [isComplete, setIsComplete] = useState(false);
    const [isCurrent, setIsCurrent] = useState(false);

    const playCheckSvg = (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                fill="#547AFF"
                stroke="white"
            />
            <path
                d="M7 11.5L10 14.5L15 8.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    useEffect(() => {
        if (curNum > kval) setIsComplete(true);
        if (curNum === kval) setIsCurrent(true);
        else setIsCurrent(false);
    }, [curNum]);

    return (
        <PlayItemWrapper isComplete={isComplete} isCurrent={isCurrent}>
            <PlayItem isCurrent={isCurrent}>{video}</PlayItem>
            <SvgWrapper>
                {playCheckSvg}
                {isCurrent ? <LoadingDots isLoading={true} /> : null}
            </SvgWrapper>
        </PlayItemWrapper>
    );
}

const PlayItemWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    margin: 0.1rem;

    & {
        svg {
            position: relative;
            top: -0.15rem;
        }

        svg > path:first-child {
            fill: ${(props) =>
                props.isComplete ? '' : `${UserColors.white01}`};
            fill: ${(props) => (props.isCurrent ? `${UserColors.blue01}` : '')};
            stroke: ${(props) =>
                props.isComplete ? '' : `${UserColors.gray03}`};
            stroke: ${(props) =>
                props.isCurrent ? `${UserColors.white01}` : ''};
        }

        svg > path:last-child {
            stroke: ${(props) =>
                props.isCurrent ? `${UserColors.blue01}` : ''};
        }
    }
`;

const PlayItem = styled.div`
    width: 70%;
    height: 5rem;
    border-radius: 10px;
    background-color: ${UserColors.blue02};
    padding: 1.5rem 1.3rem;
    font-weight: 700;
    font-size: 1.3rem;
    color: ${UserColors.blue01};
    display: flex;
    align-items: center;
    border: ${(props) =>
        props.isCurrent ? `0.15rem solid ${UserColors.blue01}` : ''};
    padding-left: ${(props) => (props.isCurrent ? '1.15rem' : '')};
`;

const SvgWrapper = styled.div`
    position: relative;
`;

export default VideoDIsplayPlaylistItem;
