import React, { Fragment } from 'react';
import FormInput from '../UI/Admin/FormInput';
import FormLabel from '../UI/Admin/FormLabel';

const NoticeReservationForm = (props) => {
    return (
        <Fragment>
            <div className="adminInputRowWrapper">
                <FormLabel for="reservedDate" className="adminInputLabel">
                    날짜
                </FormLabel>
                <FormInput
                    name="reservedDate"
                    type="date"
                    id="reservedDate"
                    ref={props.reservedDateRef}
                    className="adminInputText"
                />
            </div>
            <div className="adminInputRowWrapper">
                <FormLabel for="reservedTime" className="adminInputLabel">
                    시간
                </FormLabel>
                <FormInput
                    name="reservedTime"
                    type="time"
                    id="reservedTime"
                    ref={props.reservedTimeRef}
                    className="adminInputText"
                />
            </div>
        </Fragment>
    );
};

export default NoticeReservationForm;
