export default class UserColors {
    static white01 = '#FFFFFF';
    static black01 = '#515151';
    static black02 = '#B6B6B6';
    static gray01 = '#F3F3F3';
    static gray02 = '#DBDBDB';
    static gray03 = '#D2D2D2';
    static yellow01 = '#FFBF36';
    static yellow02 = '#FFE24A';
    static yellow03 = '#';
    static blue01 = '#547AFF';
    static blue02 = '#EEF6FF';
    static blue03 = '#E1F0FF';
}

export class AdminColors {
    static navy01 = 'rgb(12, 19, 28)';
    static blue01 = 'rgba(118, 171, 255, 0.8)';
    static blue02 = 'rgb(118, 171, 255)';
    static yellow01 = '#fcd544';
}
