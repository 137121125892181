import axiosInstance from './axiosInstance';

const todayStat = async () => {
    let failed;
    const res = await axiosInstance.get('/activities/today').catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }
    return res.data;
};

const statusLineChartData = async () => {
    let failed;
    const res = await axiosInstance.get('/activities/status').catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }
    return res.data;
};

const calendarHeatmapData = async () => {
    let failed;
    const res = await axiosInstance
        .get('activities/attendance')
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const averageDurationBoxPlotData = async () => {
    let failed;
    const res = await axiosInstance
        .get('activities/average-duration')
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const viewCountBarChartData = async () => {
    let failed;
    const res = await axiosInstance
        .get('viewed-videos/view-count')
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

export {
    todayStat,
    statusLineChartData,
    calendarHeatmapData,
    averageDurationBoxPlotData,
    viewCountBarChartData,
};
