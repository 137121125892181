import React from 'react';
import styled from 'styled-components';
import UserColors from '../../../constants/colors';

const LoadingDots = ({ transformHeight, size, color, gap, isLoading }) => {
    return (
        <DotsWrapper
            transformHeight={transformHeight}
            gap={gap}
            isLoading={isLoading}
        >
            <Dots size={size} color={color} />
            <Dots size={size} color={color} />
            <Dots size={size} color={color} />
        </DotsWrapper>
    );
};

const DotsWrapper = styled.div`
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.8s ease;
    opacity: ${(props) => (props.isLoading ? 1 : 0)};
    visibility: ${(props) => (props.isLoading ? 'visible' : 'hidden')};
    transform: ${(props) =>
        props.transformHeight
            ? `translate(-50%, ${props.transformHeight})`
            : 'translate(-50%, -150%)'};
    gap: ${(props) => (props.gap ? props.gap : '0.1rem')};
`;

const Dots = styled.div`
    border-radius: 50%;
    animation: 0.9s loading infinite;
    opacity: 1;
    width: ${(props) => (props.size ? props.size : '0.3rem')};
    height: ${(props) => (props.size ? props.size : '0.3rem')};
    background-color: ${(props) =>
        props.color ? props.color : `${UserColors.white01}`};

    &:first-child {
        animation-delay: 0.3s;
    }

    &:nth-child(2) {
        animation-delay: 0.6s;
    }

    &:last-child {
        animation-delay: 0.9s;
    }

    @keyframes loading {
        0% {
            opacity: 1;
            transform: scale(110%, 110%);
        }
        50% {
            opacity: 0.5;
            transform: scale(70%, 70%);
        }
        100% {
            opacity: 1;
            transform: scale(110%, 110%);
        }
    }
`;

export default LoadingDots;
