import styled from 'styled-components';
import UserColors from '../../constants/colors';
import SelectCancelIcon from '../../assets/select-cancel.svg';
import { useEffect, useState } from 'react';

function SelectedVideoItem({ video, RemoveVideo, onToggle, kval, isStatic }) {
    const [name, setName] = useState('');
    const [id, setId] = useState('');

    // RemoveVideo로 인해 video 배열이 바뀌는 것에 대응해야하므로 재렌더링 시 항상 실행
    useEffect(() => {
        console.log(`isStatic : ${isStatic}`);
        if (!isStatic) {
            setName(video.name);
            setId(video.id);
        } else setName(video);
    });

    return (
        <SelectedVideo
            isStatic={isStatic}
            onClick={() => {
                if (!isStatic) RemoveVideo(kval);
            }}
        >
            <div
                onClick={() => {
                    if (!isStatic) onToggle(id);
                }}
            >
                {name}
            </div>
            {!isStatic ? (
                <button>
                    <img src={SelectCancelIcon} alt="SelectCancelIcon" />
                </button>
            ) : null}
        </SelectedVideo>
    );
}

const SelectedVideo = styled.div`
    background-color: ${UserColors.blue02};
    border-radius: 30px;
    height: 2.3rem;
    display: flex;
    align-items: center;
    cursor: ${(props) => (props.isStatic ? '' : 'pointer')};
    overflow-x: hidden;
    max-width: 100%;
    width: 100%;
    justify-content: ${(props) =>
        props.isStatic ? 'center' : 'space-between'};

    & {
        div {
            font-size: 1rem;
            font-weight: 400;
            color: ${UserColors.blue01};
            position: relative;
            overflow-x: hidden;
            text-overflow: ellipsis;
            max-width: ${(props) => (props.isStatic ? '75%' : '70%')};
            left: ${(props) => (props.isStatic ? '0' : '0.9rem')};
        }

        button {
            display: block;
            border: none;
            background-color: ${UserColors.blue02};
            position: relative;
            right: 0.2rem;
            cursor: pointer;
        }
    }
`;

export default SelectedVideoItem;
