import { formatTimezoneWithTime } from './formatTimezone';
import axiosInstance from './axiosInstance';
import axios from 'axios';

const getUserActivities = async (userId) => {
    let failed;
    const res = await axiosInstance
        .get(`/activities/${userId}`)
        .catch((error) => {
            console.log(error.response.data);
            failed = error.response.data;
        });

    if (failed) {
        return failed;
    }

    const data = res.data;

    const activities = data.map((a) => {
        let videoResources = '';
        a.viewedVideos.forEach((video) => {
            if (videoResources === '') {
                videoResources = video.video.name;
            } else {
                videoResources = videoResources + ', ' + video.video.name;
            }
        });
        return {
            user: a.user,
            viewDatetime: formatTimezoneWithTime(a.createdAt),
            videoResource: videoResources,
            totalDuration: a.totalDuration,
            status: a.status,
        };
    });
    return activities;
};

const getAllActivities = async (size, lastId) => {
    let url;
    console.log('request from', lastId);
    if (lastId === null || lastId === undefined) {
        url = `/activities?size=${size}`;
    } else {
        url = `/activities?size=${size}&lastId=${lastId}`;
    }
    const res = await axiosInstance.get(url);

    const data = res.data;
    console.log(data);
    if (!data) {
        return [];
    }

    const validData = data.filter((v) => v.user !== null);

    const activities = validData.map((a) => {
        let videoResources = '';
        a.viewedVideos.forEach((video) => {
            if (videoResources === '') {
                videoResources = video.video.name;
            } else {
                videoResources = videoResources + ', ' + video.video.name;
            }
        });
        return {
            id: a.id,
            username: a.user.username,
            name: a.user.name,
            viewDatetime: formatTimezoneWithTime(a.createdAt),
            videoResource: videoResources,
            totalDuration: a.totalDuration,
            status: a.status,
        };
    });
    return activities;
};

const addActivity = async (selectedVideos) => {
    let failed;
    // const res = await axiosInstance.post('/auth/user-login', body).catch((error) => {
    //     failed = error.response.data;
    // });
    console.log('여기다');
    console.log(selectedVideos);
    const res = await axiosInstance
        .post('/activities', { selectedVideos })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const addActivityByUser = async (selectedVideos) => {
    let failed;
    // const res = await axiosInstance.post('/auth/user-login', body).catch((error) => {
    //     failed = error.response.data;
    // });
    console.log('여기다');
    console.log(selectedVideos);
    const res = await axios
        .post('/activities', { selectedVideos })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const addViewedVideo = async (activity, video, duration, isEnd = false) => {
    let failed;
    const res = await axiosInstance
        .post('/viewed-videos', { activity, video, duration, isEnd })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const addViewedVideoByUser = async (
    activity,
    video,
    duration,
    isEnd = false
) => {
    let failed;
    const res = await axios
        .post('/viewed-videos', { activity, video, duration, isEnd })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const updateActivityStatus = async (activityId, status) => {
    let failed;
    const res = await axiosInstance
        .patch(`/activities/${activityId}`, { status })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const updateActivityStatusByUser = async (activityId, status) => {
    let failed;
    const res = await axios
        .patch(`/activities/${activityId}`, { status })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const downloadActivitiesData = async () => {
    let failed;
    const res = await axiosInstance
        .get('/activities/download', { responseType: 'blob' })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    const name = res.headers['content-disposition']
        .split('filename=')[1]
        .replace(/"/g, '');
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    link.style.cssText = 'display:none';
    document.body.appendChild(link);
    link.click();
    link.remove();

    return res.data;
};

export {
    getUserActivities,
    getAllActivities,
    addActivity,
    addActivityByUser,
    addViewedVideo,
    addViewedVideoByUser,
    updateActivityStatus,
    updateActivityStatusByUser,
    downloadActivitiesData,
};
