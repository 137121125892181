import React from 'react';
import styled from 'styled-components';
import SelectedVideo from '../../User/SelectedVideo';
import UserColors from '../../../constants/colors';

const ExerciseBasket = ({ videos, RemoveVideo, onToggle, isStatic }) => {
    return (
        <ExerciseBasketWrapper>
            <SelectedVideo
                videos={videos}
                RemoveVideo={RemoveVideo}
                onToggle={onToggle}
                isStatic={isStatic}
            />
            {!videos.length && !isStatic ? (
                <Placeholder>
                    오늘의 운동을 선택해주세요!
                    <br />
                    중복 선택도 가능하답니다
                </Placeholder>
            ) : (
                ''
            )}
        </ExerciseBasketWrapper>
    );
};

const ExerciseBasketWrapper = styled.div`
    border-radius: 15px;
    border: 0.5px solid ${UserColors.gray02};
    margin-bottom: 3.8rem;
    padding: 1.5rem;
    height: 8.2rem;
`;

const Placeholder = styled.div`
    text-align: center;
    position: relative;
    top: 0.6rem;
    z-index: -1;
    color: ${UserColors.gray02};
    font-size: 1.1rem;
    line-height: 1.4rem;
`;

export default ExerciseBasket;
