import { createAction, handleActions } from 'redux-actions';

/**
 * Action
 */
const SET_NOTICES = 'notices/SET_NOTICES';

export const setNotices = createAction(SET_NOTICES, (notices) => ({ notices }));

/**
 * Reducer
 */

const initialState = [];

const notices = handleActions(
    {
        [SET_NOTICES]: (state, { payload }) => payload.notices,
    },
    initialState
);

export default notices;
