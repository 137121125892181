import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import styles from './Sidebar.module.css';
// import logoImage from '../../assets/logo-simple-circle.png';
import mark from '../../assets/mark.svg';
import logout from '../../assets/logout.svg';
import React, { useContext } from 'react';
import AuthContext from '../../store/AuthContext';
import axiosInstance from '../../lib/axiosInstance';

function Sidebar() {
    const location = useLocation();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const menuItems = [
        {
            name: '피험자 통계',
            path: '/admin/dashboard',
        },
        {
            name: '피험자 관리',
            path: '/admin/users',
        },
        {
            name: '영상 관리',
            path: '/admin/resources',
        },
        {
            name: '영상 시청 기록',
            path: '/admin/activities',
        },
        {
            name: '공지사항 관리',
            path: '/admin/notices',
        },
        {
            name: '메시지 관리',
            path: '/admin/messages',
        },
    ];

    let navStyles = styles.sidebar;
    if (location.pathname === '/admin/login') {
        navStyles = `${styles.sidebar} ${styles.hide}`;
    }

    const logoutHandler = async () => {
        const res = await axiosInstance
            .post('/auth/logout', {})
            .catch((error) => {
                console.log(error);
            });
        authContext.onLogout();
        navigate('/admin/login', { replace: true });
    };

    return (
        <div className={navStyles}>
            <div className={styles.logo}>
                ICAAN
                <img src={mark} alt="" />
            </div>
            <div className={styles.items}>
                {menuItems.map((item) => {
                    return (
                        <NavLink
                            to={item.path}
                            key={item.name}
                            className={
                                location.pathname === item.path && styles.active
                            }
                        >
                            <div className={styles.item}>{item.name}</div>
                        </NavLink>
                    );
                })}
            </div>

            <div className={styles.logout} onClick={logoutHandler}>
                <img src={logout} alt="" />
                <div>Logout</div>
            </div>
        </div>
    );
}

export default Sidebar;
