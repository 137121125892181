export const CompleteMessages = {
    1: '영상을 보고 잘 따라 하셨나요?\n내일 또 만나요^^',
    2: '수고하셨습니다.\n땀 흘린 만큼 보람이 있을 거예요!',
    3: '오늘따라 하기 어려운 동작이 있었다면,\n차근차근 따로 연습해 보세요~',
    4: '수고하셨습니다.\n숨이 차고 힘든 만큼 열심히 운동한 거예요.\n파이팅!',
    5: '올바른 자세로\n정확하게 동작을 반복하는 것이\n가장 중요해요!',
    6: '수고하셨습니다! 👏',
    7: '최고예요! 👍',
    8: '참 잘했어요! 🥳',
};
