import { Outlet } from 'react-router-dom';
import styles from './UserMain.module.css';
import styled from 'styled-components';

function UserMain({ fullScreen }) {
    return (
        <MainWrapper fullScreen={fullScreen}>
            <Outlet />
        </MainWrapper>
    );
}

const MainWrapper = styled.div`
    padding: 3rem 1.6rem 6.5rem 1.6rem;
    padding: ${(props) => (props.fullScreen ? 0 : '')};
`;

export default UserMain;
