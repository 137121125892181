import { useEffect, useState } from 'react';
import UserNotice from '../../components/User/UserNotice';
import styled from 'styled-components';
import UserColors from '../../constants/colors';
import LoadingSpinner from '../../components/UI/User/LoadingSpinner';
import NoNoticeIcon from '../../assets/no-notice.svg';
import Placeholder from '../../components/UI/User/Placeholder';
import useNotices from '../../hooks/useNotices';

function UserNotices() {
    const { notices } = useNotices();
    const [visibleNotices, setVisibleNotices] = useState([]);
    const [noticeRead, setNoticeRead] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (notices.length) setIsLoading(false);
        else setIsLoading(true);
        setVisibleNotices(notices.filter((notice) => notice.isVisible));
    }, [notices]);

    useEffect(() => {
        const localStorage = window.localStorage;
        const NoticeRead = JSON.parse(localStorage.getItem('NoticeRead')) || [];
        const ValidNoticeRead = NoticeRead.filter((read) =>
            visibleNotices.map((notice) => notice.id).includes(read)
        );
        setNoticeRead([...ValidNoticeRead]);
    }, [notices, visibleNotices]);

    return (
        <NoticeContainer>
            <LoadingSpinner isLoading={isLoading} isNotice={true} />
            <NoticeTitleWrapper>
                <NoticeTitle>공지사항</NoticeTitle>
                <NoticeNotRead>
                    {visibleNotices.length &&
                    visibleNotices.length !== noticeRead.length ? (
                        <>
                            <Num>
                                {visibleNotices.length - noticeRead.length}개
                            </Num>
                            의 공지사항을 읽지 않았어요.
                        </>
                    ) : visibleNotices.length ? (
                        <AllRead>공지사항을 모두 읽었어요.</AllRead>
                    ) : null}
                </NoticeNotRead>
            </NoticeTitleWrapper>
            {visibleNotices.length ? (
                <UserNotice notices={visibleNotices} noticeRead={noticeRead} />
            ) : !isLoading ? (
                <Placeholder
                    icon={NoNoticeIcon}
                    title={'공지사항이 등록되지 않았어요'}
                />
            ) : null}
        </NoticeContainer>
    );
}

const NoticeContainer = styled.div`
    margin: 0 1.5rem;
    margin-bottom: 7.8rem;
`;

const NoticeTitleWrapper = styled.div`
    margin: 2.8rem 0.1rem 2.4rem 0.1rem;
`;

const NoticeTitle = styled.div`
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 0.4rem;
`;

const NoticeNotRead = styled.div`
    font-size: 1.2rem;
`;

const Num = styled.span`
    font-size: 1.3rem;
    font-weight: 700;
    color: ${UserColors.blue01};
`;

const AllRead = styled.span`
    color: ${UserColors.black02};
    font-size: inherit;
`;

export default UserNotices;
