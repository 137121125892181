import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import BeatLoader from 'react-spinners/BeatLoader';
import { getUsersData } from '../../lib/users';
import { createMessage, getMessages } from '../../lib/messages';
import MessageItem from '../../components/Admin/MessageItem';
import moment from 'moment';
import FormInput from '../../components/UI/Admin/FormInput';
import FormLabel from '../../components/UI/Admin/FormLabel';
import styles from './AdminMessages.module.css';
import { Urls } from '../../constants/urls';

const AdminMessages = () => {
    const [userList, setUserList] = useState([]);
    const [isSingleMessage, setIsSingleMessage] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [moreThanTwo, setMoreThanTwo] = useState(false);
    const [selectedUserString, setSelectedUserString] = useState('');
    const [inputObj, setInputObj] = useState({
        recipients: [],
        content: null,
        redirectUrl: null,
        sendSMS: false,
    });
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const messagesContainerRef = useRef();

    const location = useLocation();
    useEffect(() => {
        const getUserMessages = async () => {
            if (location.state !== undefined && location.state !== null) {
                console.log('user page 에서');
                const recipientsFromUserPage = location.state.recipients;
                console.log(recipientsFromUserPage);
                setSelectedUsers(recipientsFromUserPage);
                if (recipientsFromUserPage.length >= 2) {
                    setIsSingleMessage(false);
                    setMoreThanTwo(true);
                    setInputObj({
                        ...inputObj,
                        recipients: recipientsFromUserPage.map(
                            (user) => user.id
                        ),
                    });
                } else {
                    setInputObj({
                        ...inputObj,
                        recipients: recipientsFromUserPage[0].id,
                    });
                    setLoading(true);
                    const data = await getMessages(
                        recipientsFromUserPage[0].id
                    );

                    setLoading(false);
                    if (data.code) {
                        return;
                    }
                    setSelectedUserString(recipientsFromUserPage[0].name);
                    setMessages(data);
                }
            }
        };
        getUserMessages();
    }, []);

    const [gridApi, setGridApi] = useState(null);
    useEffect(() => {
        if (gridApi) {
            gridRef.current.api.showLoadingOverlay();
            const getUsers = async () => {
                const data = await getUsersData();
                if (data.code) {
                    return;
                }
                if (data.cause !== 204) {
                    setUserList(data);
                } else {
                    gridRef.current.api.showNoRowsOverlay();
                }
            };
            getUsers();
        }
    }, [gridApi]);

    const inputObjChangeHandler = (event) => {
        const { name } = event.target;
        if (name === 'sendSMS') {
            if (event.target.checked) {
                setInputObj({
                    ...inputObj,
                    [name]: true,
                });
            } else {
                setInputObj({
                    ...inputObj,
                    [name]: false,
                });
            }
        } else {
            const { value } = event.target;
            setInputObj({
                ...inputObj,
                [name]: value,
            });
        }
    };

    const submitFormHandler = async (event) => {
        event.preventDefault();
        console.log(inputObj);
        if (inputObj.recipients.length === 0) {
            alert('받는 사람을 선택해주세요.');
            return;
        }
        if (inputObj.content === null || inputObj.content === '') {
            alert('메시지 내용을 입력해주세요.');
            return;
        }

        if (inputObj.redirectUrl === '') {
            setInputObj({
                ...inputObj,
                redirectUrl: null,
            });
        }
        setLoading(true);
        const res = await createMessage(inputObj);
        if (res.code) {
            if (res.code === 442) {
                alert('입력값이 올바르지 않습니다.');
                return;
            }
        }
        if (isSingleMessage) {
            setInputObj({
                ...inputObj,
                content: null,
                redirectUrl: null,
                sendSMS: false,
            });
            setMessages((prevState) => {
                return [...prevState, res.message];
            });
        } else {
            setInputObj({
                recipients: [],
                content: null,
                redirectUrl: null,
                sendSMS: false,
            });
            gridRef.current.api.deselectAll();
            setSelectedUsers([]);
            setSelectedUserString('');
        }
        setLoading(false);
        alert('메시지를 전송하였습니다.');
    };

    const gridRef = useRef();
    const containerStyle = useMemo(
        () => ({
            width: '100%',
            height: '94%',
            display: 'flex',
            justifyContent: 'space-between',
        }),
        []
    );
    const gridStyle = useMemo(
        () => ({ height: '100%', width: '50%', display: 'inline-block' }),
        []
    );

    const columnDefs = [
        {
            field: 'username',
            headerName: 'User Id',
            sortable: true,
            flex: 1,
            cellStyle: { textAlign: 'center' },
        },
        {
            field: 'name',
            headerName: 'Name',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            filterParams: {
                filterOptions: ['contains', 'equals', 'startsWith'],
            },
            flex: 1,
            cellStyle: { textAlign: 'center' },
        },
        {
            field: '그룹',
            headerName: '그룹',
            sortable: true,
            filter: 'agNumberedColumnFilter',
            floatingFilter: true,
            cellStyle: { textAlign: 'center' },
        },
    ];

    const rowClickHandler = async (event) => {
        if (isSingleMessage) {
            setLoading(true);
            const res = await getMessages(event.data.id);
            if (res.code) {
                return;
            }
            setMessages(res);
            setLoading(false);
        }
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        gridRef.current.api.sizeColumnsToFit();
    };

    const onSelectionChanged = (event) => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log(selectedRows);
        setSelectedUsers(selectedRows);
        setInputObj({
            ...inputObj,
            recipients: selectedRows.map((recipients) => recipients.id),
        });
    };

    useEffect(() => {
        if (selectedUsers.length >= 2) {
            setMoreThanTwo(true);
        } else {
            setMoreThanTwo(false);
        }
        let selectedRowsString = '';
        const maxToShow = 5;
        selectedUsers.forEach(function (selectedRow, index) {
            if (index >= maxToShow) {
                return;
            }
            if (index > 0) {
                selectedRowsString += ', ';
            }
            selectedRowsString += selectedRow.name;
        });
        if (selectedUsers.length > maxToShow) {
            const othersCount = selectedUsers.length - maxToShow;
            selectedRowsString += ' 외 ' + othersCount + ' 명';
        }
        setSelectedUserString(selectedRowsString);
    }, [selectedUsers]);

    const multiMessageHandler = (event) => {
        setIsSingleMessage((prevState) => {
            return !prevState;
        });
        setMessages([]);
        setSelectedUsers([]);
        gridRef.current.api.deselectAll();
    };

    const sortByCreatedAt = (a, b) => {
        return moment(b.createdAt).diff(a.createdAt);
    };

    const autoFillRecommendUrl = () => {
        setInputObj({ ...inputObj, redirectUrl: Urls.kakaoTalkChannelUrl });
    };

    useEffect(() => {
        messagesContainerRef.current.scrollTop =
            messagesContainerRef.current.scrollHeight;
    }, [messages]);

    const svgLink = (
        <svg
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22 12V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H12C12.2652 2 12.5196 2.10536 12.7071 2.29289C12.8946 2.48043 13 2.73478 13 3C13 3.26522 12.8946 3.51957 12.7071 3.70711C12.5196 3.89464 12.2652 4 12 4H4V20H20V12C20 11.7348 20.1054 11.4804 20.2929 11.2929C20.4804 11.1054 20.7348 11 21 11C21.2652 11 21.5196 11.1054 21.7071 11.2929C21.8946 11.4804 22 11.7348 22 12ZM21.382 2.077C21.261 2.0263 21.1312 2.00013 21 2H16C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3C15 3.26522 15.1054 3.51957 15.2929 3.70711C15.4804 3.89464 15.7348 4 16 4H18.586L11.293 11.293C11.1975 11.3852 11.1213 11.4956 11.0689 11.6176C11.0165 11.7396 10.9889 11.8708 10.9877 12.0036C10.9866 12.1364 11.0119 12.2681 11.0622 12.391C11.1125 12.5139 11.1867 12.6255 11.2806 12.7194C11.3745 12.8133 11.4861 12.8875 11.609 12.9378C11.7319 12.9881 11.8636 13.0134 11.9964 13.0123C12.1292 13.0111 12.2604 12.9835 12.3824 12.9311C12.5044 12.8787 12.6148 12.8025 12.707 12.707L20 5.414V8C20 8.26522 20.1054 8.51957 20.2929 8.70711C20.4804 8.89464 20.7348 9 21 9C21.2652 9 21.5196 8.89464 21.7071 8.70711C21.8946 8.51957 22 8.26522 22 8V3C21.9994 2.86888 21.9732 2.73913 21.923 2.618C21.8215 2.37308 21.6269 2.17849 21.382 2.077Z"
                fill="#547aff"
            />
        </svg>
    );

    const svgSms = (
        <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.25 9.38V2.62C1.25 1.58723 2.08723 0.75 3.12 0.75H8.5H12.88C13.9128 0.75 14.75 1.58723 14.75 2.62V9.38C14.75 10.4128 13.9128 11.25 12.88 11.25H3.12C2.08723 11.25 1.25 10.4128 1.25 9.38Z"
                stroke="#547aff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 1.5L6.84397 4.93114C7.53658 5.42174 8.46342 5.42174 9.15603 4.93114L14 1.5"
                stroke="#547aff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return (
        <div className={styles.messages}>
            <div className={styles['selected-count']}>
                {isSingleMessage ? (
                    <p>문자를 보낼 유저를 선택해주세요.</p>
                ) : (
                    <p>
                        다중 메시지: {selectedUsers ? selectedUsers.length : 0}{' '}
                        명 선택됨
                    </p>
                )}
                <button type="button" onClick={multiMessageHandler}>
                    {isSingleMessage ? '다중' : '단일'} 메시지 작성하기
                </button>
            </div>
            <div style={containerStyle}>
                <div className="ag-theme-alpine" style={gridStyle}>
                    <AgGridReact
                        ref={gridRef}
                        animateRows={true}
                        rowData={userList}
                        onGridReady={onGridReady}
                        columnDefs={columnDefs}
                        localeText={{ noRowsToShow: '조회 결과가 없습니다.' }}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">데이터 가져오는 중...</span>'
                        }
                        onRowClicked={rowClickHandler}
                        rowSelection={isSingleMessage ? 'single' : 'multiple'}
                        rowMultiSelectWithClick={!isSingleMessage && true}
                        onSelectionChanged={onSelectionChanged}
                    ></AgGridReact>
                </div>
                <div className={styles.chatroom}>
                    <div className={styles.recipients}>
                        받는 사람: {` ${selectedUserString}`}
                    </div>
                    <div
                        className={`${styles['message-list']} ${
                            loading && styles['message-list-spinner']
                        }`}
                        ref={messagesContainerRef}
                    >
                        {!isSingleMessage && (
                            <span>
                                메시지를 보낼 2명 이상의 유저들을 선택하세요.
                            </span>
                        )}
                        {isSingleMessage &&
                            !loading &&
                            (messages.length === 0
                                ? ''
                                : messages
                                      .sort(sortByCreatedAt)
                                      .reverse()
                                      .map((message) => (
                                          <MessageItem
                                              key={message.id}
                                              multi={
                                                  !!(
                                                      message.recipients &&
                                                      message.recipients
                                                          .length > 1
                                                  )
                                              }
                                              content={message.content}
                                              sender={
                                                  message.sender.affiliation
                                              }
                                              createdAt={message.createdAt}
                                              redirectUrl={
                                                  message.redirectUrl &&
                                                  message.redirectUrl
                                              }
                                          />
                                      )))}
                        {isSingleMessage && loading && <BeatLoader />}
                    </div>
                    <form onSubmit={submitFormHandler}>
                        <div className={styles['user-form']}>
                            <div className={styles['user-input']}>
                                <textarea
                                    name="content"
                                    id="content"
                                    disabled={!isSingleMessage && !moreThanTwo}
                                    placeholder="메시지 내용을 작성해주세요."
                                    cols={4}
                                    onChange={inputObjChangeHandler}
                                    value={
                                        inputObj.content ? inputObj.content : ''
                                    }
                                    required={true}
                                />
                                <div className={styles['send-button']}>
                                    <button
                                        disabled={
                                            !isSingleMessage && !moreThanTwo
                                        }
                                    >
                                        전송하기
                                    </button>
                                </div>
                            </div>
                            <div className={styles.urlsms}>
                                <div className={styles.url}>
                                    <FormLabel
                                        className={styles['url-label']}
                                        for="redirectUrl"
                                    >
                                        <span>{svgLink}</span> <span>URL</span>
                                    </FormLabel>
                                    <div>
                                        <FormInput
                                            name="redirectUrl"
                                            id="redirectUrl"
                                            type="text"
                                            disabled={
                                                !isSingleMessage && !moreThanTwo
                                            }
                                            className={styles.redirectUrl}
                                            onChange={inputObjChangeHandler}
                                            value={
                                                inputObj.redirectUrl
                                                    ? inputObj.redirectUrl
                                                    : ''
                                            }
                                            placeholder="카카오톡 채널 링크를 입력해주세요"
                                        />
                                        <div
                                            className={styles['url-recommend']}
                                            onClick={autoFillRecommendUrl}
                                        >
                                            {Urls.kakaoTalkChannelUrl}
                                        </div>
                                    </div>
                                </div>
                                <span>··</span>
                                <div className={styles.sms}>
                                    <FormLabel
                                        className={styles['sms-label']}
                                        for="sendSMS"
                                    >
                                        <span>{svgSms}</span> <span>SMS</span>
                                    </FormLabel>
                                    <FormInput
                                        type="checkbox"
                                        name="sendSMS"
                                        id="sendSMS"
                                        disabled={
                                            !isSingleMessage && !moreThanTwo
                                        }
                                        onChange={inputObjChangeHandler}
                                        value={inputObj.sendSMS}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminMessages;
