import React, { useRef, useState } from 'react';
import CloseIcon from '../../assets/close.svg';
import HelpIcon from '../../assets/help.svg';

import Modal from '../UI/Admin/Modal';
import FormInputArea from '../UI/Admin/FormInputArea';
import FormLabel from '../UI/Admin/FormLabel';
import FormInput from '../UI/Admin/FormInput';
import { addUser } from '../../lib/users';
import Spinner from '../UI/Admin/Spinner';

const AddUser = (props) => {
    const [loading, setLoading] = useState(false);

    const usernameInputRef = useRef();
    const nameInputRef = useRef();
    const dateOfBirthInputRef = useRef();
    const phoneNumberInputRef = useRef();

    const clearInput = () => {
        usernameInputRef.current.value = '';
        nameInputRef.current.value = '';
        phoneNumberInputRef.current.value = '';
        dateOfBirthInputRef.current.value = Date.now();
    };

    const submitFormHandler = async (event) => {
        event.preventDefault();
        const username = usernameInputRef.current.value.trim();
        const name = nameInputRef.current.value.trim();
        const dateOfBirth = dateOfBirthInputRef.current.value;
        const phoneNumber = phoneNumberInputRef.current.value.trim();
        setLoading(true);
        const response = await addUser(
            username,
            name,
            dateOfBirth,
            phoneNumber
        );
        if (response.code) {
            if (response.code === 409) {
                alert('해당 ID의 피험자가 이미 존재합니다.');
                clearInput();
                setLoading(false);
                return;
            } else if (response.code === 442) {
                alert('입력값이 올바르지 않습니다.');
                clearInput();
                setLoading(false);
                return;
            } else if (response.code === 500) {
                alert('알 수 없는 오류가 발생했습니다. 나중에 재시도해주세요.');
                setLoading(false);
                props.onClose();
                return;
            }
        }
        props.onAdd();
        setLoading(false);
        alert('새로운 피험자가 추가되었습니다.');
        clearInput();
    };

    return (
        <Modal onClose={props.onClose}>
            <div className="adminModalHeader">
                <div>피험자 추가</div>
                <div onClick={props.onClose} className="adminModalCloseBtn">
                    <img src={CloseIcon} alt="" />
                </div>
            </div>
            <div className="adminModalContent">
                {loading && <Spinner />}
                <div className="adminModalInfo">
                    <img src={HelpIcon} alt="" />
                    도움말
                </div>
                <ul>
                    <li>
                        피험자의 <span className="emp1">ID</span>,{' '}
                        <span className="emp1">이름</span>,{' '}
                        <span className="emp1">생년월일</span>,{' '}
                        <span className="emp1">휴대폰 번호</span>를 입력하세요.
                    </li>
                    <li>
                        피험자의 ID 는 000 ~ 999 사이의 세자리 숫자여야 합니다.
                    </li>
                </ul>
                <div className="adminModalFormWrapper">
                    <form onSubmit={submitFormHandler}>
                        <FormInputArea>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="username"
                                    className="adminInputLabel"
                                >
                                    ID
                                </FormLabel>
                                <FormInput
                                    type="text"
                                    name="username"
                                    ref={usernameInputRef}
                                    required={true}
                                    pattern="[0-9]{3}"
                                    className="adminInputText"
                                />
                            </div>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="name"
                                    className="adminInputLabel"
                                >
                                    Name
                                </FormLabel>
                                <FormInput
                                    type="text"
                                    name="name"
                                    ref={nameInputRef}
                                    required={true}
                                    className="adminInputText"
                                />
                            </div>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="dateOfBirth"
                                    className="adminInputLabel"
                                >
                                    Date of Birth
                                </FormLabel>
                                <FormInput
                                    type="date"
                                    name="dateOfBirth"
                                    ref={dateOfBirthInputRef}
                                    required={true}
                                    className="adminInputText"
                                />
                            </div>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="phoneNumber"
                                    label="휴대폰 번호"
                                    className="adminInputLabel"
                                >
                                    Phone Number
                                </FormLabel>
                                <FormInput
                                    name="phoneNumber"
                                    required={true}
                                    ref={phoneNumberInputRef}
                                    minLength="10"
                                    maxLength="13"
                                    type="text"
                                    pattern="01([0-9]){9}"
                                    className="adminInputText"
                                />
                            </div>
                        </FormInputArea>
                        <button type="submit" className="adminBottomBtn">
                            추가
                        </button>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default AddUser;
