import axios from 'axios';
import axiosInstance from './axiosInstance';

const JWT_EXPIRY_TIME = 3600 * 1000; // admin access token jwt expires in 1h

const adminLogin = async (username, password) => {
    const body = {
        username,
        password,
    };

    let failed;
    const res = await axios.post('/auth/admin-login', body).catch((error) => {
        console.log(error);
        failed = error.response.data;
    });

    if (failed) {
        return failed;
    }
    onLoginSuccess(res);
    return res.data;
};

const onLoginSuccess = (response) => {
    const { accessToken } = response.data;
    console.log(response);
    // accessToken 설정
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    localStorage.setItem(
        'currentUser',
        JSON.stringify({
            id: response.data._id,
            username: response.data.username,
            affiliation: response.data.affiliation,
            attributes: response.data.attributes,
            phoneNumber: response.data.phoneNumber,
        })
    );

    // accessToken 만료하기 1분 전에 로그인 연장
    setTimeout(onSilentRefresh, JWT_EXPIRY_TIME - 60000);
};

const onSilentRefresh = () => {
    axiosInstance
        .post('/auth/refresh')
        .then((response) => {
            if (response.status === 200 || response.status === 201) {
                console.log(response.data.message);
                onLoginSuccess(response);
            }
        })
        .catch((error) => {
            console.log(error.response.data);
            if (
                error.response.data.code === 401 ||
                error.response.data.code === 400
            ) {
                // redirect to login page
                window.location.href = 'https://icaan.net/admin/login';
            }
        });
};

const userLogin = async ({ username, name }) => {
    const body = {
        username,
        name,
    };
    console.log(body);

    let failed;
    const res = await axios.post('/auth/user-login', body).catch((error) => {
        failed = error.response.data;
    });

    if (failed) {
        return failed;
    }

    const { accessToken } = res.data;

    // accessToken 설정
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    return res.data;
};

export { adminLogin, userLogin, onSilentRefresh };
