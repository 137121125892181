import React from 'react';

const FormInput =
    // eslint-disable-next-line react/display-name
    React.forwardRef((props, ref) => {
        return (
            <input
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                ref={ref}
                onChange={props.onChange}
                required={props.required}
                minLength={props.minLength}
                maxLength={props.maxLength}
                pattern={props.pattern}
                disabled={props.disabled}
                value={props.value}
                id={props.id}
                className={props.className}
                checked={props.checked}
                onClick={props.onClick}
                autoComplete="off"
            />
        );
    });

export default FormInput;
