import { useState } from 'react';
import AuthContext from './AuthContext';

const AuthProvider = (props) => {
    const [authState, setAuthState] = useState(
        JSON.parse(localStorage.getItem('currentUser'))
    );

    const setCurrentUser = (user) => {
        const userContext = {
            id: user._id,
            username: user.username,
            affiliation: user.affiliation,
            phoneNumber: user.phoneNumber,
        };
        setAuthState(userContext);
        console.log('set current user in context');
    };

    const logoutHandler = () => {
        localStorage.removeItem('currentUser');
        setAuthState({});
    };

    const authContext = {
        user: authState,
        onLogin: setCurrentUser,
        onLogout: logoutHandler,
    };

    return (
        <AuthContext.Provider value={authContext}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
