import { Routes, Route } from 'react-router-dom';
import AdminApp from './pages/Admin/AdminApp';
import React, { Fragment } from 'react';
import AxiosTest from './AxiosTest';
import UserApp from './pages/User/UserApp';

const App = () => {
    return (
        <Fragment>
            <Routes>
                <Route path="admin/*" element={<AdminApp />} />
                <Route path="/*" element={<UserApp />} />
                <Route path="test" element={<AxiosTest />} />
            </Routes>
        </Fragment>
    );
};

export default App;
