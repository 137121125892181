import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UsersTable from '../../components/Admin/UsersTable';
import AddUser from '../../components/Admin/AddUser';
import UploadUserFile from '../../components/Admin/UploadUserFile';
import AddColumn from '../../components/Admin/AddColumn';
import UpdateColumns from '../../components/Admin/UpdateColumns';
import UpdateUser from '../../components/Admin/UpdateUser';
import UserActivitiesTable from '../../components/Admin/UserActivitiesTable';
import { downloadUserData, getUsersData } from '../../lib/users';
import { getAttributes } from '../../lib/attributes';
import { autoSizeAll, sizeToFit } from '../../lib/agGrid';

import styles from './AdminUsers.module.css';
import Help from '../../components/UI/Admin/Help';

const AdminUsers = () => {
    const [isAddUser, setIsAddUser] = useState(false);
    const [isUploadUserFile, setIsUploadUserFile] = useState(false);
    const [isAddAttribute, setIsAddAttribute] = useState(false);
    const [isUpdateAttributes, setIsUpdateAttributes] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [attributes, setAttributes] = useState([]);
    const [isUpdateUser, setIsUpdateUser] = useState(false);
    const [doubleClickedUser, setDoubleClickedUser] = useState(null);
    const [userDataChanged, setUserDataChanged] = useState(true);
    const [columnDataChanged, setColumnDataChanged] = useState(true);
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);

    const gridRef = useRef();

    useEffect(() => {
        if (gridApi) {
            gridRef.current.api.showLoadingOverlay();
            if (userDataChanged) {
                console.log('user data changed');
                console.log(userDataChanged);
                const getUsers = async () => {
                    const data = await getUsersData();
                    if (data.code) {
                        console.log(data);
                        return;
                    }
                    if (data.cause === 204) {
                        console.log('empty');
                        gridRef.current.api.showNoRowsOverlay();
                        return;
                    }
                    setRowData(data);
                };
                getUsers();
                setUserDataChanged(false);
            }
            gridRef.current.api.hideOverlay();
        }
    }, [userDataChanged, gridApi]);

    useEffect(() => {
        if (gridApi) {
            sizeToFit(gridRef);
        }
        if (columnDataChanged) {
            const getColumns = async () => {
                const data = await getAttributes();
                if (data.code) {
                    console.log(data);
                    return;
                }
                if (data) {
                    setAttributes(data);
                    autoSizeAll(gridRef);
                } else {
                    setAttributes([]);
                    sizeToFit(gridRef);
                }
            };
            getColumns();
            setColumnDataChanged(false);
        }
    }, [columnDataChanged, gridApi]);

    const addUserHandler = () => {
        setIsAddUser((prevState) => {
            return !prevState;
        });
    };

    const uploadUserFileHandler = () => {
        setIsUploadUserFile((prevState) => {
            return !prevState;
        });
    };

    const addColumnHandler = () => {
        setIsAddAttribute((prevState) => {
            return !prevState;
        });
    };

    const updateColumnsHandler = () => {
        setIsUpdateAttributes((prevState) => {
            return !prevState;
        });
    };

    const updateUserHandler = (user) => {
        setDoubleClickedUser(user);
        setIsUpdateUser((prevState) => {
            return !prevState;
        });
    };

    const userSelectHandler = (user) => {
        setSelectedUser(user);
    };

    const userDataChangeHandler = () => {
        setUserDataChanged(true);
    };

    const columnDataChangedHandler = () => {
        setColumnDataChanged(true);
    };

    const downloadUserDataHandler = async () => {
        const res = await downloadUserData();
        if (res.code) {
            console.log(res);
        }
        console.log(res);
    };
    const navigate = useNavigate();
    const sendMessageHandler = () => {
        const recipients = gridRef.current.api.getSelectedRows();
        if (recipients.length === 0 || !recipients) {
            alert('메시지를 보낼 대상을 한 명 이상 선택해주세요.');
            return;
        }
        navigate('/admin/messages', {
            state: {
                recipients,
            },
        });
    };

    return (
        <div className={styles.users}>
            {/* ------Modals------ */}
            {isAddUser && (
                <AddUser
                    onClose={addUserHandler}
                    onAdd={userDataChangeHandler}
                />
            )}
            {isUploadUserFile && (
                <UploadUserFile
                    onClose={uploadUserFileHandler}
                    onAdd={userDataChangeHandler}
                />
            )}
            {isAddAttribute && (
                <AddColumn
                    onClose={addColumnHandler}
                    onAdd={columnDataChangedHandler}
                />
            )}
            {isUpdateAttributes && (
                <UpdateColumns
                    onClose={updateColumnsHandler}
                    attributes={attributes}
                    onUpdate={columnDataChangedHandler}
                />
            )}
            {isUpdateUser && (
                <UpdateUser
                    onClose={updateUserHandler}
                    user={doubleClickedUser}
                    attributes={attributes}
                    onUpdate={userDataChangeHandler}
                />
            )}
            {/* ------Modals End Here------ */}
            <div className={styles.bar}>
                <div className={styles['left-menus']}>
                    <Help
                        header={'도움말'}
                        display={'inline-block'}
                        size={'80%'}
                        width={'20rem'}
                        position={'right bottom'}
                        isRelative={true}
                        top={'0.1rem'}
                        right={'0rem'}
                        style={{ verticalAlign: 'middle' }}
                    >
                        <ul>
                            <li>
                                메시지를 보낼 유저를 선택하고 '메시지 보내기'
                                버튼을 클릭하면 바로 메시지 입력 페이지로 이동할
                                수 있습니다.
                            </li>
                            <li>
                                더블 클릭하여 유저 정보를 수정할 수 있습니다.
                            </li>
                        </ul>
                    </Help>
                    <div
                        style={{
                            display: 'inline-block',
                            height: '100%',
                            verticalAlign: 'middle',
                        }}
                    >
                        <p style={{ marginLeft: '0.2rem' }}>
                            Rows: {rowData.length}
                        </p>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button type="button" onClick={sendMessageHandler}>
                        메시지 보내기
                    </button>
                    <button type="button" onClick={addUserHandler}>
                        피험자 추가
                    </button>
                    <button type="button" onClick={uploadUserFileHandler}>
                        피험자 추가 (파일 업로드)
                    </button>
                    <button type="button" onClick={addColumnHandler}>
                        열 추가
                    </button>
                    <button type="button" onClick={updateColumnsHandler}>
                        열 수정 및 삭제
                    </button>
                    <button type="button" onClick={downloadUserDataHandler}>
                        유저 데이터 다운로드
                    </button>
                </div>
            </div>
            <UsersTable
                onSelectUser={userSelectHandler}
                onDoubleClickUser={updateUserHandler}
                columns={attributes}
                rowData={rowData}
                gridRef={gridRef}
                onGridReady={setGridApi}
            />
            <UserActivitiesTable user={selectedUser} />
        </div>
    );
};

export default AdminUsers;
