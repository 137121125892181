import React, { useRef, useContext, useState, useEffect } from 'react';
import { adminLogin } from '../../lib/auth';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../store/AuthContext';
import Spinner from '../../components/UI/Admin/Spinner';

import styles from './AdminLogin.module.css';
import mark from '../../assets/mark.svg';
import loginID from '../../assets/loginUsername.svg';
import loginPW from '../../assets/loginID.svg';

const AdminLogin = () => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const usernameInputRef = useRef();
    const passwordInputRef = useRef();

    const loginHandler = async (event) => {
        event.preventDefault();
        setLoading(true);
        const response = await adminLogin(
            usernameInputRef.current.value,
            passwordInputRef.current.value
        ).then((res) => {
            console.log(res);
            if (res.code === 401) {
                alert('아이디 혹은 비밀번호가 일치하지 않습니다.');
                usernameInputRef.current.value = '';
                passwordInputRef.current.value = '';
                usernameInputRef.current.focus();
            } else {
                authContext.onLogin(res);
                navigate('/admin/dashboard', { replace: true });
            }
        });
        setLoading(false);
    };

    useEffect(() => {
        console.log(authContext.user);
        if (
            authContext.user === null ||
            Object.keys(authContext.user).length === 0
        ) {
            navigate('/admin/login', { replace: true });
        } else {
            console.log('auth context exists');
            navigate('/admin/dashboard', { replace: true });
        }
        usernameInputRef.current.focus();
    }, []);

    return (
        <div className={styles.login}>
            {loading && <Spinner />}
            <div className={styles.logo}>
                ICAAN
                <img src={mark} alt="" />
            </div>
            <form className={styles['login-form']} onSubmit={loginHandler}>
                <div className={styles['login-input']}>
                    <img src={loginID} alt="" />
                    <input
                        name="username"
                        type="text"
                        ref={usernameInputRef}
                        required={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles['login-input']}>
                    <img src={loginPW} alt="" />
                    <input
                        name="password"
                        type="password"
                        ref={passwordInputRef}
                        required={true}
                        minLength="3"
                    />
                </div>
                <button type="submit">LOG IN</button>
            </form>
        </div>
    );
};

export default AdminLogin;
