import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import messages from './messages';
import notices from './notices';

const rootReducer = combineReducers({ messages, notices });

export function* rootSaga() {
    yield all([]);
}

export default rootReducer;
