import styled from 'styled-components';
import UserColors from './colors';

export const RedirectUrl = styled.a`
    display: flex;
    width: fit-content;
    gap: 0.3rem;
    align-items: center;
    margin-top: 0.8rem;
    font-size: 0.9em;
    font-weight: 500;
    color: ${UserColors.blue01};
    background: linear-gradient(
        to top,
        ${UserColors.blue03} 50%,
        transparent 50%
    );

    svg {
        width: 1.1rem;
    }
`;

export const NoResourceError = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${UserColors.blue01};
    font-size: 1.5rem;
    white-space: nowrap;
    animation: 1s ease fadeIn;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
