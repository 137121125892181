import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { cloneDeep } from 'lodash';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom',
            align: 'center',
            labels: {
                boxWidth: 10,
                textAlign: 'center',
            },
        },
        title: {
            display: true,
            text: '상태별 시청 현황',
            font: {
                size: 14,
                weight: 'bold',
            },
        },
    },
    scales: {
        y: {
            suggestedMin: 0,
            suggestedMax: 30,
        },
    },
};

const topBarGraphOptions = {
    skipNull: true,
    minBarLength: 2,
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
            text: '누적 재생횟수 상위 5',
            font: {
                size: 14,
                weight: 'bold',
            },
        },
        tooltip: {
            callbacks: {
                title: function (context) {
                    let title = context[0].label;
                    if (title.length > 8) {
                        title = title.substring(0, 8) + '...';
                    }
                    return title;
                },
            },
        },
    },
    scales: {
        x: {
            display: false,
            ticks: {
                minRotation: 50,
                maxRotation: 90,
            },
        },
        y: {
            ticks: { stepSize: 12 },
            display: false,
        },
    },
};

const bottomBarGraphOptions = cloneDeep(topBarGraphOptions);
bottomBarGraphOptions.scales.x.reverse = true;
bottomBarGraphOptions.scales.y.ticks.stepSize = 10;
bottomBarGraphOptions.plugins.title.text = '누적 재생횟수 하위 5';
bottomBarGraphOptions.minBarLength = 4;

const scatterPlotOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
            text: '월별 평균 영상 시청 시간',
            font: {
                size: 14,
                weight: 'bold',
            },
        },
        tooltip: {
            callbacks: {
                title: function (context) {
                    let names = '';
                    for (let i = 0; i < context.length; i++) {
                        if (names === '') {
                            names += context[i].raw.name;
                        }
                        if (i === 0) {
                            continue;
                        }
                        names += ', ' + context[i].raw.name;
                    }
                    // return context[0].raw.name;
                    return names;
                },
                label: function (context) {
                    return null;
                },
                afterBody: function (context) {
                    return '평균 운동 시간: ' + context[0].raw.y + '분';
                },
            },
        },
    },
    scales: {
        x: {
            type: 'linear',
            display: true,
            ticks: {
                stepSize: 1,
                callback: function (value, index, ticks) {
                    return value + '월';
                },
            },
        },
        y: {
            ticks: {
                stepSize: 10,
            },
            display: true,
        },
    },
};

export {
    lineChartOptions,
    topBarGraphOptions,
    bottomBarGraphOptions,
    scatterPlotOptions,
};
