import VideoDIsplayPlaylistItem from './VIdeoDisplayPlaylistItem';
import styled from 'styled-components';
import UserColors from '../../constants/colors';
import { useEffect, useState, useRef } from 'react';
import LoadingSpinner from '../UI/User/LoadingSpinner';

function VideoDisplayPlaylist({ videos, isComplete, curNum }) {
    console.log(videos);

    const [isLoading, setIsLoading] = useState(true);
    const [isScroll, setIsScroll] = useState(false);

    const scroller = useRef();

    useEffect(() => {
        console.log(scroller.current.scrollHeight);
        console.log(scroller.current.clientHeight);
        if (scroller.current.scrollHeight > scroller.current.clientHeight)
            setIsScroll(true);
    });

    useEffect(() => {
        if (videos.length !== 0) {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }, [videos]);
    return (
        <PlaylistContainer>
            <PlaylistWrapper ref={scroller} isScroll={isScroll}>
                <LoadingSpinner isLoading={isLoading} />
                {videos &&
                    videos.map((item, index) => (
                        <VideoDIsplayPlaylistItem
                            video={item}
                            key={index}
                            isComplete={isComplete}
                            kval={index}
                            curNum={curNum}
                        />
                    ))}
            </PlaylistWrapper>
        </PlaylistContainer>
    );
}

const PlaylistContainer = styled.div`
    margin: 2rem 0;
    border: 0.1rem solid ${UserColors.gray02};
    border-radius: 20px;
    width: 100%;
    padding: 1rem;
    position: relative;
    /* 히든줘야 밖으로 나가는 애들이 플렉스 컨테이너 안에 담김 */
    overflow: hidden;
    /* 여백 차지 */
    flex-grow: 1;
`;

const PlaylistWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 3%;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        /* 스크롤 필요없으면 안보이게 */
        width: ${(props) => (props.isScroll ? '0.4rem' : '0rem')};
    }
    &::-webkit-scrollbar-thumb {
        background: ${UserColors.blue01};
        border-radius: 30px;
    }
    &::-webkit-scrollbar-track {
        background-color: ${(props) =>
            props.isScroll ? `${UserColors.gray01}` : ''};
        border-radius: 30px;
    }
`;

export default VideoDisplayPlaylist;
