import React, { useRef, useState } from 'react';
import Modal from '../UI/Admin/Modal';
import FormInputArea from '../UI/Admin/FormInputArea';
import FormLabel from '../UI/Admin/FormLabel';
import FormSelect from '../UI/Admin/FormSelect';
import FormInput from '../UI/Admin/FormInput';
import CloseIcon from '../../assets/close.svg';
import HelpIcon from '../../assets/help.svg';
import { addAttribute } from '../../lib/attributes';
import Spinner from '../UI/Admin/Spinner';

const AddColumn = (props) => {
    const nameInputRef = useRef();
    const typeSelectRef = useRef();

    const [loading, setLoading] = useState(false);

    const submitFormHandler = async (event) => {
        event.preventDefault();

        setLoading(true);
        const response = await addAttribute(
            nameInputRef.current.value,
            typeSelectRef.current.value
        );
        if (response.code) {
            if (response.code === 409) {
                alert('해당 이름의 열이 이미 존재합니다.');
                setLoading(false);
                return;
            } else if (response.code === 442) {
                alert('입력값이 올바르지 않습니다.');
                setLoading(false);
                return;
            } else if (response.code === 500) {
                alert('알 수 없는 오류가 발생했습니다. 나중에 재시도해주세요.');
                setLoading(false);
                props.onClose();
                return;
            }
        }
        props.onAdd();
        alert('새로운 열이 추가되었습니다.');
        setLoading(false);
        nameInputRef.current.value = '';
        typeSelectRef.current.value = '';
    };

    return (
        <Modal onClose={props.onClose}>
            <div className="adminModalHeader">
                <div>열 추가</div>
                <div onClick={props.onClose} className="adminModalCloseBtn">
                    <img src={CloseIcon} alt="" />
                </div>
            </div>
            <div className="adminModalContent">
                {loading && <Spinner />}
                <div className="adminModalInfo">
                    <img src={HelpIcon} alt="" />
                    도움말
                </div>
                <ul>
                    <li>
                        추가할 <span className="emp1">열 이름</span>과{' '}
                        <span className="emp1">데이터 타입</span>을
                        선택해주세요.
                    </li>
                </ul>
                <div className="adminModalFormWrapper">
                    <form onSubmit={submitFormHandler}>
                        <FormInputArea>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="name"
                                    label="추가할 열 이름"
                                    className="adminInputLabel"
                                >
                                    추가할 열 이름
                                </FormLabel>
                                <FormInput
                                    type="text"
                                    name="name"
                                    ref={nameInputRef}
                                    required={true}
                                    className="adminInputText"
                                />
                            </div>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="isNumber"
                                    label="데이터 타입"
                                    className="adminInputLabel"
                                >
                                    데이터 타입
                                </FormLabel>
                                <FormSelect
                                    name="isNumber"
                                    ref={typeSelectRef}
                                    required={true}
                                    options={[
                                        { name: '숫자', value: true },
                                        { name: '문자', value: false },
                                    ]}
                                    className="adminSelect"
                                />
                            </div>
                        </FormInputArea>
                        <button type="submit" className="adminBottomBtn">
                            추가
                        </button>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default AddColumn;
