import { formatTimezoneWithTime } from './formatTimezone';
import axiosInstance from './axiosInstance';
import axios from 'axios';

const getAllNotices = async () => {
    let failed;
    const res = await axiosInstance.get('/notices').catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }
    if (res.data) {
        const data = res.data;
        let fixedTopIndex;
        let fixedTop;
        data.forEach((notice, index) => {
            if (notice.fixTop) {
                fixedTop = notice;
                fixedTopIndex = index;
            }
        });
        if (fixedTopIndex !== undefined && fixedTop !== undefined) {
            data.splice(fixedTopIndex, 1);
            data.unshift(fixedTop);
        }

        return data.map((n) => {
            return {
                id: n.id,
                title: n.title,
                author: n.author.affiliation,
                content: n.content,
                fixTop: n.fixTop,
                redirectUrl: n.redirectUrl,
                sendSMS: n.sendSMS,
                reservedDatetime: formatTimezoneWithTime(n.reservedDatetime),
                isVisible: n.isVisible,
                createdAt: formatTimezoneWithTime(n.createdAt),
            };
        });
    } else {
        return [];
    }
};

const getAllNoticesByUser = async () => {
    let failed;
    const res = await axios.get('/notices').catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }

    if (res.data) {
        const data = res.data;
        let fixedTopIndex;
        let fixedTop;
        data.forEach((notice, index) => {
            if (notice.fixTop) {
                fixedTop = notice;
                fixedTopIndex = index;
            }
        });
        if (fixedTopIndex !== undefined && fixedTop !== undefined) {
            data.splice(fixedTopIndex, 1);
            data.unshift(fixedTop);
        }

        return data.map((n) => {
            return {
                id: n.id,
                title: n.title,
                author: n.author.affiliation,
                content: n.content,
                fixTop: n.fixTop,
                redirectUrl: n.redirectUrl,
                sendSMS: n.sendSMS,
                reservedDatetime: formatTimezoneWithTime(n.reservedDatetime),
                isVisible: n.isVisible,
                createdAt: formatTimezoneWithTime(n.createdAt),
            };
        });
    } else {
        return [];
    }
};

const getNotice = async (noticeId) => {
    let failed;
    const res = await axiosInstance
        .get(`/notices/${noticeId}`)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const getNoticeByUser = async (noticeId) => {
    let failed;
    const res = await axios.get(`/notices/${noticeId}`).catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }
    return res.data;
};

const updateNotice = async (noticeId, body) => {
    let failed;
    const res = await axiosInstance
        .patch(`/notices/${noticeId}`, body)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const deleteNotice = async (noticeId) => {
    let failed;
    const res = await axiosInstance
        .delete(`/notices/${noticeId}`)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }

    return res.data;
};

const addNotice = async (body) => {
    let failed;
    const res = await axiosInstance.post('/notices', body).catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }
    return res.data;
};

const updateNoticeSchedule = async (noticeId, body) => {
    let failed;
    const res = await axiosInstance
        .patch(`/notices/reserved/${noticeId}`, body)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });

    if (failed) {
        return failed;
    }
    return res.data;
};

const sendSMSNotice = async (noticeId) => {
    let failed;
    const res = await axiosInstance
        .post(`sens/notice/${noticeId}`)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

export {
    getAllNotices,
    getAllNoticesByUser,
    getNotice,
    getNoticeByUser,
    updateNotice,
    deleteNotice,
    addNotice,
    updateNoticeSchedule,
    sendSMSNotice,
};
