import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/UI/Shared/Button';
import SelectVideo from '../../components/User/SelectVideo';
import VideoDisplay from '../../components/User/VideoDisplay';
import { getUserActivities } from '../../lib/activities';

function UserWatchPage() {
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location.state);
    const { playlist } = location.state;
    console.log(playlist);
    const newArr = playlist.map((item) => item.youtubeId);
    const onClick = () => {
        navigate('/complete');
    };

    return (
        <>
            <VideoDisplay
                playlist={playlist}
                received={newArr}
                navigate={navigate}
            />
        </>
    );
}

export default UserWatchPage;
