import { formatTimezoneWithoutTime } from './formatTimezone';
import axiosInstance from './axiosInstance';
import axios from 'axios';

const getAllResources = async () => {
    let failed;
    const res = await axiosInstance.get('/resources').catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }

    console.log(res.data);

    return res.data
        ? res.data.map((r) => {
              return {
                  id: r.id,
                  name: r.name,
                  videoUrl: r.videoUrl,
                  youtubeId: r.youtubeId,
                  creator: r.creator.affiliation,
                  createdAt: formatTimezoneWithoutTime(r.createdAt),
                  updatedAt: formatTimezoneWithoutTime(r.updatedAt),
              };
          })
        : [];
};

const getAllResourcesByUser = async () => {
    let failed;
    const res = await axios.get('/resources').catch((error) => {
        console.log(error);
        failed = error.response.data;
        throw new Error(failed);
    });

    return res.data.map((r) => {
        return {
            id: r.id,
            name: r.name,
            videoUrl: r.videoUrl,
            youtubeId: r.youtubeId,
            creator: r.creator.affiliation,
            createdAt: formatTimezoneWithoutTime(r.createdAt),
            updatedAt: formatTimezoneWithoutTime(r.updatedAt),
        };
    });
};

const addResource = async (name, videoUrl) => {
    let failed;
    const res = await axiosInstance
        .post('/resources', { name, videoUrl })
        .catch((error) => {
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const updateResource = async (resourceId, name, videoUrl) => {
    let failed;
    const res = await axiosInstance
        .patch(`/resources/${resourceId}`, { name, videoUrl })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const getResourceById = async (resourceId) => {
    let failed;
    const res = await axiosInstance
        .get(`/resources/${resourceId}`)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const deleteResource = async (resourceId) => {
    let failed;
    const res = await axiosInstance
        .delete(`/resources/${resourceId}`)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const reorderResource = async (targetItem, overItem) => {
    let failed;
    const res = await axiosInstance
        .post('/resources/reorder', { targetItem, overItem })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    console.log(res);
    return res;
};

export {
    getResourceById,
    getAllResources,
    getAllResourcesByUser,
    updateResource,
    addResource,
    deleteResource,
    reorderResource,
};
