import React, { useState } from 'react';
import Modal from '../UI/Admin/Modal';
import FormInputArea from '../UI/Admin/FormInputArea';
import FormLabel from '../UI/Admin/FormLabel';
import camelCaseToRegularText from '../../lib/toRegularText';
import FormInput from '../UI/Admin/FormInput';
import cloneDeep from 'lodash/cloneDeep';
import { deleteUser, updateUser } from '../../lib/users';
import CloseIcon from '../../assets/close.svg';
import HelpIcon from '../../assets/help.svg';
import TrashIcon from '../../assets/trash.svg';
import Spinner from '../UI/Admin/Spinner';

import styles from './UpdateColumns.module.css';

const UpdateUser = (props) => {
    const initUser = cloneDeep(props.user);
    const attributes = props.attributes.map((a) => a.name);
    attributes.forEach((a) => {
        if (!(a in initUser)) {
            initUser[a] = '';
        }
    });

    const [userInput, setUserInput] = useState(initUser);
    const [loading, setLoading] = useState(false);

    const userInputChangeHandler = (event, params) => {
        const { value, name } = event.target;

        setUserInput({
            ...userInput,
            [name]: value,
        });
    };
    const submitFormHandler = async (event) => {
        event.preventDefault();
        const attributes = props.attributes.map((a) => a.name);
        const customData = {};
        attributes.forEach((a) => {
            customData[a] = userInput[a];
        });
        const body = {
            name: userInput.name,
            phoneNumber: userInput.phoneNumber,
            customData,
        };
        setLoading(true);
        const res = await updateUser(userInput.id, body);
        if (res.code) {
            if (res.code === 404) {
                alert(
                    '해당 유저를 찾을 수 없습니다. 새로고침 후 재시도 해주세요.'
                );
                return;
            }
            if (res.code === 442) {
                alert('입력값이 올바르지 않습니다.');
                return;
            }
        }
        props.onUpdate();
        setLoading(false);
        alert('성공적으로 업데이트 되었습니다.');
        props.onClose();
    };

    const deleteUserHandler = async () => {
        const proceed = confirm(
            '정말로 유저를 삭제하시겠습니까?' +
                '\n' +
                '(유저를 삭제하시면, 유저의 영상 시청 기록 역시 삭제됩니다. ' +
                '데이터의 보존이 필요하다면 삭제 전 `유저데이터 다운로드` 기능을 이용해주세요.)'
        );
        if (proceed) {
            setLoading(true);
            const res = await deleteUser(props.user.id);
            if (res.code) {
                if (res.code === 404) {
                    alert(
                        '해당 id 의 유저를 찾을 수 없습니다. 새로고침 후 재시도 해주세요.'
                    );
                    setLoading(false);
                    return;
                } else if (res.code === 500) {
                    alert(
                        '알 수 없는 오류가 발생했습니다. 나중에 재시도해주세요.'
                    );
                    setLoading(false);
                    props.onClose();
                    return;
                }
            }
            props.onUpdate();
            setLoading(false);
            alert('유저를 삭제했습니다.');
            props.onClose();
        }
    };

    return (
        <Modal onClose={props.onClose} style={{ overflowY: 'scroll' }}>
            <div className="adminModalHeader">
                <div>피험자 정보 수정</div>
                <div onClick={props.onClose} className="adminModalCloseBtn">
                    <img src={CloseIcon} alt="" />
                </div>
            </div>
            <div className="adminModalContent">
                {loading && <Spinner />}
                <div className="adminModalInfo">
                    <img src={HelpIcon} alt="" />
                    도움말
                </div>
                <ul>
                    <li>
                        <span className="emp1">Username</span>이{' '}
                        <span className="emp2">{props.user.username}</span>인
                        피험자의 정보를 수정합니다.
                    </li>
                </ul>
                <div className="adminModalFormWrapper">
                    <form onSubmit={submitFormHandler}>
                        <FormInputArea className={styles['input-area']}>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="name"
                                    label="name"
                                    className="adminInputLabel"
                                >
                                    Name
                                </FormLabel>
                                <FormInput
                                    type="text"
                                    name="name"
                                    id="name"
                                    required={true}
                                    value={userInput.name}
                                    onChange={(event) =>
                                        userInputChangeHandler(event, 'name')
                                    }
                                    className="adminInputText"
                                />
                            </div>
                            <div className="adminInputRowWrapper">
                                <FormLabel
                                    for="phoneNumber"
                                    label="Phone Number"
                                    className="adminInputLabel"
                                >
                                    Phone Number
                                </FormLabel>
                                <FormInput
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    required={true}
                                    minLength="10"
                                    maxLength="13"
                                    type="text"
                                    pattern="01([0-9]){9}"
                                    value={userInput.phoneNumber}
                                    onChange={userInputChangeHandler}
                                    className="adminInputText"
                                />
                            </div>
                            {props.attributes.map((attribute) => {
                                return (
                                    <div
                                        key={attribute.id}
                                        className="adminInputRowWrapper"
                                    >
                                        <FormLabel
                                            for={attribute.name}
                                            label={camelCaseToRegularText(
                                                attribute.name
                                            )}
                                            className="adminInputLabel"
                                        >
                                            {camelCaseToRegularText(
                                                attribute.name
                                            )}
                                        </FormLabel>
                                        <FormInput
                                            type={
                                                attribute.isNumber
                                                    ? 'number'
                                                    : 'text'
                                            }
                                            name={attribute.name}
                                            id={attribute.name}
                                            value={
                                                userInput[attribute.name] || ''
                                            }
                                            onChange={userInputChangeHandler}
                                            className="adminInputText"
                                        />
                                    </div>
                                );
                            })}
                        </FormInputArea>
                        <div className="adminBtnWrapper">
                            <button type="submit" className="adminBottomBtn">
                                수정
                            </button>
                            <button
                                type="button"
                                onClick={deleteUserHandler}
                                id="adminTrashBtn"
                            >
                                <img src={TrashIcon} alt="" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default UpdateUser;
