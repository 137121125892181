import React, { useState, useEffect } from 'react';
import RightArrowIcon from '../../../assets/right-arrow.svg';
import LeftArrowIcon from '../../../assets/left-arrow.svg';
import IccanLogoNav from '../../../assets/icaan-logo-nav.svg';
import GrayNotification from '../../../assets/gray-notification.svg';
import BlueNotification from '../../../assets/blue-notification.svg';
import styled from 'styled-components';
import UserColors from '../../../constants/colors';
import { UserFonts } from '../../../constants/styles';
import { useNavigate } from 'react-router-dom';
import useMessages from '../../../hooks/useMessages';
import useNotices from '../../../hooks/useNotices';

const Navbar = ({ path }) => {
    const right = ['/'];
    const left = ['/video', '/home/notice/view', '/notification'];
    const plain = ['/home', '/home/message', '/home/notice'];
    const [lastMessageTimeStamp, setLastMessageTimeStamp] = useState(0);
    const [lastMessageChecked, setLastMessageChecked] = useState(0);
    const [noticesLength, setNoticesLength] = useState(0);
    const [noticeReadLength, setNoticeReadLength] = useState(0);
    const { messages } = useMessages();
    const { notices } = useNotices();
    const [isVideo, setIsVideo] = useState(true);

    const navigate = useNavigate();

    const toLogin = () => navigate('/login');

    const toPrev = () => {
        if (path === '/home/notice/view') navigate('/home/notice');
        else navigate(-1);
    };

    const toHome = () => navigate('/home');

    const toNotification = () => navigate('/notification');

    useEffect(() => {
        if (path !== '/video') setIsVideo(false);
        else setIsVideo(true);

        if (!(messages.length && notices.length)) return;

        const withTimeStamp = messages.map((v) => {
            v.timeStamp = Date.parse(v.createdAt);
            return v;
        });

        // 가장 최근 메세지
        setLastMessageTimeStamp(
            Number(
                withTimeStamp.sort((a, b) => b.timeStamp - a.timeStamp)[0]
                    .timeStamp
            )
        );

        // 공지사항 개수
        setNoticesLength(notices.filter((v) => v.isVisible).length);

        // 최근 메세지 접속기록
        if (localStorage.getItem('MessageLastChecked'))
            setLastMessageChecked(
                Number(localStorage.getItem('MessageLastChecked'))
            );
        else setLastMessageChecked(0);

        // 공지사항 접속기록
        if (localStorage.getItem('NoticeRead'))
            setNoticeReadLength(
                JSON.parse(localStorage.getItem('NoticeRead')).length
            );
        else setNoticeReadLength(0);
    }, [path, messages, notices]);

    return (
        <NavWrapper>
            {right.includes(path) ? (
                <NavLink justify="flex-end" onClick={toLogin} right={true}>
                    바로 시작하기{' '}
                    <img src={RightArrowIcon} alt="RightArrowIcon" />
                </NavLink>
            ) : (
                ''
            )}
            {left.includes(path) ? (
                <NavLink justify="flex-start" onClick={toPrev} left={true}>
                    <img src={LeftArrowIcon} alt="LeftArrowIcon" />
                    {!isVideo ? (
                        <CenterHeader onClick={(e) => e.stopPropagation()}>
                            {path === '/home/notice/view' ? '공지사항' : '알림'}
                        </CenterHeader>
                    ) : (
                        '다시 선택하기'
                    )}
                </NavLink>
            ) : (
                ''
            )}
            {plain.includes(path) ? (
                <NavLink justify="space-between" plain={true}>
                    <img
                        src={IccanLogoNav}
                        alt="IccanLogoNav"
                        onClick={toHome}
                    />
                    {lastMessageTimeStamp > lastMessageChecked ||
                    noticesLength > noticeReadLength ? (
                        <img
                            src={BlueNotification}
                            alt="BlueNotification"
                            onClick={toNotification}
                        />
                    ) : (
                        <img
                            src={GrayNotification}
                            alt="GrayNotification"
                            onClick={toNotification}
                        />
                    )}
                </NavLink>
            ) : (
                ''
            )}
        </NavWrapper>
    );
};

const NavWrapper = styled.div`
    background-color: ${UserColors.white01};
    line-height: 4.4rem;
    display: flex;
    padding: 0 1.5rem;
    height: 4.4rem;
    position: fixed;
    width: 100vw;
    z-index: 10;

    @media screen and (min-width: 600px) {
        width: 35.2rem;
    }
`;

const NavLink = styled.div`
    ${UserFonts.Navbar};
    width: ${(props) => (props.plain ? '100%' : '')};
    margin-left: ${(props) => (props.right ? 'auto' : '')};
    margin-right: ${(props) => (props.left ? 'auto' : '')};
    display: flex;
    align-items: center;
    color: ${UserColors.blue01};
    justify-content: ${(props) => props.justify};
    gap: 0 1.15rem;
    cursor: ${(props) => (props.plain ? '' : 'pointer')};

    & {
        img {
            position: relative;
            top: -0.5px;
            cursor: ${(props) => (props.plain ? 'pointer' : '')};
        }
    }
`;

const CenterHeader = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: ${UserColors.blue01};
    font-size: 1.5rem;
    font-weight: 500;
    cursor: default;
`;

export default Navbar;
