import React from 'react';
import { UserFonts } from '../../../constants/styles';
import UserColors from '../../../constants/colors';
import styled from 'styled-components';

const Button = ({
    action,
    isLanding,
    color,
    children,
    margin,
    isActive,
    isDisable,
}) => {
    console.log(isDisable);
    return isLanding === true ? (
        <LandingButtonWrapper onClick={action}>{children}</LandingButtonWrapper>
    ) : (
        <ButtonWrapper
            onClick={action}
            color={color}
            margin={margin}
            isActive={isActive !== undefined ? isActive : true}
            // disabled={isDisable}
        >
            {children}
        </ButtonWrapper>
    );
};

const ButtonWrapper = styled.button`
    ${UserFonts.Button};
    height: 3.9rem;
    width: 100%;
    display: block;
    background-color: ${(props) =>
        props.color === 'blue' && !props.disabled
            ? UserColors.blue01
            : props.color === 'gray'
            ? UserColors.gray02
            : props.color === 'yellow'
            ? UserColors.yellow01
            : UserColors.gray02};
    border-radius: 6px;
    border: none;
    cursor: ${(props) => (props.isActive && !props.disabled ? 'pointer' : '')};
    margin: ${(props) => props.margin};
    flex-shrink: 0;
`;

const LandingButtonWrapper = styled.button`
    ${UserFonts.LandingButton};
    height: 4.4rem;
    width: 80%;
    display: block;
    margin: 0 auto;
    background-color: ${UserColors.blue01};
    border-radius: 8px;
    border: none;
    cursor: pointer;
    position: relative;
    top: -0.3rem;
`;

export default Button;
