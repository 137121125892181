import React from 'react';
import styled from 'styled-components';
import UserColors from '../../../constants/colors';

const Placeholder = ({ icon, title }) => {
    return (
        <PlaceholderWrapper>
            <PlaceholderIconWrapper>
                <img src={icon} alt="PlaceholderIcon" />
            </PlaceholderIconWrapper>
            <PlaceholderTitle>{title}</PlaceholderTitle>
            <PlaceholderSubTitle>
                소식이 오면 전달해드릴게요
            </PlaceholderSubTitle>
        </PlaceholderWrapper>
    );
};

const PlaceholderWrapper = styled.div`
    text-align: center;
    height: calc(100vh - (4.4rem) - (6.5rem) - (10rem));
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const PlaceholderIconWrapper = styled.div`
    margin-bottom: 5.6rem;
`;

const PlaceholderTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    color: ${UserColors.black02};
    margin-bottom: 0.8rem;
`;

const PlaceholderSubTitle = styled.div`
    font-size: 1.3rem;
    color: ${UserColors.gray03};
    margin-bottom: 20vh;
`;

export default Placeholder;
