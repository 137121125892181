import { formatTimezoneWithoutTime } from './formatTimezone';
import axiosInstance from './axiosInstance';

const getUsersData = async () => {
    let failed;
    const res = await axiosInstance.get('/users').catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }

    const data = res.data.map((user) => {
        const userData = {
            id: user.id,
            username: user.username,
            name: user.name,
            dateOfBirth: formatTimezoneWithoutTime(user.dateOfBirth),
            phoneNumber: user.phoneNumber,
            createdAt: formatTimezoneWithoutTime(user.createdAt),
            updatedAt: formatTimezoneWithoutTime(user.updatedAt),
        };
        Object.assign(userData, user.customData);
        return userData;
    });

    return data;
};

const updateUser = async (userId, body) => {
    let failed;
    const res = await axiosInstance
        .patch(`/users/${userId}`, body)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const addUser = async (username, name, dateOfBirth, phoneNumber) => {
    let failed;
    const res = await axiosInstance
        .post('/users', { username, name, dateOfBirth, phoneNumber })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });

    if (failed) {
        return failed;
    }
    return res.data;
};

const downloadUserData = async () => {
    let failed;
    const res = await axiosInstance
        .get('/users/download', { responseType: 'blob' })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    const name = res.headers['content-disposition']
        .split('filename=')[1]
        .replace(/"/g, '');
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    link.style.cssText = 'display:none';
    document.body.appendChild(link);
    link.click();
    link.remove();

    return res.data;
};

const getPreSignedUrlForSampleFile = async () => {
    let failed;
    const res = await axiosInstance
        .get('/users/download/sample-file')
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const uploadUserData = async (formData) => {
    let failed;
    const res = await axiosInstance
        .post('/users/bulk', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const deleteUser = async (userId) => {
    let failed;
    const res = await axiosInstance
        .delete(`/users/${userId}`)
        .catch((error) => {
            console.log(error);
            failed = error.response.data;
        });
    if (failed) {
        return failed;
    }
    return res.data;
};

const getUserById = async (userId) => {
    let failed;
    const res = await axiosInstance.get(`/users/${userId}`).catch((error) => {
        console.log(error);
        failed = error.response.data;
    });
    if (failed) {
        return failed;
    }
    return res.data;
};

export {
    getUsersData,
    updateUser,
    addUser,
    downloadUserData,
    getPreSignedUrlForSampleFile,
    uploadUserData,
    deleteUser,
    getUserById,
};
