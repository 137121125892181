import { useEffect, memo, useState } from 'react';
import { BoxPlotChart } from '@sgratzl/chartjs-chart-boxplot';
import { averageDurationBoxPlotData } from '../../lib/requestChartData';

const BoxPlot = (props) => {
    const [chartData, setChartData] = useState(null);

    const boxplotData = {
        labels: props.labels.map((data) => data + '월'),
        datasets: [
            {
                label: '평균 운동 시간',
                backgroundColor: 'rgba(255,0,0,0.5)',
                borderColor: 'red',
                borderWidth: 1,
                outlierColor: '#151cb7',
                padding: 10,
                itemRadius: 0,
                data: chartData,
            },
        ],
    };

    useEffect(() => {
        const getBoxPlotData = async () => {
            const res = await averageDurationBoxPlotData();
            if (res.code) {
                console.log(res);
                return;
            }
            setChartData(res);
        };
        getBoxPlotData();

        const ctx = document.getElementById('box').getContext('2d');
        const myBoxPlot = new BoxPlotChart(ctx, {
            type: 'boxplot',
            data: boxplotData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: '월별 평균 운동 시간 분포',
                        font: {
                            size: 14,
                            weight: 'bold',
                        },
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            label: function (context) {
                                const { formattedValue } = context;
                                const formattedLabel = [
                                    `Min: ${formattedValue.min} 분`,
                                    `Q1: ${formattedValue.q1} 분`,
                                    `Median: ${formattedValue.median} 분`,
                                    `Mean: ${formattedValue.mean} 분`,
                                    `Q3: ${formattedValue.q3} 분`,
                                    `Max: ${formattedValue.max} 분`,
                                ];
                                console.log(context.formattedValue);
                                return formattedLabel;
                            },
                        },
                    },
                },
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: '분',
                        },
                        suggestedMin: 0,
                        suggestedMax: 30,
                    },
                },
                barPercentage: 0.7,
            },
        });

        return () => {
            myBoxPlot.destroy();
        };
    }, []);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <canvas id="box"></canvas>
        </div>
    );
};
export default memo(BoxPlot);
