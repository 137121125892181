import toCamelCase from '../../../lib/toCamelCase';
import React from 'react';

const FormLabel =
    // eslint-disable-next-line react/display-name
    React.forwardRef((props, ref) => {
        return (
            <label
                htmlFor={props.for}
                className={props.className && props.className}
                ref={ref}
            >
                {props.children}
            </label>
        );
    });

export default FormLabel;
