import { useDispatch, useSelector } from 'react-redux';
import { setMessages } from '../modules/messages';

export default function useMessages() {
    const messages = useSelector(({ messages }) => messages);
    const dispatch = useDispatch();

    const onSetMessages = (messages) => {
        dispatch(setMessages(messages));
    };

    return { messages, onSetMessages };
}
