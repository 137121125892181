import React, { memo, useEffect, useState } from 'react';
import styles from './TodaysStats.module.css';
import ChartTitle from './ChartTitle';
import { todayStat } from '../../lib/requestChartData';

const TodaysStats = (props) => {
    const [data, setData] = useState([0, 0, 0]);

    useEffect(() => {
        const getTodayStatData = async () => {
            const res = await todayStat();
            if (res.code) {
                console.log(res);
                return;
            }
            setData(res);
        };
        getTodayStatData();
    }, []);

    return (
        <div style={{ width: '100%', height: '100%', maxHeight: '100%' }}>
            <ChartTitle title="오늘의 통계" />
            <div>
                <table className={styles.table}>
                    <thead className={styles.header}>
                        <tr>
                            <th>출석</th>
                            <th>COMPLETE</th>
                            <th>평균 운동 시간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{data[0] || 0}명</td>
                            <td>{data[1] || 0}명</td>
                            <td>{data[2] || 0}분</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default memo(TodaysStats);
