import React, { useState } from 'react';
import styled from 'styled-components';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Help = ({
    children,
    header,
    size,
    width,
    position,
    display,
    top,
    right,
    isRelative,
    alignRelativeBlockRight,
}) => {
    const HelpIcon = (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 1C9.8244 1 7.69767 1.64514 5.88872 2.85383C4.07978 4.06253 2.66989 5.78049 1.83732 7.79048C1.00476 9.80047 0.786922 12.0122 1.21136 14.146C1.6358 16.2798 2.68345 18.2398 4.22182 19.7782C5.7602 21.3166 7.72021 22.3642 9.854 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C22.9966 9.08367 21.8365 6.28778 19.7744 4.22563C17.7122 2.16347 14.9163 1.00344 12 1ZM12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51982 18.4943 4.36627 17.0887 3.68508 15.4442C3.00389 13.7996 2.82566 11.99 3.17293 10.2442C3.5202 8.49836 4.37736 6.89471 5.63604 5.63604C6.89471 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4441 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C20.9971 14.3861 20.0479 16.6735 18.3607 18.3607C16.6735 20.0479 14.3861 20.9971 12 21ZM13 16.5V18.5H11V16.5H13ZM16 9.5C16.0011 10.0997 15.8667 10.692 15.607 11.2325C15.3473 11.7731 14.9689 12.2481 14.5 12.622C13.7426 13.2089 13.2304 14.0565 13.063 15H11.031C11.1194 14.229 11.3613 13.4836 11.7424 12.8076C12.1235 12.1316 12.6361 11.5387 13.25 11.064C13.4963 10.8668 13.6926 10.6142 13.8226 10.3267C13.9526 10.0392 14.0128 9.72499 13.9981 9.4098C13.9835 9.0946 13.8945 8.78734 13.7384 8.51312C13.5823 8.2389 13.3636 8.0055 13.1 7.832C12.8063 7.64 12.4683 7.52635 12.1182 7.50184C11.7681 7.47734 11.4176 7.5428 11.1 7.692C10.7612 7.85439 10.4769 8.11172 10.2816 8.43269C10.0863 8.75367 9.98847 9.12446 10 9.5C10 9.76522 9.89464 10.0196 9.7071 10.2071C9.51957 10.3946 9.26521 10.5 9 10.5C8.73478 10.5 8.48043 10.3946 8.29289 10.2071C8.10535 10.0196 8 9.76522 8 9.5C7.98512 8.73144 8.19718 7.97555 8.60965 7.32687C9.02212 6.67819 9.61669 6.16553 10.319 5.853C10.9415 5.57478 11.6241 5.4578 12.3038 5.51285C12.9835 5.5679 13.6383 5.79321 14.208 6.168C14.7584 6.53239 15.2102 7.02736 15.5228 7.60874C15.8355 8.19013 15.9994 8.83987 16 9.5Z"
                fill="#0C131C"
            />
        </svg>
    );
    const [isShow, setIsShow] = useState(false);
    const [isMount, setIsMount] = useState(false);

    const toggleHowToUse = () => {
        setIsShow(!isShow);
    };

    useDidMountEffect(() => {
        if (isShow) setIsMount(true);
        else {
            setTimeout(() => {
                setIsMount(false);
            }, 300);
        }
    }, [isShow]);

    return (
        <HelpContainer
            display={display}
            top={top}
            right={right}
            isRelative={isRelative}
            alignRelativeBlockRight={alignRelativeBlockRight}
        >
            <HelpIconWrapper
                onClick={toggleHowToUse}
                isShow={isShow}
                size={size}
            >
                {HelpIcon}
            </HelpIconWrapper>
            {isMount && (
                <HowToUseContainer
                    width={width}
                    position={position}
                    isShow={isShow}
                >
                    <HowToUseHeader position={position}>
                        {header}
                    </HowToUseHeader>
                    <HowToUseContent position={position}>
                        {children}
                    </HowToUseContent>
                </HowToUseContainer>
            )}
        </HelpContainer>
    );
};

const HelpContainer = styled.div`
    width: fit-content;
    height: fit-content;
    /* 적용할 div에서의 도움말 아이콘의 위치를 바꾸고 싶다면 */
    top: ${(props) => (props.top ? props.top : '-0.2rem')};
    right: ${(props) => (props.right ? props.right : '-0.2rem')};
    /* 여기를 수정하시면 됩니다 */
    display: ${(props) => (props.display ? props.display : 'inline-block')};
    position: ${(props) => (props.isRelative ? 'relative' : 'absolute')};
    margin-left: ${(props) => (props.alignRelativeBlockRight ? 'auto' : '')};
`;

const HelpIconWrapper = styled.span`
    cursor: pointer;
    & {
        svg {
            transform: ${(props) =>
                props.size ? `scale(${props.size},${props.size})` : ''};
        }
        svg path {
            fill: ${(props) => (props.isShow ? 'rgba(118, 171, 255)' : '')};
            transition: 0.3s ease;
        }
    }
`;

const HowToUseContainer = styled.div`
    width: ${(props) => (props.width ? props.width : '15rem')};
    position: absolute;
    transform: ${(props) =>
        props.position === 'right bottom'
            ? 'translate(1.5rem, 1.5rem)'
            : props.position === 'right top'
            ? 'translate(1.5rem, -100%)'
            : props.position === 'left bottom'
            ? 'translate(-100%, 1.5rem)'
            : props.position === 'left top'
            ? 'translate(-100%, -100%)'
            : ''};
    top: 0;
    left: 0;
    z-index: 5;
    opacity: ${(props) => (props.isShow ? '0.9' : '0')};
    transition: 0.3s ease;
`;

const HowToUseHeader = styled.div`
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0.9rem;
    padding-bottom: 0.7rem;
    color: #fff;
    background-color: rgba(118, 171, 255);
    border-radius: 15px 15px 0 0;
    border-radius: ${(props) =>
        props.position === 'right bottom'
            ? '0 15px 0 0'
            : props.position === 'right top'
            ? ''
            : props.position === 'left bottom'
            ? '15px 0 0 0'
            : props.position === 'left top'
            ? ''
            : ''};
`;

const HowToUseContent = styled.div`
    color: rgb(12, 19, 28);
    background-color: #fff;
    font-size: 0.8rem;
    line-height: 1.3rem;
    padding: 0.8rem 1rem;
    border-radius: 0 0 15px 15px;
    border-radius: ${(props) =>
        props.position === 'right bottom'
            ? ''
            : props.position === 'right top'
            ? '0 0 15px 0'
            : props.position === 'left bottom'
            ? ''
            : props.position === 'left top'
            ? '0 0 0 15px'
            : ''};

    & {
        ul,
        ol {
            margin: 0;
            padding: 0 1rem;
        }
    }
`;

export default Help;
