import moment from 'moment';
import 'moment/locale/ko';

const formatTimezoneWithoutTime = (timezone) => {
    if (timezone === null || timezone === undefined) {
        return null;
    }
    const m = moment(timezone);
    return m.format('LL').toString();
};

const formatTimezoneWithTime = (timezone) => {
    if (timezone === null || timezone === undefined) {
        return null;
    }
    const m = moment(timezone);
    return m.format('YYYY/MM/DD HH:mm:ss').toString();
};

export { formatTimezoneWithTime, formatTimezoneWithoutTime };
