import GivenVideoItem from './GivenVideoItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

function GivenVideo({
    videos,
    AddVideo,
    onToggle,
    selectedVideos,
    syncIndicator,
}) {
    const lastSlidePage = Math.ceil(videos.length / 8);

    const onSlideChange = (swiper) => {
        console.log(swiper.realIndex);
        syncIndicator(swiper.realIndex);
    };

    return (
        <>
            <ExerciseSliderWrapper>
                <Swiper onSlideChange={onSlideChange}>
                    {Array(lastSlidePage)
                        .fill(0)
                        .map((v, i) => (
                            <SwiperSlide key={(v, i)}>
                                <ExerciseItemsWrapper key={(v, i)}>
                                    {videos &&
                                        videos
                                            .slice(i * 8, i * 8 + 8)
                                            .map((item) => (
                                                <GivenVideoItem
                                                    video={item}
                                                    key={item.id}
                                                    AddVideo={AddVideo}
                                                    onToggle={onToggle}
                                                    selectedVideos={
                                                        selectedVideos
                                                    }
                                                />
                                            ))}
                                </ExerciseItemsWrapper>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </ExerciseSliderWrapper>
        </>
    );
}

const ExerciseSliderWrapper = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    // 스크롤바 없앰
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* scroll-behavior: smooth; */

    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;

const ExerciseItemsWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    text-align: center;
    padding: 1.2rem 0.8rem;
    grid-gap: 0.8rem;
`;

export default GivenVideo;
